export function Physician({ color, size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || "16"}
      height={size || "16"}
      viewBox="0 0 16 16"
      fill="none"
    >
      <g opacity="0.4">
        <path
          d="M2.66683 1.33325C1.93812 1.33325 1.3335 1.93788 1.3335 2.66659L1.3348 4.65096C1.3348 4.60074 1.3335 4.6057 1.3335 4.66658C1.3335 6.62741 2.55578 8.30997 4.2762 8.99862C4.51237 9.40534 4.88406 9.71786 5.3335 9.87752V11.9999C5.3335 13.4648 6.53527 14.6666 8.00016 14.6666H10.6668C12.1317 14.6666 13.3335 13.4648 13.3335 11.9999V11.884C13.7232 11.7463 14.0606 11.4911 14.2994 11.1537C14.5382 10.8163 14.6665 10.4133 14.6668 9.99992C14.6668 9.46949 14.4561 8.96078 14.081 8.5857C13.706 8.21063 13.1973 7.99992 12.6668 7.99992C12.1364 7.99992 11.6277 8.21063 11.2526 8.5857C10.8775 8.96078 10.6668 9.46949 10.6668 9.99992C10.6674 10.413 10.7959 10.8158 11.0346 11.153C11.2734 11.4901 11.6107 11.745 12.0002 11.8827V11.9999C12.0002 12.7444 11.4113 13.3333 10.6668 13.3333H8.00016C7.25572 13.3333 6.66683 12.7444 6.66683 11.9999V9.87752C7.11627 9.71786 7.48795 9.40534 7.72412 8.99862C9.44455 8.30997 10.6668 6.62741 10.6668 4.66658V4.65096V2.66659C10.6668 1.93788 10.0622 1.33325 9.3335 1.33325H8.00016V2.66659H9.3335V4.65096V4.66658C9.3335 6.5157 7.84927 7.99992 6.00016 7.99992C4.15105 7.99992 2.66683 6.5157 2.66683 4.66658C2.66683 4.71681 2.66813 4.71185 2.66813 4.65096L2.66683 2.66659H4.00016V1.33325H2.66683Z"
          fill={color || "black"}
        />
      </g>
    </svg>
  );
}
