import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { addInParams } from "../utils";

const timeFrameData = [
  {
    title: "Newest",
    value: "desc",
    keyField: "createdAt",
  },
  {
    title: "Oldest",
    value: "asc",
    keyField: "createdAt",
  },
  {
    title: "Recent Procedure Date",
    value: "desc",
    keyField: "surgeryDate",
  },
  {
    title: "Oldest Procedure Date",
    value: "asc",
    keyField: "surgeryDate",
  },
];

export default function PatientSort({
  selectedValue,
  setSelectedValue,
  sortField,
  setSortField,
  sortOrder,
  setSortOrder,
  setPage,
}) {
  const navigate = useNavigate();

  const handleChangeDropdown = (event) => {
    const selectedOption = timeFrameData.find(
      (item) => `${item.keyField}-${item.value}` === event.target.value,
    );

    if (selectedOption) {
      const { keyField, value } = selectedOption;
      localStorage.setItem("sortField3", keyField);
      localStorage.setItem("sortOrder3", value);

      setSortField(keyField);
      setSortOrder(value);
      localStorage.setItem("page3", 1);
      addInParams("page", 1);
      setPage(1);
      addInParams("sortField", keyField);
      addInParams("sortOrder", value);
    }
  };

  const handleRedirect = () => {
    localStorage.removeItem("patientForm");
    localStorage.removeItem("assignJourneyForm");
    localStorage.removeItem("assignSurgeryForm");
    localStorage.removeItem("stepperValue");
    navigate("/createProfile/");
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          ml: "5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Typography sx={{ marginRight: "8px" }} color={"#B3B7BA"}>
            Sort by:
          </Typography>
          <FormControl sx={{ minWidth: 80 }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              sx={{ height: 35, borderRadius: "8px" }} // Set the height here
              value={`${sortField}-${sortOrder}`} // Combine `sortField` and `sortOrder` for matching
              onChange={handleChangeDropdown}
            >
              {timeFrameData?.map((values, index) => (
                <MenuItem
                  key={values._id}
                  value={`${values.keyField}-${values.value}`} // Unique identifier for each option
                >
                  {values.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Button
          variant="contained"
          size="small"
          sx={{
            cursor: "pointer",
            ml: "10px",
            paddingX: "12px",
            paddingY: "8px",
          }}
          onClick={() => handleRedirect()}
        >
          <Typography
            variant="caption"
            sx={{ color: "white", textWrap: "nowrap" }}
          >
            Create New Patient
          </Typography>
        </Button>
      </Box>
    </>
  );
}
