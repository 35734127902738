import ReplayIcon from "@mui/icons-material/Replay";
import {
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";
import { toast } from "react-toastify";
import { useResendUnknownUserConsentMutation } from "../../../store/chatSlice";
import { Conversation } from "../../../assets/svgs/PatientProfileTabsSvgs/Conversation";
import { Gallery } from "../../../assets/svgs/PatientProfileTabsSvgs/Gallery";
import { Form } from "../../../assets/svgs/PatientProfileTabsSvgs/Form";
import { Call } from "../../../assets/svgs/PatientProfileTabsSvgs/Call";
import { Task } from "../../../assets/svgs/PatientProfileTabsSvgs/Task";
import {
  AccessTimeOutlined,
  CakeOutlined,
  CallOutlined,
  ChevronRight,
  HelpCenterOutlined,
  PermIdentityOutlined,
} from "@mui/icons-material";
import { TaskCategory } from "../../../assets/svgs/PatientProfileTabsSvgs/TaskCategory";
import moment from "moment";
import CustomMultiSelect from "./CustomMultiSelect";
import { getTokenFromLocalStroage } from "../../../utils";
import axios from "axios";
import { PatientsSvg } from "../../../assets/svgs";
import LinkQueueModal from "../../../Components/LinkQueueModal";
import { ConfirmationModal } from "../../../Components/MessageLayout/ConfirmationModal";

function formatPhoneNumber(phoneNumber) {
  // Remove any non-digit characters
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");

  // Check if the input is valid (should be 11 digits with country code)
  if (cleaned.length === 11 && cleaned[0] === "1") {
    const country = cleaned[0]; // Country code
    const area = cleaned.slice(1, 4); // Area code
    const local = cleaned.slice(4, 7); // First 3 digits
    const number = cleaned.slice(7, 11); // Last 4 digits

    return `+${country} (${area}) ${local}-${number}`;
  }

  return phoneNumber; // Return original if not valid
}

const getConsentStatus = (consent) => {
  if (consent === "yes") {
    return "success";
  } else if (consent === "no") {
    return "error";
  } else if (consent === "NO_RESPONSE") {
    return "warning";
  } else {
    return "default"; // For cases where consent does not match known values
  }
};

const getSmsConsent = (data) => {
  const consentResponse = data?.consentResponse;

  if (consentResponse === "yes") {
    return "SMS Consent Approved";
  } else if (consentResponse === "no") {
    return "SMS Consent Declined";
  } else if (consentResponse === "NO_RESPONSE") {
    return "SMS Consent Pending";
  } else {
    return "Unknown Consent Status";
  }
};

export function UnknownDrawerHeader({
  data,
  setOpenUnknownCallerChat,
  refetchQueueAgain,
  selectedPatientStatus,
}) {
  const [linkQueueModalOpen, setLinkQueueModalOpen] = React.useState({
    value: false,
    data: null,
    results: null,
    taskCategory: null,
    callerIdentification: null,
  });

  const [openResend, setOpenResend] = React.useState(false);

  const [resendUnknownUserConsent] = useResendUnknownUserConsentMutation();

  const handleResend = () => {
    setOpenResend(true);
  };

  const updateStatus = async (itemId, currentStatus) => {
    const newStatus = currentStatus === "resolved" ? "pending" : "resolved";
    const ids = [itemId];

    try {
      await axios
        .put(
          `${process.env.REACT_APP_BASE_API_URL}/patientQueues/updateStatuses`,
          { status: newStatus, ids },
          config,
        )
        .then((res) => {
          const status = res.data.status;
          toast.success("The issue is " + status);
          refetchQueueAgain();
        });
    } catch (error) {
      toast.error("Error:", error);
    }
  };

  function validatePhoneNumber(number) {
    // Remove all spaces
    const cleanedNumber = number.replace(/\s+/g, "");

    // Check if it starts with "+1" and has exactly 12 characters
    if (!cleanedNumber.startsWith("+1") && cleanedNumber.length !== 12) {
      return true;
    } else {
      return false;
    }
  }

  const getReadableTextByKey = (key) => {
    switch (key) {
      case "message":
        return "Messages";
      case "new_media":
        return "Photo Uploads";
      case "form_submission":
        return "Forms";
      case "care_ally_message":
        return "Care Ally";
      case "dialogue":
        return "Dialogues";
      case "call":
        return "Calls";
      case "task":
        return "Tasks";
      default:
        return "Unknown Type"; // Default fallback value
    }
  };

  const getIconByKey = (key) => {
    switch (key) {
      case "message":
        return <Conversation color="#9E9E9E" />;
      case "new_media":
        return <Gallery color="#9E9E9E" />;
      case "form_submission":
        return <Form color="#9E9E9E" />;
      case "care_ally_message":
        return <Conversation color="#9E9E9E" />;
      case "dialogue":
        return <Form color="#9E9E9E" />;
      case "call":
        return <Call color="#9E9E9E" />;
      case "task":
        return <Task color="#9E9E9E" />;
      default:
        return <HelpCenterOutlined />; // Default fallback icon
    }
  };

  const config = {
    headers: { Authorization: `Bearer ${getTokenFromLocalStroage()}` },
  };

  const handleDataResend = async () => {
    if (data?.results?.phone_number === "N/A") {
      toast.error("User phone number does not exist!");
    } else if (validatePhoneNumber(data?.results?.phone_number)) {
      toast.error("Phone Number not valid!");
    } else {
      const body = {
        phone: data?.results?.phone_number.replace(/\n/g, "\r\n"),
      };
      const response = await resendUnknownUserConsent(body);
      if (response?.data?.message) {
        toast.success("The SMS consent has been resent.");
        refetchQueueAgain();
        setOpenResend(false);
      } else {
        toast.error("Something went wrong!");
      }
    }
  };
  return (
    <>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
          paddingTop: "3px",
          m: "0px 16px",
          mt: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "8px 0px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              display="flex"
              alignItems="center"
              fontSize="small"
            >
              {getIconByKey(data?.calldata?.type)}
              <span style={{ marginLeft: 5 }}>
                {getReadableTextByKey(data?.calldata?.type)}
              </span>
              <ChevronRight sx={{ color: "#9E9E9E" }} />
            </Typography>
            <Chip
              label={data?.taskCategory}
              size="small"
              startIcon={<TaskCategory color="#9E9E9E" />}
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "4px",
              }}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                px: "12px",
                ml: 1,
              }}
            >
              <AccessTimeOutlined
                sx={{
                  color: "#9E9E9E",
                  height: "16px",
                  width: "16px",
                  mr: "2px",
                }}
              />
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{
                  color: "#212121",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "157%",
                }}
              >
                {moment(data?.createdAt).format("MM-DD-YYYY | hh:mma")}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Button
              variant="outlined"
              sx={{
                borderRadius: "4px",
                mr: "12px",
                py: "0px",
              }}
            >
              <Typography
                sx={{
                  color: "#00000099",
                  fontSize: "12px",
                  fontWeight: 400,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Assign to :{"  "}
                <CustomMultiSelect
                  queueID={data?.calldata?._id}
                  queueStatus={data?.calldata?.status}
                  assignee={data?.calldata?.assignees || []}
                  refetchQueueAgain={refetchQueueAgain}
                />
              </Typography>
            </Button>
            <Button
              variant="contained"
              startIcon={
                <Avatar
                  variant="rounded"
                  sx={{
                    width: "22px",
                    height: "22px",
                    bgcolor: "#947DF4",
                    fontSize: "12px",
                  }}
                >
                  {data?.calldata?.status === "resolved" ? "P" : "R"}
                </Avatar>
              }
              sx={{
                ".MuiButton-startIcon > :nth-of-type(1)": {
                  fontSize: "12px",
                },
              }}
              onClick={() =>
                updateStatus(data?.calldata?._id, data?.calldata?.status)
              }
            >
              {data?.calldata?.status === "resolved"
                ? "Mark as Pending"
                : "Mark as Resolved"}
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            p: 2,
            borderRadius: "4px",
            padding: "6px 20px",
            justifyContent: "space-between",
            backgroundColor: "#F6F4FA",
            marginTop: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "157%",
                letterSpacing: "0.113px",
                color: "#303030",
              }}
            >
              {data?.results?.name || "N/A"}
            </Typography>
            <IconButton
              sx={{
                border: "1px solid rgb(0, 0, 0, 0.12)", // Add an 8px border
                borderRadius: "8px", // Optional: Make the border circular if needed
                padding: "4px", // Adjust padding to maintain proper spacing
                display: "inline-flex", // Center the content inside the border
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "5px",
              }}
              onClick={() =>
                setLinkQueueModalOpen({
                  value: true,
                  data: data?.data,
                  results: data?.results,
                  taskCategory: data?.taskCategory,
                  callerIdentification: data?.callerIdentification,
                })
              }
            >
              <PatientsSvg fill="black" size="16px" />
            </IconButton>
          </Box>

          <Box sx={{ mx: 2 }}>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 0.5,
                color: "#303030",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "143%",
                letterSpacing: "0.193px",
              }}
            >
              <CallOutlined
                sx={{
                  color: "#424242",
                  height: "16px",
                  width: "16px",
                  marginRight: "8px",
                }}
              />
              {formatPhoneNumber(data?.results?.phone_number) || "N/A"}
            </Typography>
          </Box>

          <Box sx={{ mx: 2 }}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#303030",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "143%",
                letterSpacing: "0.193px",
              }}
            >
              <CakeOutlined
                sx={{
                  color: "#424242",
                  height: "16px",
                  width: "16px",
                  marginRight: "8px",
                }}
              />
              {data?.results?.dob || "N/A"}
            </Typography>
          </Box>

          <Box sx={{ mx: 2 }}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#303030",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "143%",
                letterSpacing: "0.193px",
              }}
            >
              <PermIdentityOutlined
                sx={{
                  color: "#424242",
                  height: "16px",
                  width: "16px",
                  marginRight: "8px",
                }}
              />
              {data?.callerIdentification || "N/A"}
            </Typography>
          </Box>

          <Box sx={{ mx: 2 }}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#303030",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "143%",
                letterSpacing: "0.193px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Chip
                  label={getSmsConsent(data?.results)}
                  color={getConsentStatus(data?.results?.consentResponse)}
                  size="small"
                  sx={{ borderRadius: "4px", fontWeight: "600" }}
                />

                {(data?.results?.consentResponse !== "yes" ||
                  data?.results?.phone_number !== "N/A") &&
                  data?.results?.phone_number && (
                    <Tooltip title="Resend SMS Consent">
                      <IconButton onClick={handleResend}>
                        <ReplayIcon sx={{ width: "20px", height: "20px" }} />
                      </IconButton>
                    </Tooltip>
                  )}
              </Box>
            </Typography>
          </Box>
        </Box>
        <LinkQueueModal
          open={linkQueueModalOpen?.value}
          queueId={linkQueueModalOpen?.data}
          metadata={linkQueueModalOpen?.results}
          callresults={linkQueueModalOpen?.callresults}
          taskCategory={linkQueueModalOpen?.taskCategory}
          callerIdentification={linkQueueModalOpen?.callerIdentification}
          setOpenUnknownCallerChat={setOpenUnknownCallerChat}
          onClose={() =>
            setLinkQueueModalOpen({
              value: false,
              data: null,
              results: null,
              taskCategory: null,
              callerIdentification: null,
            })
          }
          refetchQueueAgain={refetchQueueAgain}
        />

        <ConfirmationModal
          open={openResend}
          setOpen={setOpenResend}
          txt="Are you sure you want to resend the SMS consent?"
          handle={handleDataResend}
        />
      </Box>
    </>
  );
}
