import { Box, Button } from "@mui/material";
import axios from "axios";
import { addInParams, getTokenFromLocalStroage } from "../../utils";
import { toast } from "react-toastify";

const MarkAsResolvedButton = ({
  isLoading,
  setIsShow,
  isShow,
  name,
  queueData,
  queueStatus,
  refetchAgain,
  refetchCount,
  refetchQueueAgain,
  setOpenChat,
  isType,
}) => {
  const config = {
    headers: { Authorization: `Bearer ${getTokenFromLocalStroage()}` },
  };

  console.log(queueData);

  const updateStatus = async (itemId, currentStatus, allIds) => {
    const newStatus = currentStatus === "resolved" ? "pending" : "resolved";
    const ids = currentStatus === "resolved" ? [itemId] : allIds;

    console.log("itemId:", itemId);

    try {
      await axios
        .put(
          `${process.env.REACT_APP_BASE_API_URL}/patientQueues/updateStatuses`,
          { status: newStatus, ids },
          config,
        )
        .then((res) => {
          const status = res.data.status;
          toast.success("The issue is " + status);
          refetchAgain();
          refetchCount();
          refetchQueueAgain();
          setOpenChat(false);
          addInParams("formType", null);
          addInParams("patientId", null);
          addInParams("queueId", null);
          addInParams("tab", null);
        });
    } catch (error) {
      toast.error("Error:", error);
    }
  };
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      {isType && name && (
        <Button
          variant="contained"
          size="small"
          color="primary"
          disabled={isLoading}
          sx={{
            mr: "10px",
            padding: "8px",
            // background: "#E9E9E9",
            // color: "black",
            // "&:hover": {
            //   backgroundColor: "#bdbdbd",
            //   color: "white",
            // },
          }}
          onClick={() => setIsShow(!isShow)}
        >
          {isShow ? `View Queue ${name}` : `View All ${name}`}
        </Button>
      )}
      <Button
        variant="contained"
        size="small"
        color="primary"
        sx={{
          mr: "10px",
          padding: "8px",
          // background: "#E9E9E9",
          // color: "black",
          // "&:hover": {
          //   backgroundColor: "#bdbdbd",
          //   color: "white",
          // },
        }}
        onClick={() =>
          updateStatus(
            queueData?._id,
            queueStatus,
            queueData?.items?.map((ids) => ids?._id),
          )
        }
      >
        {queueStatus === "resolved" ? "Mark as Pending" : "Mark as Resolved"}
      </Button>
    </Box>
  );
};

export default MarkAsResolvedButton;
