import { Drawer, Box, IconButton, Avatar, Tooltip } from "@mui/material";
import { Typography, Chip, Button } from "@mui/material";
import {
  AccessTimeOutlined,
  CallOutlined,
  ChevronRight,
  HelpCenterOutlined,
  LaunchRounded,
  MailOutline,
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import IgnoreModal from "./IgnoreModal";
import EmailModal from "./EmailModal";
import ContentDrawer from "./ContentDrawer";
import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";
import { getTokenFromLocalStroage } from "../../../utils";
import ConversationTab from "../../../Components/MessageLayout/ConversationTab";
import ChatBotDropdownButton from "../../../Components/MessageLayout/ChatBotModeDropdown";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GalleryTab from "../../../Components/QueueDetailDrawerV2/GalleryTab";
import CallTab from "../../../Components/QueueDetailDrawerV2/CallTab";
import { useSelector } from "react-redux";
import { Conversation } from "../../../assets/svgs/PatientProfileTabsSvgs/Conversation";
import { Gallery } from "../../../assets/svgs/PatientProfileTabsSvgs/Gallery";
import { Form } from "../../../assets/svgs/PatientProfileTabsSvgs/Form";
import { Call } from "../../../assets/svgs/PatientProfileTabsSvgs/Call";
import { Task } from "../../../assets/svgs/PatientProfileTabsSvgs/Task";
import { Physician } from "../../../assets/svgs/PatientProfileTabsSvgs/Physician";
import CustomMultiSelect from "./CustomMultiSelect";
import FormTab from "../../../Components/QueueDetailDrawerV2/FormTab";
import PatientToggleButton from "./PatientToggleButton";
import CareAllyTab from "../../../Components/MessageLayout/CareAllyTab";
import TaskTab from "../../../Components/QueueDetailDrawerV2/TaskTab";
import { useNavigate } from "react-router-dom";
import OutgoingCallComponent from "../../../Components/OutgoingCall";
import { styled } from "@mui/system";
import { TaskCategory } from "../../../assets/svgs/PatientProfileTabsSvgs/TaskCategory";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#ffffff", // White background
    color: "#000000", // Black text
    border: "1px solid #ccc", // Optional border
    padding: theme.spacing(1),
    fontSize: "0.875rem",
    boxShadow: theme.shadows[1],
  },
}));

const CustomDrawer = ({
  open,
  onClose,
  type,
  data,
  selectedPatientStatus,
  refetchQueueAgain,
  isMessageClick,
}) => {
  const { user } = useSelector((state) => state.user);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEmailModalOpen, setEmailModalOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isShowMessage, setIsShowMessage] = useState(false);
  const [chatPage, setChatPage] = useState(1);
  const [careAllyChatPage, setCareAllyChatPage] = useState(1);
  const [selectedValue, setSelectedValue] = useState(null);
  const [patientToggleOption, setPatientToggleOption] = useState("patient");
  const [careAllyData, setCareAllyData] = useState(null);
  const [openCallUnregisterPatient, setOpenCallUnregisterPatient] =
    useState(false);
  const navigate = useNavigate();

  const handlePatientToggle = (event, newAlignment) => {
    setPatientToggleOption(newAlignment);
  };

  const handleCloseCallUnregister = () => {
    setOpenCallUnregisterPatient(false);
  };

  const openCallUnregisterPatientModal = (name, phone, patientId) => {
    if (phone) {
      setOpenCallUnregisterPatient({
        value: true,
        name: name,
        phone: phone,
        patientId: patientId,
      });
    } else {
      toast.error("Phone number is not valid!");
    }
  };

  const handleEmailModalOpen = () => {
    setEmailModalOpen(true);
  };

  const handleDrawerClose = () => {
    onClose();
    setIsShow(false);
    setIsFullScreen(false);
    setIsShowMessage(false);
  };

  const handleEmailModalClose = () => {
    setEmailModalOpen(false);
  };

  const handleSendEmail = () => {
    setEmailModalOpen(false);
  };

  const handleRegenerateEmail = () => {
    return;
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleIgnoreConfirm = () => {
    updateIgnoredStatus(data?.items?.map((ids) => ids?._id));
    setModalOpen(false);
    handleDrawerClose();
  };

  const getColorStyles = (acuity) => {
    if (acuity === "high") {
      return {
        backgroundColor: "#EF4444",
      };
    } else if (acuity === "medium") {
      return {
        backgroundColor: "#FFB546",
      };
    } else if (acuity === "N/A") {
      return {
        backgroundColor: "black",
      };
    } else if (acuity === "low") {
      return {
        backgroundColor: "#04B556",
      };
    } else {
      return {
        backgroundColor: "gray",
      };
    }
  };

  const calculateAge = (dob, gender) => {
    if (dob) {
      // Check if the date is in ISO 8601 format
      const birthDate = dob.includes("T")
        ? new Date(dob)
        : new Date(`${dob}T00:00:00.000Z`);
      const today = new Date();
      let age_now = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
      }
      return `${age_now}/${gender?.charAt(0)?.toUpperCase() || "N/A"}`;
    } else {
      return `N/A`;
    }
  };

  const config = {
    headers: { Authorization: `Bearer ${getTokenFromLocalStroage()}` },
  };

  const getReadableTextByKey = (key) => {
    switch (key) {
      case "message":
        return "Messages";
      case "new_media":
        return "Photo Uploads";
      case "form_submission":
        return "Forms";
      case "care_ally_message":
        return "Care Ally";
      case "dialogue":
        return "Dialogues";
      case "call":
        return "Calls";
      case "task":
        return "Tasks";
      default:
        return "Unknown Type"; // Default fallback value
    }
  };

  const getIconByKey = (key) => {
    switch (key) {
      case "message":
        return <Conversation color="#9E9E9E" />;
      case "new_media":
        return <Gallery color="#9E9E9E" />;
      case "form_submission":
        return <Form color="#9E9E9E" />;
      case "care_ally_message":
        return <Conversation color="#9E9E9E" />;
      case "dialogue":
        return <Form color="#9E9E9E" />;
      case "call":
        return <Call color="#9E9E9E" />;
      case "task":
        return <Task color="#9E9E9E" />;
      default:
        return <HelpCenterOutlined />; // Default fallback icon
    }
  };

  const goToProfile = (id, name, firstName) => {
    localStorage.setItem("pageName", JSON.stringify(name));
    const url = `/patients?page=1&status=active&name=${firstName}&patientId=${id}&tab=0`;
    const queryParams = new URLSearchParams({
      action: "read-only",
      id,
    }).toString();
    window.open(`${url}?${queryParams}`, "_blank");
  };

  const updateStatus = async (itemId, currentStatus, allIds) => {
    const newStatus = currentStatus === "resolved" ? "pending" : "resolved";
    const ids =
      currentStatus === "resolved" || currentStatus === "ignored"
        ? [itemId]
        : allIds;

    try {
      await axios
        .put(
          `${process.env.REACT_APP_BASE_API_URL}/patientQueues/updateStatuses`,
          { status: newStatus, ids },
          config,
        )
        .then((res) => {
          const status = res.data.status;
          toast.success("The issue is " + status);
          handleDrawerClose();
          refetchQueueAgain();
        });
    } catch (error) {
      toast.error("Error:", error);
    }
  };

  const updateIgnoredStatus = async (allIds) => {
    try {
      await axios
        .put(
          `${process.env.REACT_APP_BASE_API_URL}/patientQueues/updateStatuses`,
          { status: "ignored", ids: allIds },
          config,
        )
        .then((res) => {
          const status = res.data.status;
          toast.success("The issue is " + status);
          handleDrawerClose();
          refetchQueueAgain();
        });
    } catch (error) {
      toast.error("Error:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/v2/careAlly/patient/${data?.patient?._id}`,
        config,
      );
      setCareAllyData(response.data);
    } catch (error) {
      setCareAllyData(null);
    }
  };

  useEffect(() => {
    fetchData();
  }, [data?.patient?._id]);

  useEffect(() => {
    if (isMessageClick) {
      setIsShow(true);
      setIsShowMessage(true);
    }
  }, [isMessageClick]);

  return (
    <>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflow: "hidden",
            width: "62%",
          },
        }}
        anchor="right"
        open={open}
        onClose={handleDrawerClose}
        className="ScrollDesign"
      >
        <Box
          sx={{
            height: "100vh",
            padding: "14px",
          }}
        >
          <Box
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 10,
              backgroundColor: "white",
              paddingTop: "3px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "8px 0px",
                maxWidth: "100%", // Set a maximum width
                overflow: "hidden", // Hide overflowing content
                textOverflow: "ellipsis", // Add ellipsis for overflow
                whiteSpace: "nowrap", // Prevent text wrapping
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  display="flex"
                  alignItems="center"
                  fontSize="small"
                >
                  {getIconByKey(
                    selectedPatientStatus === "pending"
                      ? data?._id?.type
                      : data?.type,
                  )}
                  <span style={{ marginLeft: 5 }}>
                    {getReadableTextByKey(
                      selectedPatientStatus === "pending"
                        ? data?._id?.type
                        : data?.type,
                    )}
                  </span>
                  <ChevronRight sx={{ color: "#9E9E9E" }} />
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  {/* Chip for the first task category */}
                  <Chip
                    label={
                      selectedPatientStatus === "pending"
                        ? data?._id?.taskCategory?.[0]
                        : data?.taskCategory?.[0]
                    }
                    size="small"
                    icon={<TaskCategory />}
                    sx={{
                      backgroundColor: "#EEE",
                      borderRadius: "4px",
                      paddingLeft: 1,
                    }}
                  />

                  {/* Chip for the remaining task categories count */}
                  {((selectedPatientStatus === "pending"
                    ? data?._id?.taskCategory
                    : data?.taskCategory
                  )?.length || 0) > 1 && (
                    <CustomTooltip
                      title={
                        <div>
                          {(selectedPatientStatus === "pending"
                            ? data?._id?.taskCategory
                            : data?.taskCategory
                          )
                            ?.slice(1)
                            .map((item, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "4px",
                                }}
                              >
                                <span
                                  style={{
                                    display: "inline-block",
                                    width: "8px",
                                    height: "8px",
                                    backgroundColor: "#6235ED",
                                    borderRadius: "3px",
                                    marginRight: "8px",
                                  }}
                                />
                                <Typography component="span">
                                  {item
                                    .replace(/_/g, " ")
                                    .split(/\s+/)
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1),
                                    )
                                    .join(" ")}
                                </Typography>
                              </div>
                            ))}
                        </div>
                      }
                    >
                      <Chip
                        label={`+
                          ${
                            (
                              selectedPatientStatus === "pending"
                                ? data?._id?.taskCategory
                                : data?.taskCategory
                            )?.length - 1
                          }`}
                        size="small"
                        sx={{
                          backgroundColor: "#EEE",
                          borderRadius: "700px",
                          cursor: "pointer",
                        }}
                      />
                    </CustomTooltip>
                  )}
                </Box>

                <Chip
                  label={
                    selectedPatientStatus === "pending"
                      ? data?.highestAcuity?.charAt(0).toUpperCase() +
                        data?.highestAcuity?.slice(1)
                      : data?.acuity?.charAt(0).toUpperCase() +
                        data?.acuity?.slice(1)
                  }
                  size="small"
                  sx={{
                    ...getColorStyles(
                      selectedPatientStatus === "pending"
                        ? data?.highestAcuity
                        : data?.acuity,
                    ),
                    height: "20px",
                    fontSize: "0.75rem",
                    color: "white",
                    ml: 1,
                    borderRadius: "4px",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    px: "12px",
                    ml: 1,
                  }}
                >
                  <AccessTimeOutlined
                    sx={{
                      color: "#9E9E9E",
                      height: "16px",
                      width: "16px",
                      mr: "2px",
                    }}
                  />
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{
                      color: "#212121",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "157%",
                    }}
                  >
                    {moment(data?.createdAt).format("MM-DD-YYYY | hh:mma")}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: "4px",
                    mr: "12px",
                    py: "0px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#00000099",
                      fontSize: "12px",
                      fontWeight: 400,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Assign to :{"  "}
                    <CustomMultiSelect
                      queueID={data?.items?.map((ids) => ids?._id)}
                      queueStatus={data?.status}
                      assignee={
                        selectedPatientStatus === "resolved" ||
                        selectedPatientStatus === "ignored"
                          ? data?.assignees || []
                          : data?.uniqueAssignees || []
                      }
                      refetchQueueAgain={refetchQueueAgain}
                    />
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  startIcon={
                    <Avatar
                      variant="rounded"
                      sx={{
                        width: "22px",
                        height: "22px",
                        bgcolor: "#947DF4",
                        fontSize: "12px",
                      }}
                    >
                      {data?.status === "resolved" ? "P" : "R"}
                    </Avatar>
                  }
                  sx={{
                    ".MuiButton-startIcon > :nth-of-type(1)": {
                      fontSize: "12px",
                    },
                  }}
                  onClick={() =>
                    updateStatus(
                      data?._id,
                      data?.status,
                      data?.items?.map((ids) => ids?._id),
                    )
                  }
                >
                  {data?.status === "resolved"
                    ? "Mark as Pending"
                    : "Mark as Resolved"}
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                p: 2,
                borderRadius: "4px",
                padding: "6px 20px",
                justifyContent: "space-between",
                backgroundColor: "#F6F4FA",
                marginTop: "20px",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "157%",
                    letterSpacing: "0.113px",
                    color: "#303030",
                  }}
                >
                  {data?.patient?.firstName}, {data?.patient?.lastName}
                </Typography>
                <Chip
                  label={calculateAge(
                    data?.patient?.birthday,
                    data?.patient?.gender,
                  )}
                  size="small"
                  variant="outlined"
                  sx={{
                    height: "20px",
                    mx: 1,
                    fontWeight: 500,
                    fontSize: "10px",
                    lineHeight: "145%",
                    letterSpacing: "0.113px",
                    color: "black",
                    border: "1px solid #BDBDBD",
                    borderRadius: "4px",
                  }}
                />
              </Box>

              <Box sx={{ mx: 2 }}>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 0.5,
                    color: "#303030",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "143%",
                    letterSpacing: "0.193px",
                  }}
                >
                  <CallOutlined
                    sx={{
                      color: "#424242",
                      height: "16px",
                      width: "16px",
                      marginRight: "8px",
                    }}
                  />
                  {data?.patient?.phone}
                </Typography>
              </Box>

              <Box sx={{ mx: 2 }}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#303030",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "143%",
                    letterSpacing: "0.193px",
                  }}
                >
                  <MailOutline
                    sx={{
                      color: "#424242",
                      height: "16px",
                      width: "16px",
                      marginRight: "8px",
                    }}
                  />
                  {data?.patient?.email ? data?.patient?.email : "N/A"}
                </Typography>
              </Box>

              <Box sx={{ mx: 2 }}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#303030",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "143%",
                    letterSpacing: "0.193px",
                  }}
                >
                  <Physician color="#424242" />
                  <span style={{ marginLeft: "8px" }}>
                    {data?.surgeonName ? data?.surgeonName : "N/A"}
                  </span>
                </Typography>
              </Box>

              <Box>
                <IconButton
                  sx={{ ml: "12px", alignItems: "end" }}
                  size="small"
                  onClick={() => {
                    goToProfile(
                      data?.patient?._id,
                      "patient",
                      data?.patient?.firstName,
                    );
                  }}
                >
                  <LaunchRounded fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              height: !isShow && "calc(100vh - 200px)", // Fixed spacing
              overflowY: !isShow && "auto", // Allow scroll behavior
            }}
            className="DialogContent"
          >
            {/* content */}
            {!isShow && (
              <Box
                sx={{
                  marginTop: "12px",
                  marginBottom: 1,
                }}
              >
                <ContentDrawer
                  {...{
                    type,
                    isShow,
                    setIsShow,
                    data,
                    user,
                    refetchQueueAgain,
                    selectedValue,
                    setSelectedValue,
                    patientToggleOption,
                    setPatientToggleOption,
                    handlePatientToggle,
                    careAllyData,
                    chatPage,
                    setChatPage,
                    careAllyChatPage,
                    setCareAllyChatPage,
                    isFullScreen,
                    setIsFullScreen,
                    setIsShowMessage,
                    selectedPatientStatus,
                  }}
                />
              </Box>
            )}
          </Box>
          {/* footer Ui  */}

          {!isFullScreen && !isShow && (
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                position: "absolute", // Make it fixed
                bottom: 0, // Align it at the bottom
                right: 10,
                backgroundColor: "white", // To ensure the background stays visible on top of other content
                zIndex: 1000, // Ensure it stays above other elements
                paddingY: 2,
                width: "100%",
              }}
            >
              {/* Pagination on the left */}
              {/* <Pagination count={10} shape="rounded" size="small" /> */}

              {/* Buttons on the right */}
              <Stack direction="row" spacing={2}>
                {selectedPatientStatus === "pending" && (
                  <Button
                    variant="outlined"
                    onClick={handleModalOpen}
                    sx={{ fontWeight: 600 }}
                    color="error"
                  >
                    Ignore
                  </Button>
                )}
                {/* <Button
                  variant="outlined"
                  startIcon={<Email />}
                  sx={{ fontWeight: 600 }}
                  onClick={handleEmailModalOpen}
                >
                  Email Patient
                </Button> */}
                <Button
                  variant="outlined"
                  startIcon={<Call color="#6235ED" />}
                  sx={{ fontWeight: 600 }}
                  onClick={() =>
                    openCallUnregisterPatientModal(
                      `${data?.patient?.firstName} ${data?.patient?.lastName}`,
                      data?.patient?.phone,
                      data?.patient?._id,
                    )
                  }
                >
                  Call Patient
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<Conversation color="#6235ED" />}
                  sx={{ fontWeight: 600 }}
                  onClick={() => {
                    setIsShow(!isShow);
                    setIsShowMessage(true);
                  }}
                >
                  Send Message
                </Button>
              </Stack>
            </Box>
          )}

          {isShow && isShowMessage && (
            <Box sx={{ mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      setIsShow(false);
                      setIsShowMessage(false);
                    }}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                  <Typography>All Messages</Typography>
                </Box>
                <ChatBotDropdownButton
                  chatBotEnabled={data?.patient?.chatMode}
                  selectedValue={selectedValue}
                  setSelectedValue={setSelectedValue}
                  patientId={data?.patient?._id}
                  // refetch={
                  //   refetchPatientAgain
                  // }
                  value={0}
                  // pathname={pathname}
                  // refetchChat={refetchChat}
                  isV2True={true}
                />
              </Box>
              {careAllyData && (
                <PatientToggleButton
                  {...{
                    patientToggleOption,
                    setPatientToggleOption,
                    handlePatientToggle,
                  }}
                />
              )}
              {patientToggleOption === "patient" && (
                <ConversationTab
                  id={data?.patient?._id}
                  consent={data?.patient?.consentResponse}
                  isDrawer={false}
                  selectedValue={selectedValue}
                  chatBot={data?.patient?.chatBotEnabled}
                  page={chatPage}
                  setPage={setChatPage}
                  status={data?.patient?.status}
                  value={0}
                  // refetchChat={refetchChat}
                  // chatData={chatData}
                  // isChatLoading={isChatLoading}
                  patient={data?.patient}
                  isPatientQueueV2={true}
                  setIsRefetchChat={null}
                  isCareAlly={!!careAllyData}
                />
              )}

              {patientToggleOption === "careAlly" && careAllyData && (
                <CareAllyTab
                  id={data?.patient?._id}
                  consent={careAllyData?.consentResponse}
                  isDrawer={false}
                  careAllyData={careAllyData}
                  page={careAllyChatPage}
                  selectedValue={selectedValue}
                  setPage={setCareAllyChatPage}
                  status={data?.patient?.status}
                  value={0}
                  isPatientQueueV2={true}
                />
              )}
            </Box>
          )}

          {isShow && type === "new_media" && !isShowMessage && (
            <Box sx={{ mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <IconButton size="small" onClick={() => setIsShow(false)}>
                  <ArrowBackIosIcon />
                </IconButton>
                <Typography>All Images</Typography>
              </Box>
              <GalleryTab id={data?.patient?._id} />
            </Box>
          )}

          {isShow && type === "call" && !isShowMessage && (
            <Box sx={{ mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <IconButton size="small" onClick={() => setIsShow(false)}>
                  <ArrowBackIosIcon />
                </IconButton>
                <Typography>All Calls</Typography>
              </Box>
              <CallTab
                user={user}
                id={data?.patient?._id}
                status={data?.patient?.status}
                receiverId={data?.patient?.phone}
                queueIds={data?.items?.map((item) => item?._id) || []}
                isCallType={data?._id?.type === "call"}
                {...{
                  queueData: data,
                  queueStatus: data?.status,
                  refetchQueueAgain,
                  // setOpenChat,
                  name: "Calls",
                }}
              />
            </Box>
          )}

          {isShow && type === "form_submission" && !isShowMessage && (
            <Box sx={{ mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <IconButton size="small" onClick={() => setIsShow(false)}>
                  <ArrowBackIosIcon />
                </IconButton>
                <Typography>All Forms</Typography>
              </Box>
              <FormTab
                user={user}
                id={data?.patient?._id}
                status={data?.patient?.status}
                patientName={
                  data?.patient
                    ? `${data?.patient?.firstName} ${data?.patient?.lastName}`
                    : "Patient"
                }
                isFormType={data?._id?.type === "form_submission"}
                queueIds={
                  data?.items?.map((item) => item?.metadata?.assignFormId) || []
                }
                {...{
                  queueData: data,
                  queueStatus: data?.status,
                  refetchQueueAgain,
                  //  setOpenChat,
                  name: "Forms",
                }}
              />
            </Box>
          )}

          {isShow && type === "task" && !isShowMessage && (
            <Box sx={{ mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <IconButton size="small" onClick={() => setIsShow(false)}>
                  <ArrowBackIosIcon />
                </IconButton>
                <Typography>All Tasks</Typography>
              </Box>
              <TaskTab
                user={user}
                id={data?.patient?._id}
                status={data?.patient?.status}
                isPatientQueueDrawer={true}
                {...{
                  queueData: data,
                  queueStatus: data?.status,
                  refetchQueueAgain,
                }}
              />
            </Box>
          )}
        </Box>
      </Drawer>
      <IgnoreModal
        open={isModalOpen}
        onClose={handleModalClose}
        onConfirm={handleIgnoreConfirm}
        title="Confirm Ignore"
        content="Are you sure you want to ignore this patient queue?"
      />
      <EmailModal
        open={isEmailModalOpen}
        onClose={handleEmailModalClose}
        onSendEmail={handleSendEmail}
        onRegenerate={handleRegenerateEmail}
      />
      {openCallUnregisterPatient?.value && (
        <OutgoingCallComponent
          open={openCallUnregisterPatient?.value}
          name={openCallUnregisterPatient?.name}
          phone={openCallUnregisterPatient?.phone}
          patientId={openCallUnregisterPatient?.patientId}
          handleClose={handleCloseCallUnregister}
        />
      )}
    </>
  );
};

export default CustomDrawer;
