import { Box, Drawer, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Header from "./Header";
import Body from "./Body";

const Content = ({
  name,
  user,
  path,
  patient,
  acuity,
  value,
  id,
  status,
  consent,
  isJourney,
  queueData,
  queueStatus,
  refetchAgain,
  refetchCount,
  refetchQueueAgain,
  setOpenChat,
  state,
}) => {
  return (
    <>
      <Header
        {...{
          name,
          user,
          path,
          patient,
          acuity,
          value,
          id,
          status,
          consent,
          surgeonName: queueData?.surgeonName,
          surgeryDate: queueData?.surgeryDate,
          surgeryType: queueData?.items?.[0]?.surgeryType,
        }}
      />
      <Body
        patient={patient}
        isJourney={isJourney}
        {...{
          queueData,
          queueStatus,
          refetchAgain,
          refetchCount,
          refetchQueueAgain,
          setOpenChat,
          state,
        }}
      />
    </>
  );
};

export function QueueDetailDrawerV2({
  state,
  toggleDrawer,
  name,
  user,
  path,
  patient,
  acuity,
  value,
  id,
  status,
  consent,
  isJourney,
  queueData,
  queueStatus,
  refetchAgain,
  refetchCount,
  refetchQueueAgain,
  setOpenChat,
}) {
  return (
    <React.Fragment key={"anchor"}>
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: "75%",
            padding: "20px",
            maxHeight: "100vh",
            overflowY: "hidden",
          },
        }}
        anchor="right"
        open={state}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
            Patient Queue Detail
          </Typography>
          <CloseIcon onClick={toggleDrawer(false)} sx={{ cursor: "pointer" }} />
        </Box>
        <Content
          {...{
            name,
            user,
            path,
            patient,
            acuity,
            value,
            id,
            status,
            consent,
            isJourney,
            queueData,
            queueStatus,
            refetchAgain,
            refetchCount,
            refetchQueueAgain,
            setOpenChat,
            state,
          }}
        />
      </Drawer>
    </React.Fragment>
  );
}
