import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CircularLoader, SolidButton } from "../../../../Components";
import OutlinedButton from "../../../../Components/OutlinedButton";
import { useIsMobile } from "../../../../contexts/isMobile";
import {
  useAssignBundleToPatientMutation,
  useGetBundlesOptionsQuery,
} from "../../../../store/bundlingsSlice";

export const BundleModal = ({ open, setOpen, pid, fetchAgain, status }) => {
  const isMobile = useIsMobile();
  const { isLoading, isError, data, refetch } = useGetBundlesOptionsQuery();
  const [assignBundleToPatient, { isLoading: isLoadingAssignJourney }] =
    useAssignBundleToPatientMutation();

  const [assign, setAssign] = useState("");
  const [selectedAssign, setSelectedAssign] = useState("");

  const resetValues = () => {
    setAssign("");
    setSelectedAssign("");
  };

  useEffect(() => {
    refetch();
  }, []);

  if (isLoading) {
    return (
      <div>
        <CircularLoader />
      </div>
    );
  }

  // if (isError) {
  //   return <div>some error</div>;
  // }

  const handleChange = (event, newValue) => {
    if (newValue) {
      setSelectedAssign(newValue.title); // Set the selected title
      setAssign(newValue._id); // Set the _id of the selected bundle
    } else {
      setSelectedAssign(""); // Reset when cleared
      setAssign(""); // Reset when cleared
    }
  };

  const handleAssign = () => {
    if (status === "active") {
      if (selectedAssign === "" || assign === "") {
        toast.error("Please pick a bundle from the dropdown menu first.");
        return;
      }

      assignBundleToPatient({
        bundleId: assign,
        patientId: pid,
      })
        .unwrap()
        .then(() => {
          fetchAgain();
          resetValues();
          setOpen(false);
          toast.success("Successfully assigned!");
        })

        .catch((error) => {
          toast.error(error?.data?.error);
          setOpen(true);
        });
    } else {
      toast.error("The patient status is currently inactive.");
      setOpen(false);
    }
  };

  const handleClose = () => {
    resetValues();
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "80%" : 700,
            height: isMobile ? 300 : 400,
            bgcolor: "#F5F5F5",
            p: 4,
            borderRadius: "10px",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              PRO Bundle
            </Typography>
            <Box sx={{ cursor: "pointer" }} onClick={handleClose}>
              <CloseIcon />
            </Box>
          </Stack>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={12} xs={12}>
              <Typography fontSize={14} mt={2}>
                Assign PRO Bundle <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                value={data?.data?.find((item) => item._id === assign) || null} // Ensure controlled component
                onChange={handleChange}
                options={data?.data || []} // Pass the array of objects directly
                getOptionLabel={(option) => option.title || ""} // Display the title
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                } // Ensure correct matching
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              mt: isMobile ? "2rem" : "10rem",
              justifyContent: "flex-end",
            }}
          >
            <OutlinedButton label="Cancel" handleClick={handleClose} />
            <SolidButton
              label="Assign"
              onClick={handleAssign}
              disabled={isLoadingAssignJourney}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
