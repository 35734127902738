export function KIMISvg() {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <path
          id="Vector"
          d="M13.706 1.31301H8.29401C6.97288 1.31301 5.70584 1.83776 4.77156 2.77185C3.83728 3.70594 3.31228 4.97288 3.31201 6.29401V11.706C3.31201 13.0273 3.8369 14.2945 4.77121 15.2288C5.70551 16.1631 6.9727 16.688 8.29401 16.688H13.706C15.0273 16.688 16.2945 16.1631 17.2288 15.2288C18.1631 14.2945 18.688 13.0273 18.688 11.706V6.29401C18.688 4.9727 18.1631 3.70551 17.2288 2.77121C16.2945 1.8369 15.0273 1.31201 13.706 1.31201V1.31301Z"
          stroke="white"
          stroke-width="1.5"
        />
        <path
          id="Vector_2"
          d="M18.606 12.5881H20.225C20.4968 12.5881 20.7576 12.4801 20.9498 12.2879C21.142 12.0956 21.25 11.8349 21.25 11.5631V6.43809C21.25 6.16624 21.142 5.90553 20.9498 5.7133C20.7576 5.52108 20.4968 5.41309 20.225 5.41309H18.605M3.395 12.5881H1.775C1.6404 12.5881 1.50711 12.5616 1.38275 12.5101C1.25839 12.4586 1.1454 12.3831 1.05022 12.2879C0.955035 12.1927 0.879535 12.0797 0.828023 11.9553C0.776512 11.831 0.75 11.6977 0.75 11.5631V6.43809C0.75 6.16624 0.857991 5.90553 1.05022 5.7133C1.24244 5.52108 1.50315 5.41309 1.775 5.41309H3.395"
          stroke="white"
          stroke-width="1.5"
        />
        <path
          id="Vector_3"
          d="M1.76489 5.41299V1.31299M20.2249 5.41299L20.2149 1.31299M8.94989 12.237C9.49499 12.778 10.2319 13.0817 10.9999 13.0817C11.7679 13.0817 12.5048 12.778 13.0499 12.237M6.87989 7.97499L7.90289 6.94999L8.92789 7.97499M13.0299 7.97499L14.0549 6.94999L15.0789 7.97499"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}
