import { useState } from "react";
import { Box, Button, Chip, Popover, Typography } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

export default function TimelinePopover({ data }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      {data && (
        <Button
          onClick={handleClick}
          variant="text"
          sx={{
            textTransform: "none",
            textDecoration: "underline",
            color: "black",
          }}
        >
          <Box component="span" sx={{ fontWeight: 600 }}>
            {`Step ${data?.current_stage_index}:`}
          </Box>
          {data?.stages[data?.current_stage_index - 1]?.name
            .toLowerCase()
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")}
        </Button>
      )}

      {data?.status && (
        <Chip
          label={data?.status
            ?.toLowerCase()
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")}
          size="small"
          variant="outlined"
          sx={{ borderRadius: "4px" }}
          color={
            data?.is_interrupted
              ? "error"
              : data?.status === "completed"
                ? "success"
                : "warning"
          }
        />
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            borderRadius: "8px",
            display: "flex", // Makes the container a flexbox
            alignItems: "center", // Centers items vertically
            justifyContent: "center", // Centers items horizontally
          },
        }}
      >
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {data?.stages?.map((stage, index) => (
            <TimelineItem key={stage.name}>
              <TimelineSeparator>
                <TimelineDot
                  color={
                    index + 1 === data?.current_stage_index ? "primary" : "grey"
                  }
                />
                {index !== data?.stages?.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Typography
                  sx={{
                    fontSize: "0.875rem",
                    color: "text.primary",
                  }}
                >
                  <Box component="span" sx={{ fontWeight: 600 }}>
                    {`Step ${index + 1}:`}
                  </Box>{" "}
                  {stage.name
                    .toLowerCase()
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Popover>
    </Box>
  );
}
