import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { InactivityCheck } from "../../Components/InactivityCheck";
import { useIsMobile } from "../../contexts/isMobile";
import { hasScope } from "../../utils";
import { CarePathwayAccordion } from "./CarePathway";
import { ContentsAccordian } from "./ContentManager/index";
import { HospitalsAccordian } from "./Hospitals/index";
import { PhysicianAccordian } from "./Physician";
import { ProcedureTypeAccordian } from "./ProcedureType";
import { TagsAccordian } from "./Tags/TagsAccordian";
import { TeamsAccordian } from "./Teams/TeamsAccordian";
import { BundlingAccordion } from "./Bundling";

export function Settings() {
  const isMobile = useIsMobile();
  const { user } = useSelector((state) => state.user);

  return (
    <>
      <Grid
        container={true}
        spacing={2}
        padding={isMobile ? "1.5rem 1rem" : "1.5rem 2rem"}
      >
        {hasScope(user.scopes, "settings/tags:read") && (
          <Grid item={true} md={12} xs={12}>
            <TagsAccordian scopes={user.scopes} />
          </Grid>
        )}
        {/* We commented it for deployment because we are working on it  */}
        {hasScope(user.scopes, "settings/teams:read") && (
          <Grid item={true} md={12} xs={12}>
            <TeamsAccordian scopes={user.scopes} />
          </Grid>
        )}

        {hasScope(user.scopes, "settings/sites:read") && (
          <Grid item={true} md={12} xs={12}>
            <HospitalsAccordian scopes={user.scopes} />
          </Grid>
        )}

        {hasScope(user.scopes, "settings/contentManagers:read") && (
          <Grid item={true} md={12} xs={12}>
            <ContentsAccordian scopes={user.scopes} />
          </Grid>
        )}

        {hasScope(user.scopes, "settings/contentManagers:read") && (
          <Grid item md={12} xs={12}>
            <ProcedureTypeAccordian scopes={user.scopes} />
          </Grid>
        )}

        {hasScope(user.scopes, "settings/contentManagers:read") && (
          <Grid item md={12} xs={12}>
            <PhysicianAccordian scopes={user.scopes} />
          </Grid>
        )}

        {hasScope(user.scopes, "settings/contentManagers:read") && (
          <Grid item md={12} xs={12}>
            <CarePathwayAccordion scopes={user.scopes} />
          </Grid>
        )}

        {hasScope(user.scopes, "settings/contentManagers:read") && (
          <Grid item md={12} xs={12}>
            <BundlingAccordion scopes={user.scopes} />
          </Grid>
        )}
      </Grid>
      <InactivityCheck />
    </>
  );
}
