import { useDebouncedValue } from "@mantine/hooks";
import {
  Alert,
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PaginationBar } from "../../../Components";
import { careProgramsData } from "../../../careProgramUtils.js";
import { useIsMobile } from "../../../contexts/isMobile";
import { getTokenFromLocalStroage, toSnakeCase } from "../../../utils";
import { LastCell } from "../../patientQueue/LastCell";
import "./main.css";
import { Visibility } from "@mui/icons-material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BlockIcon from "@mui/icons-material/Block";
import CallIcon from "@mui/icons-material/Call";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import EmojiEmotionsOutlinedIcon from "@mui/icons-material/EmojiEmotionsOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
import PaymentsIcon from "@mui/icons-material/Payments";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import SentimentDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentDissatisfiedOutlined";
import ShieldIcon from "@mui/icons-material/Shield";
import SouthIcon from "@mui/icons-material/South";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import FiltersDialog from "../../../Components/FiltersDialog";
import LinkQueueModal from "../../../Components/LinkQueueModal.js";
import { UnknownCallerMessageDrawer } from "../../../Components/MessageLayout/UnknownCallerMessageDrawer.js";
import { PatientProfileDrawer } from "../../../Components/PatientProfile/PatientProfileDrawer.js";
import SnackbarMsg from "../../../Components/SnackbarMsg";
import CareProgram from "../../../Components/TableFields/CareProgram.js";
import { PatientInfo } from "../../../Components/TableFields/PatientInfo.js";
import { patientQueueFilterData } from "../../../FilterUtils";
import { Conversation } from "../../../assets/svgs/PatientProfileTabsSvgs/Conversation.js";
import { useChatValues } from "../../../contexts/chatValue.js";
import { useIsHovered } from "../../../contexts/isHovered";
import { useGetProceduresOptionsQuery } from "../../../store/proceduresSlice.js";
import { addInParams } from "../../../utils";
import filterByDate from "../../patientQueue/DateFilterFunction.js";
import DialogueAccordion from "../../patientQueue/DialogueAccordion.js";
import WhatwentwrongDialog from "../../patientQueue/Profile/WhatwentwrongDialog";
import CustomMultiSelect from "./CustomMultiSelect.jsx";

const typeData = [
  { label: "Messages", key: "message", icon: "ChatBubbleOutlineIcon" },
  { label: "Photo Uploads", key: "new_media", icon: "CollectionsOutlinedIcon" },
  { label: "Tasks", key: "task", icon: "AssignmentIcon" },
  { label: "Forms", key: "form_submission", icon: "FeedOutlinedIcon" },
  {
    label: "Care Ally Messages",
    key: "care_ally_message",
    icon: "HandshakeOutlinedIcon",
  },
  { label: "Call", key: "call", icon: "CallIcon" },
  { label: "Dialogue Assessments", key: "dialogue", icon: "ForumOutlinedIcon" },
];

const iconMapping = {
  ChatBubbleOutlineIcon: ChatBubbleOutlineIcon,
  CollectionsOutlinedIcon: CollectionsOutlinedIcon,
  AssignmentIcon: AssignmentIcon,
  FeedOutlinedIcon: FeedOutlinedIcon,
  HandshakeOutlinedIcon: HandshakeOutlinedIcon,
  ForumOutlinedIcon: ForumOutlinedIcon,
  CallIcon: CallIcon,
};

export function ProgramSelect({ searchProgram, setSearchProgram }) {
  const data = careProgramsData;
  const isMobile = useIsMobile();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSearchProgram(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  const porgramTagsText = (selected) =>
    selected?.map((tagID) => {
      const tag = data?.find((tag) => tag.value === tagID);
      return tag ? tag.label : "";
    });

  return (
    <Box sx={{ padding: !isMobile && "10px" }}>
      <FormControl sx={{ width: "105%", margin: "-5px" }}>
        <InputLabel id="demo-multiple-checkbox-label">Care Programs</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={searchProgram || []}
          onChange={handleChange}
          input={
            <OutlinedInput
              sx={{ height: "2.4em", margin: "10px" }}
              label="Care Programs"
            />
          }
          renderValue={(selected) => porgramTagsText(selected).join(", ")}
          style={{ borderColor: "#dad4d4" }}
          MenuProps={MenuProps}
        >
          {data?.map((item) => (
            <MenuItem key={item.id} value={item.value}>
              <Checkbox checked={searchProgram.indexOf(item.value) > -1} />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export function QueueTable({
  value,
  columns,
  refetchCount,
  acuityCount,
  queueData,
  selectedPatientIds,
  setSelectedPatientIds,
  page,
  setPage,
  handleSorting,
  sortPatientOrder,
  sortAcuityOrder,
  refetchQueueAgain,
  sortTaskCategoryOrder,
  sortPhysicianNameOrder,
  sortProcedureTypeOrder,
  sortQueueTypeOrder,
  sortSocialNeedsOrder,
  sortContactTimeOrder,
  isV2Profile,
}) {
  console.log(columns);
  // Create a URLSearchParams object from the location search string
  const { setChatMessages } = useChatValues();
  const isMobile = useIsMobile();
  const { hovered } = useIsHovered();
  const prevDataRef = useRef();
  const unknownCallerDrawerRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchAbleFields = ["name", "task category", "acuity"];
  const selectedPatientStatus =
    location.pathname.split("/")[1] === "queue"
      ? localStorage.getItem("selectedPatientStatus1")
      : localStorage.getItem("selectedPatientStatus");
  const [selectedFields, setSelectedFields] = useState([...searchAbleFields]);
  const { user } = useSelector((state) => state.user);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedValue] = useDebouncedValue(searchTerm, 200);
  const [status, setStatus] = useState("pending");
  const [openRows, setOpenRows] = useState([]);
  const [linkQueueModalOpen, setLinkQueueModalOpen] = useState({
    value: false,
    data: null,
    results: null,
    taskCategory: null,
    callerIdentification: null,
  });
  const [filterData, setFilterData] = useState({});
  const [rows, setRows] = useState([]);
  const [searchProgram, setSearchProgram] = useState([]);
  const navigate = useNavigate();
  const queries = {};
  const [queriesValue, setQueriesValue] = useState(
    localStorage.getItem("queriesValue")
      ? JSON.parse(localStorage.getItem("queriesValue"))
      : {},
  );
  const [open, setOpen] = useState(false);
  const [chatbotResponseCritique, setChatbotResponseCritique] = useState("");
  const [selectedAcuity, setSelectedAcuity] = useState("");
  const [proposedChatbotResponse, setProposedChatbotResponse] = useState("");
  const [selectedTaskLabel, setSelectedTaskLabel] = useState("");
  const [currentItemId, setCurrentItemId] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [patientQueues, setPatientQueues] = useState([]);
  const [selectOriginalMessage, setSelectOriginalMessage] = useState(null);
  const [openDislikeModal, setOpenDislikeModal] = useState(false);
  const [isTaskCategorySelected, setIsTaskCategorySelected] = useState(true);
  const [isChatbotResponse, setIsChatbotResponse] = useState(true);
  const [chatbotResponse, setChatbotResponse] = useState(null);
  const [isAcuitySelected, setIsAcuitySelected] = useState(true);
  const [selectedValue, setSelectedValue] = useState(
    localStorage.getItem("selectedValue")
      ? localStorage.getItem("selectedValue")
      : null,
  );
  const [sortCreatedAtOrder, setSortCreatedAtOrder] = useState(
    localStorage.getItem("sortCreatedAtOrder")
      ? JSON.parse(localStorage.getItem("sortCreatedAtOrder"))
      : { field: "createdAt", sort: "desc" },
  );
  const [sortField, setSortField] = useState(
    localStorage.getItem("sortField") ? localStorage.getItem("sortField") : "",
  );
  const [sortSurgeonName, setSortSurgeonName] = useState(
    localStorage.getItem("sortSurgeonName")
      ? JSON.parse(localStorage.getItem("sortSurgeonName"))
      : { field: "surgeonName", sort: "asc" },
  );
  const [sortOrder, setSortOrder] = useState(
    localStorage.getItem("sortOrder") ? localStorage.getItem("sortOrder") : "",
  );
  const [createdAfter, setCreatedAfter] = useState(
    localStorage.getItem("createdAfter")
      ? localStorage.getItem("createdAfter")
      : "",
  );
  const [patientQueuesData, setPatientQueuesData] = useState([]);
  const [isRowHovered, setIsRowHovered] = useState(null);
  const [hasRunOnce, setHasRunOnce] = useState(false);
  const tabsValue = [
    "message",
    "new_media",
    "form_submission",
    "care_ally_message",
    "dialogue",
  ];

  const handleRefetch = () => {
    // refetch()
  };

  const handleFieldSelect = (option, checked) => {
    if (checked) {
      setSelectedFields([...selectedFields, option]);
    } else {
      setSelectedFields(selectedFields.filter((o) => o !== option));
    }
  };

  // for (const field of selectedFields) {
  //   queries[field] = debouncedValue;
  // }

  queries["name"] = debouncedValue;
  queries["acuity"] = queriesValue?.acuity || [];
  queries["status"] = queriesValue?.status || "pending";
  queries["taskCategory"] = queriesValue?.taskCategory || [];
  queries["program"] = queriesValue?.program || [];
  queries["surgeryType"] = queriesValue?.surgeryType || [];

  // const { data, refetch, isLoading, isSuccess } = useGetPatientQueuesQuery(
  // 	{
  // 		page,
  // 		userId: user.id,
  // 		type: tabsValue[value],
  // 		sortField,
  // 		sortOrder,
  // 		createdAfter,
  // 		...queries,
  // 	},
  // 	{ refetchOnMountOrArgChange: true }
  // )

  const { data: procedureTypeData } = useGetProceduresOptionsQuery();

  // const totalPages = data?.totalPages;
  //  const patientQueues = data?.patientQueues;

  const calculateAge = (dob) => {
    if (dob) {
      // Check if the date is in ISO 8601 format
      const birthDate = dob.includes("T")
        ? new Date(dob)
        : new Date(`${dob}T00:00:00.000Z`);
      const today = new Date();
      let age_now = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
      }
      return `${age_now}Y`;
    } else {
      return `N/A`;
    }
  };

  function renderFeedbackIcons(
    itemId,
    userFeedbackData,
    originalMessage,
    patientData,
  ) {
    const iconSkeleton = {
      color: "#979797",
      cursor: "pointer",
    };

    const dislikeIcon = {
      cursor: "pointer",
      color: "red",
    };

    const feedbackIcons = {
      like: (
        <EmojiEmotionsOutlinedIcon
          fontSize="medium"
          style={{ color: "green" }}
        />
      ),
      dislike: (
        <SentimentDissatisfiedOutlinedIcon
          fontSize="medium"
          style={dislikeIcon}
          onClick={() => {
            setCurrentItem(patientData);
            setCurrentItemId(itemId);
            setSelectOriginalMessage(originalMessage);
          }}
        />
      ),
    };

    return (
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "start" }}
      >
        <>
          {userFeedbackData?.some((item) => item?.status === "like") &&
            feedbackIcons.like}
          {!userFeedbackData?.some((item) => item?.status === "like") && (
            <EmojiEmotionsOutlinedIcon
              fontSize="medium"
              onClick={() => addReaction(itemId, "like")}
              style={iconSkeleton}
            />
          )}
          &ensp;
        </>
        {userFeedbackData?.some((item) => item?.status === "dislike") &&
          feedbackIcons.dislike}
        {!userFeedbackData?.some((item) => item?.status === "dislike") && (
          <SentimentDissatisfiedOutlinedIcon
            fontSize="medium"
            onClick={() => {
              setCurrentItem(patientData);
              setCurrentItemId(itemId);
              setSelectOriginalMessage(originalMessage);
            }}
            style={iconSkeleton}
          />
        )}
      </div>
    );
  }

  const addReaction = async (queueID, status) => {
    if (status === "dislike") {
      if (!selectedAcuity || !selectedTaskLabel || !chatbotResponseCritique) {
        toast.error("Please fill out the form in-order to submit the feedback");
        return;
      }
    }

    try {
      await axios
        .put(
          `${process.env.REACT_APP_BASE_API_URL}/patientQueues/addReaction/${queueID}`,
          {
            status: status,
            userId: user.id,
            correctTaskLabel: selectedTaskLabel,
            correctAcuityLabel: selectedAcuity,
            proposedChatbotResponse: proposedChatbotResponse,
            chatbotResponseCritique: chatbotResponseCritique,
          },
          config,
        )
        .then((res) => {
          setOpen(true);
          // if(patientDrawer){
          // 	setUserFeedback2([res?.data?.feedback])
          // }
        });
    } catch (error) {
      toast.error("Error:" + error);
    }
    setCurrentItemId("");
    setSelectedTaskLabel("");
    setSelectedAcuity("");
    setProposedChatbotResponse("");
    setChatbotResponseCritique("");
    setIsAcuitySelected(false);
    setIsChatbotResponse(false);
    setIsTaskCategorySelected(false);
    setOpenDislikeModal(false);
    if (handleRefetch) {
      handleRefetch();
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const setCurrentItem = async (patientData) => {
    setSelectedAcuity(patientData?.acuity);
    setSelectedTaskLabel(patientData?.taskCategory);
    setChatbotResponse(patientData?.chatbotReply);
    setOpenDislikeModal(true);
  };

  const handleChangePage = (event, newPage) => {
    if (isV2Profile) {
      localStorage.setItem("page3", newPage);
      setPage(newPage);
      addInParams("page", newPage);
      console.log("Page is changed");
    } else {
      setSelectedPatientIds([]);
      localStorage.setItem("page", newPage);
      setPage(newPage);
      addInParams("page", newPage);
      console.log("Page is changed");
    }
  };

  const timeDifference = (createdDate) => {
    const startDate = new Date(createdDate);
    const endDate = new Date();
    let unit = "";
    let time = "";
    const timeDifference = endDate - startDate;

    if (timeDifference < 1000) {
      unit = "seconds";
      time = Math.floor(timeDifference / 1000);
    } else if (timeDifference < 1000 * 60) {
      unit = "seconds";
      time = Math.floor(timeDifference / 1000);
    } else if (timeDifference < 1000 * 60 * 60) {
      unit = "minutes";
      time = Math.floor(timeDifference / (1000 * 60));
    } else if (timeDifference < 1000 * 60 * 60 * 24) {
      unit = "hours";
      time = Math.floor(timeDifference / (1000 * 60 * 60));
    } else {
      unit = "days";
      time = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    }

    return `${time} ${unit}`;
  };

  const redirectToEditPage = (id, name) => {
    // localStorage.setItem('pageName2', JSON.stringify(name))
    navigate(`/profile/${id}`, { state: { action: "read-only", id } });
  };

  const config = {
    headers: { Authorization: `Bearer ${getTokenFromLocalStroage()}` },
  };
  const getColorStyles = (acuity) => {
    if (acuity === "high") {
      return {
        marginRight: "5px",
        color: "#DC362E",
      };
    } else if (acuity === "medium") {
      return {
        marginRight: "5px",
        color: "#FFB400",
      };
    } else if (acuity === "N/A") {
      return {
        marginRight: "5px",
        color: "black",
      };
    } else {
      return {
        marginRight: "5px",
        color: "#63A02C",
      };
    }
  };
  function calculateDaysRemaining(surgeryDateStr) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const surgeryDate = new Date(surgeryDateStr);
    surgeryDate.setHours(0, 0, 0, 0);
    const differenceMs = surgeryDate - today;
    if (differenceMs < 0) {
      return "Procedure date has passed";
    }
    const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

    return `${differenceDays} days until procedure`;
  }
  useEffect(() => {
    if (patientQueues) {
      const initialOpenState = new Array(patientQueues.length).fill(false);
      setOpenRows(initialOpenState);
    }
  }, [patientQueues]);

  const handleRowToggle = (index) => {
    const newOpenRows = [...rows];
    newOpenRows[index] = !newOpenRows[index];
    setRows(newOpenRows);
  };

  const showMarkAsResolved = (index) => {
    const hoveredPatient = patientQueues[index];
    setIsRowHovered(hoveredPatient?._id);
  };

  const formatProgram = (program) => {
    const words = program.split("_");

    const formattedString = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return formattedString;
  };

  const calculateNumberOfDays = (createdDate) => {
    const today = new Date();
    const date = new Date(createdDate);

    const timeDifference = today - date;

    const numberOfDays = Math.floor(timeDifference / (24 * 60 * 60 * 1000));

    return numberOfDays + " days ago";
  };
  const getCarePhase = (surgeryDate) => {
    if (new Date(surgeryDate) > new Date()) {
      return "Post-op";
    } else if (new Date(surgeryDate) < new Date()) {
      return "Pre-op";
    }
  };

  const [openUnknownCallerChat, setOpenUnknownCallerChat] =
    React.useState(false);

  const [openPatientProfileDrawer, setOpenPatientProfileDrawer] =
    useState(false);

  const toggleUnknownCallerDrawer = (open) => () => {
    // addInParams('formType', open?.clickedValue )
    // addInParams('patientId', open?.patientId)
    // addInParams('queueId', open?.queueId)
    // setClickedValue(open?.clickedValue)
    setOpenUnknownCallerChat(open);
    // setChatMessages([]);

    // if (open?.open) {
    //   messageSeen(patientId).then(() => {
    //     refetch();
    //   });
    //   setPage(1);
    // }
  };

  const togglePatientProfileDrawer = (open) => () => {
    addInParams("patientId", open?.patient?._id);
    addInParams("tab", open?.isJourney ? "6" : open?.value ? "0" : null);

    setChatMessages([]);
    setOpenPatientProfileDrawer(open);
    // setChatMessages([]);

    // if (open?.open) {
    //   messageSeen(patientId).then(() => {
    //     refetch();
    //   });
    //   setPage(1);
    // }
  };

  const createRows = (
    items,
    selectedPatientIds,
    setSelectedPatientIds,
    isRowHovered,
    selectedPatientStatus,
    toggleUnknownCallerDrawer,
    openUnknownCallerChat,
    isV2Profile,
    togglePatientProfileDrawer,
    openPatientProfileDrawer,
    redirectToEditPage,
    updateStatus,
    refetchQueueAgain,
  ) => {
    const iconMap = {
      food: <RestaurantIcon />,
      transportation: <DirectionsCarIcon />,
      housing: <HouseOutlinedIcon />,
      safety: <ShieldIcon />,
      utility: <TipsAndUpdatesIcon />,
      financial: <PaymentsIcon />,
      urgent: <DirectionsRunIcon />,
    };
    const getCategoryColorStyles = (risk) => {
      const riskColors = {
        "High Risk": "#DC362E",
        "High risk": "#DC362E",
        "Moderate Risk": "#FFB400",
        "Rising Risk": "#FFB400",
        "Rising risk": "#FFB400",
        "Low Risk": "#63A02C",
        Healthy: "#63A02C",
      };

      return {
        marginRight: "5px",
        color: riskColors[risk] || "black",
      };
    };

    const getConsentColors = (consent) => {
      if (consent === "yes") {
        return {
          marginRight: "5px",
          color: "#63A02C",
        };
      } else if (consent === "no") {
        return {
          marginRight: "5px",
          color: "#DC362E",
        };
      } else {
        return {
          marginRight: "5px",
          color: "#FFB400",
        };
      }
    };

    const getColorStylesStatus = (status) => {
      if (status === "active") {
        return {
          marginRight: "5px",
          color: "#63A02C",
        };
      } else if (status === "inactive") {
        return {
          marginRight: "5px",
          color: "#DC362E",
        };
      } else {
        return {
          marginRight: "5px",
          color: "#FFB400",
        };
      }
    };

    const getColorStylesSocialRisk = (risk) => {
      const riskColors = {
        low: "#63A02C",
        high: "#DC362E",
        medium: "#FFB400",
      };

      return {
        marginRight: "5px",
        color: riskColors[risk] || "black",
      };
    };

    const getColorStylesEnrolled = (status) => {
      if (status) {
        return {
          marginRight: "5px",
          color: "#63A02C",
        };
      } else {
        return {
          marginRight: "5px",
          color: "#DC362E",
        };
      }
    };

    console.log("ITEMS:", items);

    return isV2Profile
      ? items?.map((item, index) => ({
          patientInfo: (
            <Box>
              {item ? (
                <PatientInfo
                  id={item?.patient?._id}
                  patient={item}
                  surgeryDate={item.surgeryDate}
                  firstName={item.firstName}
                  patientId={item._id}
                  path={item.path}
                  queueStatus={item.status}
                  onRefetch={handleRefetch}
                  FormData={item}
                  queueID={item._id}
                  acuity={item.acuity}
                  userFeedback={item.userFeedback}
                  originalMessage={item.originalMessage}
                  chatbotReply={item.chatbotReply}
                  reasoning={item.reasoning}
                  createdAt={item.createdAt}
                  value={value}
                  taskCategory={item.taskCategory}
                  refetchCount={refetchCount}
                  formId={item?.metadata?.assignFormId}
                  selectedPatientStatus={
                    item?.items ? selectedPatientStatus : item?.status
                  }
                  refetchQueueAgain={refetchQueueAgain}
                  isV2Profile={true}
                  togglePatientProfileDrawer={togglePatientProfileDrawer}
                />
              ) : (
                <>
                  <Chip
                    label={item?.metadata?.patientData?.name || "N/A"}
                    size="small"
                    sx={{
                      backgroundColor: "#B71C1C",
                      color: "white",
                      fontWeight: 600,
                    }}
                    onClick={toggleUnknownCallerDrawer({
                      value: true,
                      data: item?._id,
                      results: item?.metadata?.patientData,
                      taskCategory: item?.taskCategory,
                      callerIdentification:
                        item?.metadata?.callerIdentification,
                      calldata: item,
                    })}
                  />
                  <Typography
                    sx={{ color: "#979797", fontSize: 14, mt: "4px" }}
                  >
                    <span style={{ color: "#979797", fontWeight: 500 }}>
                      Ph:
                    </span>{" "}
                    {item?.metadata?.patientData?.phone_number || "N/A"}
                  </Typography>
                </>
              )}
            </Box>
          ),
          pcp: (
            <>
              {" "}
              <Typography
                variant="p"
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  textAlign: "left",
                }}
              >
                N/A
              </Typography>
            </>
          ),
          surgeonName: (
            <>
              <Typography
                variant="p"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                {item?.surgeonName ? item?.surgeonName : "N/A"}
              </Typography>
            </>
          ),
          procedureType: (
            <>
              {item?.surgeryDate || item?.surgeryType ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="p">
                    {item?.surgeryType || "Procedure Type Unavailable"}
                  </Typography>
                  <Typography variant="p">
                    {item?.surgeryDate
                      ? moment(item?.surgeryDate).format("MM-DD-YYYY")
                      : "Procedure Date Unavailable"}
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="p">N/A</Typography>
                </Box>
              )}
            </>
          ),
          surgeryDate: (
            <>
              <Typography
                variant="p"
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  textAlign: "left",
                }}
              >
                {item?.items?.[0]?.surgeryDate
                  ? moment(item?.surgeryDate).format("MM-DD-YYYY")
                  : "Procedure Date Unavailable"}
              </Typography>
            </>
          ),
          consent: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <>
                <span
                  style={{
                    ...getConsentColors(item?.consentResponse),
                    fontSize: "36px",
                  }}
                >
                  •
                </span>
                <Typography fontSize={14} color="black">
                  {item?.consentResponse === "NO_RESPONSE"
                    ? "No Response"
                    : item?.consentResponse?.charAt(0).toUpperCase() +
                      item?.consentResponse?.slice(1)}
                </Typography>
              </>
            </div>
          ),
          socialNeeds: (
            <div
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "4px",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                {(item?.socialNeeds || item?.socialRisks) &&
                (item?.socialNeeds?.length > 0 ||
                  item?.socialRisks?.length > 0) ? (
                  [
                    ...(item?.socialRisks || []),
                    ...(item?.socialNeeds || []),
                  ].map((icon, index) => {
                    const isSocialNeed = item?.socialNeeds?.includes(icon);
                    const color = isSocialNeed ? "#ffb400" : "#DC362E";
                    const IconElement = iconMap[icon] || <BlockIcon />;
                    return (
                      <Tooltip
                        key={index}
                        title={icon.charAt(0).toUpperCase() + icon.slice(1)}
                      >
                        <div
                          style={{
                            display: "flex", // Flexbox layout
                            alignItems: "center", // Vertical centering
                            justifyContent: "center", // Horizontal centering
                            cursor: "pointer",
                          }}
                        >
                          {React.cloneElement(IconElement, {
                            sx: { color, fontSize: 20 },
                          })}
                        </div>
                      </Tooltip>
                    );
                  })
                ) : (
                  <div
                    style={{
                      gridColumn: "1 / span 3", // Span across all columns
                      textAlign: "center", // Center the text within the grid
                    }}
                  >
                    N/A
                  </div>
                )}
              </div>
            </div>
          ),
          taskCategory: (
            <>
              {selectedPatientStatus === "resolved" || !item?.items ? (
                <>
                  <Typography fontSize={14} color="black">
                    {item.taskCategory ? item.taskCategory : "N/A"}
                  </Typography>
                  {tabsValue[value] !== "new_media" &&
                    tabsValue[value] !== "form_submission" && (
                      <Typography
                        fontSize={14}
                        color="#979797"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "100px",
                        }}
                      >
                        {item.originalMessage
                          ? item.originalMessage
                          : "No Message Available!"}
                      </Typography>
                    )}
                </>
              ) : (
                <>
                  <Typography
                    fontSize={14}
                    color="black"
                    sx={{ width: "120px" }}
                  >
                    {item?.uniqueTaskCategories?.length > 0
                      ? item?.uniqueTaskCategories
                          ?.map((item) => item)
                          ?.join(", ")
                      : "N/A"}
                  </Typography>
                  {tabsValue[value] !== "new_media" &&
                    tabsValue[value] !== "form_submission" && (
                      <Typography
                        fontSize={14}
                        color="#979797"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "120px",
                        }}
                      >
                        {item.items?.[0]?.originalMessage
                          ? item.items?.[0]?.originalMessage
                          : "No Message Available!"}
                      </Typography>
                    )}
                </>
              )}
            </>
          ),
          carePathways: (
            <CareProgram
              program={item.carePathways}
              isClickable={true}
              togglePatientProfileDrawer={togglePatientProfileDrawer}
              patient={item}
            />
          ),
          status: (
            <>
              <Typography
                fontSize={14}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {item.status && (
                  <span
                    style={{
                      ...getColorStylesStatus(item.status),
                      fontSize: "36px",
                    }}
                  >
                    •
                  </span>
                )}
                {item.status
                  ? item.status.charAt(0).toUpperCase() + item.status.slice(1)
                  : "N/A"}
              </Typography>
            </>
          ),
          socialRiskLevel: (
            <>
              <Typography
                fontSize={14}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    ...getColorStylesSocialRisk(item?.socialRiskLevel),
                    fontSize: "36px",
                  }}
                >
                  •
                </span>
                {item.socialRiskLevel
                  ? item.socialRiskLevel.charAt(0).toUpperCase() +
                    item.socialRiskLevel.slice(1)
                  : "N/A"}
              </Typography>
            </>
          ),
          riskCategory: (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontSize={14}
                  color="black"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      ...getCategoryColorStyles(
                        item?.riskCategory?.score
                          ? item?.riskCategory?.score
                          : "N/A",
                      ),
                      fontSize: "36px",
                    }}
                  >
                    •
                  </span>
                  {item.riskCategory.score
                    ? item?.riskCategory?.score?.charAt(0)?.toUpperCase() +
                      item?.riskCategory?.score?.slice(1)
                    : "N/A"}
                </Typography>
                {item.riskCategory.mfiValue && (
                  <Typography fontSize={14} color="black">
                    {`Score: ${item.riskCategory.mfiValue}`}
                  </Typography>
                )}
              </Box>
            </>
          ),
          selfEnrolled: (
            <>
              <Typography
                fontSize={14}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    ...getColorStylesEnrolled(item.selfEnrolled),
                    fontSize: "36px",
                  }}
                >
                  •
                </span>
                {item.selfEnrolled ? "Yes" : "No"}
              </Typography>
            </>
          ),
          action: (
            <Box>
              {item?.patient ? (
                <LastCell
                  id={item._id}
                  firstName={item?.patient?.firstName}
                  patientId={item?.patient?._id}
                  path={item?.patient?.path}
                  queueStatus={item?.status}
                  onRefetch={handleRefetch}
                  patient={item?.patient}
                  FormData={item}
                  acuity={item?.acuity}
                  userFeedback={item?.userFeedback}
                  originalMessage={item?.originalMessage}
                  chatbotReply={item?.chatbotReply}
                  reasoning={item?.reasoning}
                  createdAt={item?.createdAt}
                  value={value}
                  taskCategory={item?.taskCategory}
                  refetchCount={refetchCount}
                  formId={item?.metadata?.assignFormId}
                  isRowHovered={isRowHovered}
                  selectedPatientStatus={
                    item?.items ? selectedPatientStatus : item?.status
                  }
                  refetchQueueAgain={refetchQueueAgain}
                />
              ) : isV2Profile ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    sx={{
                      padding: "5px",
                      marginRight: "5px",
                      marginTop: "5px",
                    }}
                    // onClick={() =>
                    //   setLinkQueueModalOpen({
                    //     value: true,
                    //     data: item?._id,
                    //     results: item?.metadata?.patientData,
                    //     taskCategory: item?.taskCategory,
                    //     callerIdentification: item?.metadata?.callerIdentification
                    //   })
                    // }
                    onClick={togglePatientProfileDrawer({
                      value: true,
                      patient: item,
                    })}
                  >
                    <Tooltip title="View Profile Drawer">
                      <Badge>
                        <Conversation color="black" size="20" />
                      </Badge>
                    </Tooltip>
                  </IconButton>
                  <Button
                    variant="contained"
                    sx={{
                      visibility:
                        isRowHovered === item?._id ? "visible" : "hidden",
                    }}
                    onClick={() => {
                      redirectToEditPage(item?._id);
                    }}
                  >
                    View
                  </Button>
                </Box>
              ) : (
                <IconButton
                  sx={{
                    padding: "0px",
                    marginRight: "5px",
                    marginTop: "5px",
                  }}
                  // onClick={() =>
                  //   setLinkQueueModalOpen({
                  //     value: true,
                  //     data: item?._id,
                  //     results: item?.metadata?.patientData,
                  //     taskCategory: item?.taskCategory,
                  //     callerIdentification: item?.metadata?.callerIdentification
                  //   })
                  // }
                  onClick={toggleUnknownCallerDrawer({
                    value: true,
                    data: item?._id,
                    results: item?.metadata?.patientData,
                    taskCategory: item?.taskCategory,
                    callerIdentification: item?.metadata?.callerIdentification,
                    calldata: item,
                  })}
                >
                  <Tooltip title="Link With Patient">
                    <Badge>
                      <Visibility sx={{ fontSize: 26, marginBottom: "5px" }} />
                    </Badge>
                  </Tooltip>
                </IconButton>
              )}
            </Box>
          ),
        }))
      : items.map((item, index) => ({
          patientInfo: (
            <Box>
              {item?.patient ? (
                <PatientInfo
                  id={item?.patient?._id}
                  patient={item?.patient}
                  surgeryDate={item.patient.surgeryDate}
                  firstName={item.patient.firstName}
                  patientId={item.patient._id}
                  path={item.patient.path}
                  queueStatus={item.status}
                  onRefetch={handleRefetch}
                  FormData={item}
                  queueID={item._id}
                  acuity={item.acuity}
                  userFeedback={item.userFeedback}
                  originalMessage={item.originalMessage}
                  chatbotReply={item.chatbotReply}
                  reasoning={item.reasoning}
                  createdAt={item.createdAt}
                  value={value}
                  taskCategory={item.taskCategory}
                  refetchCount={refetchCount}
                  formId={item?.metadata?.assignFormId}
                  selectedPatientStatus={
                    item?.items ? selectedPatientStatus : item?.status
                  }
                  refetchQueueAgain={refetchQueueAgain}
                />
              ) : (
                <>
                  <Chip
                    label={item?.metadata?.patientData?.name || "N/A"}
                    size="small"
                    sx={{
                      backgroundColor: "#B71C1C",
                      color: "white",
                      fontWeight: 600,
                    }}
                    onClick={toggleUnknownCallerDrawer({
                      value: true,
                      data: item?._id,
                      results: item?.metadata?.patientData,
                      taskCategory: item?.taskCategory,
                      callerIdentification:
                        item?.metadata?.callerIdentification,
                      calldata: item,
                    })}
                  />
                  <Typography
                    sx={{ color: "#979797", fontSize: 14, mt: "4px" }}
                  >
                    <span style={{ color: "#979797", fontWeight: 500 }}>
                      Ph:
                    </span>{" "}
                    {item?.metadata?.patientData?.phone_number || "N/A"}
                  </Typography>
                </>
              )}
            </Box>
          ),
          dialogueIntent: (
            <>
              {" "}
              <Typography
                variant="p"
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  textAlign: "left",
                }}
              >
                {item?.dialogueIntent ? item?.dialogueIntent : "No Intent"}
              </Typography>
            </>
          ),
          surgeonName: (
            <>
              {selectedPatientStatus === "resolved" || !item?.items ? (
                <Typography
                  variant="p"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  {item?.surgeonName ? item?.surgeonName : "N/A"}
                </Typography>
              ) : (
                <Typography
                  variant="p"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  {item?.items?.[0]?.surgeonName
                    ? item?.items[0]?.surgeonName
                    : "N/A"}
                </Typography>
              )}
            </>
          ),
          surgeryType: (
            <>
              {selectedPatientStatus === "resolved" || !item?.items ? (
                <Typography
                  variant="p"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  {item?.surgeryType
                    ? item?.surgeryType
                        ?.replace(/_/g, " ")
                        .split(/\s+/)
                        .map(
                          (word) =>
                            word.charAt(0).toUpperCase() + word.slice(1),
                        )
                        .join(" ")
                    : "N/A"}
                </Typography>
              ) : (
                <Typography
                  variant="p"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  {item?.items?.[0]?.surgeryType
                    ? item?.items?.[0]?.surgeryType
                        ?.replace(/_/g, " ")
                        .split(/\s+/)
                        .map(
                          (word) =>
                            word.charAt(0).toUpperCase() + word.slice(1),
                        )
                        .join(" ")
                    : "N/A"}
                </Typography>
              )}
            </>
          ),
          surgeryDate: (
            <>
              <Typography
                variant="p"
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  textAlign: "left",
                }}
              >
                {item?.items?.[0]?.surgeryDate
                  ? moment(item?.surgeryDate).format("MM-DD-YYYY")
                  : "Procedure Date Unavailable"}
              </Typography>
            </>
          ),
          collapsibleIcon: (
            <>
              {/* <IconButton aria-label="expand row" size="small" onClick={() => handleRowToggle(index)}>
						{rows[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton> */}
              {console.log(item?._id, selectedPatientIds?.includes(item?._id))}
              {selectedPatientStatus === "resolved" || !item?.items ? (
                <Checkbox
                  size="small"
                  checked={selectedPatientIds?.includes(item?._id)}
                  onChange={() => {
                    setSelectedPatientIds((prevState) =>
                      prevState.includes(item._id)
                        ? prevState.filter((id) => id !== item._id)
                        : [...prevState, item._id],
                    );
                  }}
                />
              ) : (
                <Checkbox
                  size="small"
                  checked={item?.items
                    ?.map((ids) => ids?._id)
                    ?.every((id) => selectedPatientIds?.includes(id))}
                  onChange={() => {
                    setSelectedPatientIds((prevState) => {
                      const newIds = item.items?.map((ids) => ids?._id) || [];

                      const allIdsIncluded = newIds.every((id) =>
                        prevState.includes(id),
                      );

                      return allIdsIncluded
                        ? prevState.filter((id) => !newIds.includes(id))
                        : [...prevState, ...newIds];
                    });
                  }}
                />
              )}
            </>
          ),
          collapsibleBody: (
            <>
              <Collapse
                in={rows[index]}
                timeout="auto"
                unmountOnExit
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {value === 4 ? (
                  <Paper
                    elevation={3}
                    sx={{
                      padding: "1.5rem",
                      // height: '95%',
                      my: "1em",
                      borderRadius: "15px",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <DialogueAccordion
                          title={"QnA"}
                          chatData={item?.dialogueChat}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h6" fontSize={"16px"}>
                          Dialogue Summary
                        </Typography>
                        <Typography>
                          <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                              a: (props) => (
                                <a
                                  href={props.href}
                                  style={{ color: "#000" }}
                                  target="blank"
                                >
                                  {props.children}
                                </a>
                              ),
                            }}
                          >
                            {item?.dialogueSummary
                              ? item?.dialogueSummary
                                  ?.replace(/^```|```$/g, "")
                                  ?.replace(/\n/g, "  \n")
                              : "No Summary Available"}
                          </ReactMarkdown>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                ) : (
                  <Paper
                    elevation={3}
                    sx={{
                      padding: "1.5rem",
                      height: "95%",
                      my: "1em",
                      borderRadius: "15px",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Care Program</strong>
                        </Typography>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              position: "relative",
                              borderRadius: "10px",
                              overflow: "hidden",
                              marginRight: "5px",
                            }}
                          >
                            <Chip
                              label={
                                item?.patient?.program?.length > 0
                                  ? item?.patient?.program[0]
                                      .replace(/_/g, " ")
                                      .split(/\s+/)
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1),
                                      )
                                      .join(" ")
                                  : "No Care Program Assigned"
                              }
                              size="medium"
                              sx={{
                                marginTop: "-0.2em",
                                color: "black",
                                backgroundColor:
                                  item?.patient?.program?.length > 0
                                    ? "#e5feec"
                                    : "rgba(255, 31, 0, 0.08)",
                                borderRadius: "8px",
                                position: "relative",
                              }}
                            />
                            <LinearProgress
                              variant="determinate"
                              value={
                                item?.patient?.program?.length > 0
                                  ? 100 / item?.patient?.program?.length
                                  : 0
                              }
                              sx={{
                                position: "absolute",
                                bottom: "4px",
                                left: "2px",
                                width: "calc(100% - 4px)",
                                borderRadius: "10px",
                                height: "3.5px",
                                zIndex: 0,
                                backgroundColor:
                                  item?.patient?.program?.length > 0
                                    ? "#8fdaa5"
                                    : "#D3602F",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: "#208b40", // Desired color
                                },
                              }}
                            />
                          </div>
                          <Tooltip
                            title={
                              item?.patient?.program?.length > 1
                                ? item?.patient?.program
                                    .slice(1) // Exclude the first index
                                    .map((word) =>
                                      word
                                        .replace(/_/g, " ")
                                        .split(/\s+/)
                                        .map(
                                          (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1),
                                        )
                                        .join(" "),
                                    )
                                    .join(", ")
                                : "No additional data"
                            }
                          >
                            <Chip
                              label={
                                "+" +
                                (item?.patient?.program?.length > 0
                                  ? item?.patient?.program?.length - 1
                                  : 0)
                              }
                              sx={{ borderRadius: "10px" }}
                            />
                          </Tooltip>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Message Grading</strong>
                        </Typography>
                        <div>
                          {renderFeedbackIcons(
                            item?._id,
                            item?.userFeedback,
                            item?.originalMessage,
                            item,
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Patient Summary</strong>
                        </Typography>
                        <Typography>
                          {item?.patient?.patientSummary
                            ? item?.patient?.patientSummary
                            : "N/A"}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography>
                          <strong>Patient Message</strong>
                        </Typography>
                        <Alert
                          severity="info"
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                              a: (props) => (
                                <a
                                  href={props.href}
                                  style={{ color: "#000" }}
                                  target="blank"
                                >
                                  {props.children}
                                </a>
                              ),
                            }}
                          >
                            {item?.originalMessage}
                          </ReactMarkdown>
                        </Alert>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Chatbot Reply</strong>
                        </Typography>
                        <Alert
                          severity="success"
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                              a: (props) => (
                                <a
                                  href={props.href}
                                  style={{ color: "#000" }}
                                  target="blank"
                                >
                                  {props.children}
                                </a>
                              ),
                            }}
                          >
                            {item.chatbotReply}
                          </ReactMarkdown>
                        </Alert>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Prediction Reasoning</strong>
                        </Typography>
                        {item?.reasoning?.map((value, i) => (
                          <Grid item xs={12} key={i}>
                            <Alert severity="warning">{value}</Alert>
                            <br />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Paper>
                )}
              </Collapse>
            </>
          ),
          createdAt: (
            <>
              {selectedPatientStatus === "resolved" || !item?.items ? (
                <>
                  <Typography
                    fontSize={14}
                    color="#979797"
                    sx={{ minWidth: "100px", textAlign: "center" }}
                  >
                    {moment(item?.createdAt).format("MM-DD-YYYY")}
                    <br />
                    {moment(item?.createdAt).format("hh:mm A")}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    fontSize={14}
                    color="#979797"
                    sx={{ minWidth: "100px", textAlign: "center" }}
                  >
                    {moment(item?.items?.[0]?.createdAt).format("MM-DD-YYYY")}
                    <br />
                    {moment(item?.items?.[0]?.createdAt).format("hh:mm A")}
                  </Typography>
                </>
              )}
            </>
          ),
          clinic: (
            <>
              {selectedPatientStatus === "resolved" || !item?.items ? (
                <>
                  <Typography
                    fontSize={14}
                    color="#979797"
                    sx={{ minWidth: "100px", textAlign: "center" }}
                  >
                    {item?.clinic || "N/A"}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    fontSize={14}
                    color="#979797"
                    sx={{ minWidth: "100px", textAlign: "center" }}
                  >
                    {item?.items?.[0]?.clinic || "N/A"}
                  </Typography>
                </>
              )}
            </>
          ),
          socialNeeds: (
            <div
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "4px",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                {(item?.patient?.socialNeeds || item?.patient?.socialRisks) &&
                (item?.patient?.socialNeeds?.length > 0 ||
                  item?.patient?.socialRisks?.length > 0) ? (
                  [
                    ...(item?.patient?.socialRisks || []),
                    ...(item?.patient?.socialNeeds || []),
                  ].map((icon, index) => {
                    const isSocialNeed =
                      item?.patient?.socialNeeds?.includes(icon);
                    const color = isSocialNeed ? "#ffb400" : "#DC362E";
                    const IconElement = iconMap[icon] || <BlockIcon />;
                    return (
                      <Tooltip
                        key={index}
                        title={icon.charAt(0).toUpperCase() + icon.slice(1)}
                      >
                        <div
                          style={{
                            display: "flex", // Flexbox layout
                            alignItems: "center", // Vertical centering
                            justifyContent: "center", // Horizontal centering
                            cursor: "pointer",
                          }}
                        >
                          {React.cloneElement(IconElement, {
                            sx: { color, fontSize: 20 },
                          })}
                        </div>
                      </Tooltip>
                    );
                  })
                ) : (
                  <div
                    style={{
                      gridColumn: "1 / span 3", // Span across all columns
                      textAlign: "center", // Center the text within the grid
                    }}
                  >
                    N/A
                  </div>
                )}
              </div>
            </div>
          ),
          taskCategory: (
            <>
              {selectedPatientStatus === "resolved" || !item?.items ? (
                <>
                  <Typography fontSize={14} color="black">
                    {item.taskCategory
                      ? item.taskCategory?.map((item) => item)?.join(", ")
                      : "N/A"}
                  </Typography>
                  {tabsValue[value] !== "new_media" &&
                    tabsValue[value] !== "form_submission" && (
                      <Typography
                        fontSize={14}
                        color="#979797"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "100px",
                        }}
                      >
                        {item.originalMessage
                          ? item.originalMessage
                          : "No Message Available!"}
                      </Typography>
                    )}
                </>
              ) : (
                <>
                  <Typography
                    fontSize={14}
                    color="black"
                    sx={{ width: "120px" }}
                  >
                    {item?.uniqueTaskCategories?.length > 0
                      ? item?.uniqueTaskCategories
                          ?.map((item) => item)
                          ?.join(", ")
                      : "N/A"}
                  </Typography>
                  {tabsValue[value] !== "new_media" &&
                    tabsValue[value] !== "form_submission" && (
                      <Typography
                        fontSize={14}
                        color="#979797"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "120px",
                        }}
                      >
                        {item.items?.[0]?.originalMessage
                          ? item.items?.[0]?.originalMessage
                          : "No Message Available!"}
                      </Typography>
                    )}
                </>
              )}
            </>
          ),
          care_program: (
            <div style={{ display: "flex" }}>
              <div
                style={{
                  position: "relative",
                  borderRadius: "10px",
                  overflow: "hidden",
                  marginRight: "5px",
                }}
              >
                <Chip
                  label={
                    item?.patient?.program?.length > 0
                      ? item?.patient?.program[0]
                          .replace(/_/g, " ")
                          .split(/\s+/)
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1),
                          )
                          .join(" ")
                      : "No Care Program Assigned"
                  }
                  size="medium"
                  sx={{
                    marginTop: "-0.2em",
                    color: "black",
                    backgroundColor:
                      item?.patient?.program?.length > 0
                        ? "#e5feec"
                        : "rgba(255, 31, 0, 0.08)",
                    borderRadius: "8px",
                    position: "relative",
                  }}
                />
                <LinearProgress
                  variant="determinate"
                  value={
                    item?.patient?.program?.length > 0
                      ? 100 / item?.patient?.program?.length
                      : 0
                  }
                  sx={{
                    position: "absolute",
                    bottom: "4px",
                    left: "2px",
                    width: "calc(100% - 4px)",
                    borderRadius: "10px",
                    height: "3.5px",
                    zIndex: 0,
                    backgroundColor:
                      item?.patient?.program?.length > 0
                        ? "#8fdaa5"
                        : "#D3602F",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#208b40", // Desired color
                    },
                  }}
                />
              </div>
              <Tooltip
                title={
                  item?.patient?.program?.length > 1
                    ? item?.patient?.program
                        .slice(1) // Exclude the first index
                        .map((word) =>
                          word
                            .replace(/_/g, " ")
                            .split(/\s+/)
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1),
                            )
                            .join(" "),
                        )
                        .join(", ")
                    : "No additional data"
                }
              >
                <Chip
                  label={
                    "+" +
                    (item?.patient?.program?.length > 0
                      ? item?.patient?.program?.length - 1
                      : 0)
                  }
                  sx={{ borderRadius: "10px" }}
                />
              </Tooltip>
            </div>
          ),
          acuity: (
            <>
              {selectedPatientStatus === "resolved" || !item?.items ? (
                <Typography
                  fontSize={14}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {item.acuity && (
                    <span
                      style={{
                        ...getColorStyles(item.acuity),
                        fontSize: "36px",
                      }}
                    >
                      •
                    </span>
                  )}
                  {item.acuity
                    ? item.acuity.charAt(0).toUpperCase() + item.acuity.slice(1)
                    : "N/A"}
                </Typography>
              ) : (
                <Typography
                  fontSize={14}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {item.highestAcuity && (
                    <span
                      style={{
                        ...getColorStyles(item.highestAcuity),
                        fontSize: "36px",
                      }}
                    >
                      •
                    </span>
                  )}
                  {item.highestAcuity
                    ? item.highestAcuity.charAt(0).toUpperCase() +
                      item.highestAcuity.slice(1)
                    : "N/A"}
                </Typography>
              )}
            </>
          ),
          assignee: (
            <Box>
              <CustomMultiSelect
                queueID={item?.items?.map((ids) => ids?._id)}
                queueStatus={item?.status}
                assignee={
                  selectedPatientStatus === "resolved"
                    ? item?.assignees || []
                    : item?.uniqueAssignees || []
                }
                refetchQueueAgain={refetchQueueAgain}
              />
            </Box>
          ),
          ChatbotMode: (
            <Typography fontSize={14} color="black">
              {item?.patient?.chatMode === "automatic"
                ? "Autopilot"
                : item?.patient?.chatMode === "manual"
                  ? "Manual"
                  : "Care Guard"}
            </Typography>
          ),
          action: (
            <Box>
              {item?.patient ? (
                <LastCell
                  id={item._id}
                  firstName={item?.patient?.firstName}
                  patientId={item?.patient?._id}
                  path={item?.patient?.path}
                  queueStatus={item?.status}
                  onRefetch={handleRefetch}
                  patient={item?.patient}
                  FormData={item}
                  acuity={item?.acuity}
                  userFeedback={item?.userFeedback}
                  originalMessage={item?.originalMessage}
                  chatbotReply={item?.chatbotReply}
                  reasoning={item?.reasoning}
                  createdAt={item?.createdAt}
                  value={value}
                  taskCategory={item?.taskCategory}
                  refetchCount={refetchCount}
                  formId={item?.metadata?.assignFormId}
                  isRowHovered={isRowHovered}
                  selectedPatientStatus={
                    item?.items ? selectedPatientStatus : item?.status
                  }
                  refetchQueueAgain={refetchQueueAgain}
                />
              ) : (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    sx={{
                      padding: "0px",
                      marginRight: "5px",
                      marginTop: "5px",
                    }}
                    // onClick={() =>
                    //   setLinkQueueModalOpen({
                    //     value: true,
                    //     data: item?._id,
                    //     results: item?.metadata?.patientData,
                    //     taskCategory: item?.taskCategory,
                    //     callerIdentification: item?.metadata?.callerIdentification
                    //   })
                    // }
                    onClick={toggleUnknownCallerDrawer({
                      value: true,
                      data: item?._id,
                      results: item?.metadata?.patientData,
                      taskCategory: item?.taskCategory,
                      callerIdentification:
                        item?.metadata?.callerIdentification,
                      calldata: item,
                    })}
                  >
                    <Tooltip title="Link With Patient">
                      <Badge>
                        <Conversation color={"black"} size={20} />
                      </Badge>
                    </Tooltip>
                  </IconButton>

                  <Box
                    sx={{
                      "&:hover .hiddenButton": {
                        visibility: "visible",
                      },
                    }}
                  >
                    <Button
                      className="hiddenButton"
                      variant="contained"
                      size="small"
                      sx={{
                        visibility:
                          isRowHovered === item._id ? "visible" : "hidden",
                        padding: "0px",
                        cursor: "pointer",
                      }}
                      onClick={() => updateStatus(item._id, item?.status)}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          color: "white",
                          textWrap: "nowrap",
                          padding: "5px	",
                        }}
                      >
                        {item?.status === "resolved"
                          ? "Mark as Pending"
                          : "Mark as Resolved"}
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          ),
          queueType: (
            <>
              {selectedPatientStatus === "resolved" || !item?.items ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {typeData
                    .filter((type) => type.key === item?.type)
                    .map((matchedType) => {
                      const IconComponent = iconMapping[matchedType.icon];
                      return (
                        <React.Fragment key={matchedType.key}>
                          {IconComponent && (
                            <IconComponent
                              sx={{ marginRight: "5px" }}
                              fontSize="small"
                            />
                          )}
                          <Typography
                            variant="subtitle3"
                            sx={{ fontWeight: "bold" }}
                          >
                            {matchedType.label || item?.type}
                          </Typography>
                        </React.Fragment>
                      );
                    })}
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {typeData
                    .filter((type) => type.key === item?._id?.type)
                    .map((matchedType) => {
                      return (
                        <React.Fragment key={matchedType.key}>
                          <Badge
                            badgeContent={item.totalItems}
                            color="primary"
                            sx={{ mr: item.totalItems > 99 ? 3 : 2 }}
                          />
                          <Typography
                            variant="subtitle3"
                            sx={{
                              fontWeight: "bold",
                              textAlign: "center",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {matchedType.label || item?._id?.type}
                          </Typography>
                        </React.Fragment>
                      );
                    })}
                </Box>
              )}
            </>
          ),
        }));
  };
  const [openFilter, setOpenFilter] = useState(false);

  const handleOpenFilter = (e) => {
    setOpenFilter(true);
  };

  const handleSelectAll = () => {
    if (selectedPatientStatus === "resolved") {
      if (selectedPatientIds.length === patientQueues.length) {
        // Deselect all if all are already selected
        setSelectedPatientIds([]);
      } else {
        // Select all patient IDs
        setSelectedPatientIds(patientQueues.map((patient) => patient._id));
      }
    } else if (
      selectedPatientIds.length ===
      patientQueues.flatMap((patient) =>
        patient?.items
          ? patient?.items?.map((ids) => ids?._id) || []
          : patient?._id,
      ).length
    ) {
      // Deselect all if all are already selected
      setSelectedPatientIds([]);
    } else {
      // Select all patient IDs
      setSelectedPatientIds(
        patientQueues.flatMap((patient) =>
          patient?.items
            ? patient?.items?.map((ids) => ids?._id) || []
            : patient?._id,
        ),
      );
    }
  };

  const updateStatus = async (itemId, currentStatus) => {
    // handleClose();
    const newStatus = currentStatus === "resolved" ? "pending" : "resolved";
    const ids = [itemId];

    try {
      await axios
        .put(
          `${process.env.REACT_APP_BASE_API_URL}/patientQueues/updateStatuses`,
          { status: newStatus, ids },
          config,
        )
        .then((res) => {
          const status = res.data.status;
          toast.success("The issue is " + status);
          refetchQueueAgain();
          refetchCount();
        });
    } catch (error) {
      toast.error("Error:", error);
    }
  };

  // useEffect(() => {
  // 	if (data) {
  // 		const interval = setInterval(() => {
  // 			refetch()
  // 		}, 5000)

  // 		return () => clearInterval(interval)
  // 	}
  // }, [data])

  useEffect(() => {
    if (queueData && isV2Profile) {
      setTotalPages(queueData?.totalPages);
      setPatientQueues(queueData?.data);
    } else if (queueData) {
      if (queueData?.patientQueues !== prevDataRef?.current?.length) {
        prevDataRef.current = queueData?.patientQueues;

        setTotalPages(queueData?.totalPages);
        setPatientQueues(queueData?.patientQueues);
      }
    }
  }, [queueData]);

  useEffect(() => {
    if (selectedValue) {
      setPage(1);
      const filteredDate = filterByDate(selectedValue);
      localStorage.setItem("createdAfter", filteredDate);
      setCreatedAfter(filteredDate);
    }
  }, [selectedValue]);

  useEffect(() => {
    if (page > queueData?.totalPages) {
      localStorage.setItem("page", 1);
      setPage(1);
    }
  }, [queueData]);

  useEffect(() => {
    if (procedureTypeData?.data) {
      const surgeryType = {
        value: procedureTypeData?.data?.map((item) => ({
          id: item._id,
          title: item.name,
          value: toSnakeCase(item.name),
        })),
      };

      const newPatientQueueFilterData = {
        ...patientQueueFilterData,
        surgeryType,
      };

      setFilterData(newPatientQueueFilterData);
    } else {
      setFilterData(patientQueueFilterData);
    }
  }, [procedureTypeData]);

  useEffect(() => {
    if (setSelectedPatientIds) {
      setSelectedPatientIds([]);
    }
  }, [selectedPatientStatus, location]);

  useEffect(() => {
    console.log(queryParams.get("tab"), queryParams.get("patientId"));
    if (
      queryParams.get("tab") &&
      queryParams.get("patientId") &&
      !hasRunOnce &&
      patientQueues?.find((item) => item?._id == queryParams.get("patientId"))
    ) {
      console.log(
        patientQueues?.find(
          (item) => item?._id == queryParams.get("patientId"),
        ),
      );
      setChatMessages([]);
      setOpenPatientProfileDrawer({
        value: true,
        patient: patientQueues?.find(
          (item) => item?._id == queryParams.get("patientId"),
        ),
      });
      setHasRunOnce(true);
    }
  }, [patientQueues]);

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <Paper
          sx={{
            border: "1px ",
            width: "100%",
            borderRadius: "10px",
          }}
        >
          <TableContainer
            sx={{ borderRadius: "4px" }}
            className="DialogContent"
          >
            <Table>
              <TableHead style={{ backgroundColor: "rgba(0, 151, 236, 0.12)" }}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      variant="head"
                      key={column.id}
                      align="left"
                      sx={{
                        fontWeight: 600,
                        fontSize: "14px",
                        color: "#50607A",
                        padding: "8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {column.label}
                        {column.label === "" && (
                          <Checkbox
                            size="small"
                            checked={
                              patientQueues?.length !== 0 &&
                              (selectedPatientStatus === "resolved"
                                ? selectedPatientIds?.length ===
                                  patientQueues?.length
                                : selectedPatientIds?.length ===
                                  patientQueues?.flatMap((patient) =>
                                    patient?.items
                                      ? patient?.items?.map(
                                          (ids) => ids?._id,
                                        ) || []
                                      : patient?._id,
                                  ).length)
                            }
                            onChange={handleSelectAll}
                          />
                        )}
                        {column.label === "Message Time" && (
                          <IconButton onClick={() => handleSorting(column?.id)}>
                            {sortCreatedAtOrder.field === column?.id &&
                            sortCreatedAtOrder.sort === "asc" ? (
                              <ArrowUpwardIcon
                                sx={{ height: "15px", color: "#536878" }}
                              />
                            ) : (
                              <SouthIcon
                                sx={{ height: "15px", color: "#536878" }}
                              />
                            )}
                          </IconButton>
                        )}
                        {column.label === "Patient Information" && (
                          <IconButton onClick={() => handleSorting(column.id)}>
                            {sortPatientOrder?.field === "patientName" &&
                            column?.id === "patientInfo" &&
                            sortPatientOrder.sort === "asc" ? (
                              <ArrowUpwardIcon
                                sx={{ height: "15px", color: "#536878" }}
                              />
                            ) : (
                              <SouthIcon
                                sx={{ height: "15px", color: "#536878" }}
                              />
                            )}
                          </IconButton>
                        )}

                        {column.label === "Task Category" && (
                          <IconButton onClick={() => handleSorting(column?.id)}>
                            {sortTaskCategoryOrder.field === column?.id &&
                            sortTaskCategoryOrder.sort === "asc" ? (
                              <ArrowUpwardIcon
                                sx={{ height: "15px", color: "#536878" }}
                              />
                            ) : (
                              <SouthIcon
                                sx={{ height: "15px", color: "#536878" }}
                              />
                            )}
                          </IconButton>
                        )}

                        {column.label === "Contact Time" && (
                          <IconButton
                            onClick={() =>
                              handleSorting(
                                column?.id,
                                sortContactTimeOrder.field === "contactTime" &&
                                  column.id === "createdAt",
                              )
                            }
                          >
                            {sortContactTimeOrder.field === "contactTime" &&
                            column?.id === "createdAt" &&
                            sortContactTimeOrder.sort === "asc" ? (
                              <ArrowUpwardIcon
                                sx={{ height: "15px", color: "#536878" }}
                              />
                            ) : (
                              <SouthIcon
                                sx={{ height: "15px", color: "#536878" }}
                              />
                            )}
                          </IconButton>
                        )}

                        {column.label === "Physician Name" && (
                          <IconButton onClick={() => handleSorting(column?.id)}>
                            {sortPhysicianNameOrder.field === column.id &&
                            sortPhysicianNameOrder.sort === "asc" ? (
                              <ArrowUpwardIcon
                                sx={{ height: "15px", color: "#536878" }}
                              />
                            ) : (
                              <SouthIcon
                                sx={{ height: "15px", color: "#536878" }}
                              />
                            )}
                          </IconButton>
                        )}

                        {column.label === "Procedure Type" && (
                          <IconButton onClick={() => handleSorting(column?.id)}>
                            {sortProcedureTypeOrder.field === "procedureType" &&
                            column?.id === "surgeryType" &&
                            sortProcedureTypeOrder.sort === "asc" ? (
                              <ArrowUpwardIcon
                                sx={{ height: "15px", color: "#536878" }}
                              />
                            ) : (
                              <SouthIcon
                                sx={{ height: "15px", color: "#536878" }}
                              />
                            )}
                          </IconButton>
                        )}

                        {column.label === "Surgeon Name" && !isV2Profile && (
                          <IconButton onClick={() => handleSorting(column?.id)}>
                            {sortSurgeonName.field === column?.id &&
                            sortSurgeonName.sort === "asc" ? (
                              <ArrowUpwardIcon
                                sx={{ height: "15px", color: "#536878" }}
                              />
                            ) : (
                              <SouthIcon
                                sx={{ height: "15px", color: "#536878" }}
                              />
                            )}
                          </IconButton>
                        )}

                        {column.label === "Acuity" && (
                          <>
                            {acuityCount !== 0 && (
                              <Tooltip
                                title="High Acuity Queues Count"
                                sx={{ mr: 1, ml: 2.5 }}
                              >
                                <Badge
                                  badgeContent={acuityCount}
                                  color="primary"
                                />
                              </Tooltip>
                            )}
                            <IconButton
                              onClick={() => handleSorting(column?.id)}
                            >
                              {sortAcuityOrder.field === column?.id &&
                              sortAcuityOrder.sort === "asc" ? (
                                <ArrowUpwardIcon
                                  sx={{ height: "15px", color: "#536878" }}
                                />
                              ) : (
                                <SouthIcon
                                  sx={{ height: "15px", color: "#536878" }}
                                />
                              )}
                            </IconButton>
                          </>
                        )}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {createRows(
                  patientQueues,
                  selectedPatientIds,
                  setSelectedPatientIds,
                  isRowHovered,
                  selectedPatientStatus,
                  toggleUnknownCallerDrawer,
                  openUnknownCallerChat,
                  isV2Profile,
                  togglePatientProfileDrawer,
                  openPatientProfileDrawer,
                  redirectToEditPage,
                  updateStatus,
                  refetchQueueAgain,
                )?.map((row, i) => (
                  <>
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={i}
                      sx={{
                        background:
                          row.status === "resolved" || openRows[i]
                            ? "#0000000a"
                            : "",
                      }}
                      onMouseEnter={() => showMarkAsResolved(i)}
                      onMouseLeave={() => setIsRowHovered(null)}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];

                        return (
                          <TableCell
                            key={column.id}
                            align="left"
                            sx={{
                              color: "text.secondary",
                              padding: "8px",
                            }}
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={10}
                      >
                        {row.collapsibleBody}
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <PaginationBar
            {...{ page, count: totalPages, handleChange: handleChangePage }}
          />
        </Paper>
      </div>
      <FiltersDialog
        open={openFilter}
        setOpen={setOpenFilter}
        queriesValue={queriesValue}
        setQueriesValue={setQueriesValue}
        data={filterData}
      />
      <SnackbarMsg
        msg={"Thank you for giving us feedback!"}
        handleClose={handleClose}
        open={open}
        width={"310px"}
      />
      <WhatwentwrongDialog
        open={openDislikeModal}
        setOpen={setOpenDislikeModal}
        addReaction={addReaction}
        queueId={currentItemId}
        originalMessage={selectOriginalMessage}
        isAcuitySelected={isAcuitySelected}
        setIsAcuitySelected={setIsAcuitySelected}
        isChatbotResponse={isChatbotResponse}
        setIsChatbotResponse={setIsChatbotResponse}
        isTaskCategorySelected={isTaskCategorySelected}
        setIsTaskCategorySelected={setIsTaskCategorySelected}
        proposedChatbotResponse={proposedChatbotResponse}
        setProposedChatbotResponse={setProposedChatbotResponse}
        chatbotResponseCritique={chatbotResponseCritique}
        setChatbotResponseCritique={setChatbotResponseCritique}
        selectedAcuity={selectedAcuity}
        setSelectedAcuity={setSelectedAcuity}
        selectedTaskLabel={selectedTaskLabel}
        setSelectedTaskLabel={setSelectedTaskLabel}
        chatbotResponse={chatbotResponse}
      />
      <UnknownCallerMessageDrawer
        toggleDrawer={toggleUnknownCallerDrawer}
        state={openUnknownCallerChat}
        data={toggleUnknownCallerDrawer?.calldata}
        setOpenUnknownCallerChat={setOpenUnknownCallerChat}
        refetchQueueAgain={refetchQueueAgain}
      />
      {openPatientProfileDrawer?.value && (
        <PatientProfileDrawer
          state={openPatientProfileDrawer.value}
          toggleDrawer={togglePatientProfileDrawer}
          name={`${openPatientProfileDrawer?.patient?.firstName} ${openPatientProfileDrawer?.patient?.lastName}`}
          user={user}
          path={openPatientProfileDrawer?.patient?.path}
          patient={openPatientProfileDrawer?.patient}
          acuity={openPatientProfileDrawer?.patient?.acuity}
          value={value}
          id={openPatientProfileDrawer?.patient?._id}
          status={openPatientProfileDrawer?.patient?.status}
          consent={openPatientProfileDrawer?.patient?.consentResponse}
          isJourney={openPatientProfileDrawer?.isJourney}
        />
      )}
      <LinkQueueModal
        open={linkQueueModalOpen?.value}
        queueId={linkQueueModalOpen?.data}
        metadata={linkQueueModalOpen?.results}
        callresults={linkQueueModalOpen?.callresults}
        taskCategory={linkQueueModalOpen?.taskCategory}
        callerIdentification={linkQueueModalOpen?.callerIdentification}
        setOpenUnknownCallerChat={setOpenUnknownCallerChat}
        onClose={() =>
          setLinkQueueModalOpen({
            value: false,
            data: null,
            results: null,
            taskCategory: null,
            callerIdentification: null,
          })
        }
        refetchQueueAgain={refetchQueueAgain}
      />
    </>
  );
}
