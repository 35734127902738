import { Close as CloseIcon } from "@mui/icons-material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Alert,
  Badge,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Call } from "../../assets/svgs/PatientProfileTabsSvgs/Call";
import { Conversation } from "../../assets/svgs/PatientProfileTabsSvgs/Conversation";
import { Gallery } from "../../assets/svgs/PatientProfileTabsSvgs/Gallery";
import { AssistantSvg } from "../../assets/svgs/Queue Icons";
import { useChatValues } from "../../contexts/chatValue";
import { useIsAuthenticatedUser } from "../../contexts/isAuthenticatedUser";
import { useGetChatQuery, useMessageSeenMutation } from "../../store/chatSlice";
import { useGetPatientsSurveyResultByIDQuery } from "../../store/patientsSurveySlice";
import { addInParams, getTokenFromLocalStroage } from "../../utils";
import { DetailPageDrawer } from "../Patient/Profile/DetailPageDrawer";
import { QueueDetailDrawerV2 } from "../../Components/QueueDetailDrawerV2/PatientProfileDrawer";
import OutgoingCallComponent from "../../Components/OutgoingCall";
const iconsData = [
  { title: "Call Patient", Icon: Call, value: "call" },
  { title: "Upload Image", Icon: Gallery, value: "gallery" },
  {
    title: "View Assistant",
    Icon: AssistantSvg,
    marginRight: "10px",
    value: "assistant",
  },
];

const iconsDataForNewDesign = [
  { title: "View Call", Icon: Call, value: "call" },
];

const IconButtonCustom = ({
  key,
  title,
  Icon,
  marginRight,
  onClick,
  value,
  selectedPatientStatus,
  FormData,
  openCallUnregisterPatientModal,
  patient,
}) => {
  return (
    <IconButton
      key={key}
      sx={{ padding: "0px", marginRight: marginRight || "5px" }}
      onClick={(event) => {
        event.stopPropagation(); // Ensure event propagation is stopped properly
        if (value === "call") {
          openCallUnregisterPatientModal(
            `${patient?.firstName} ${patient?.lastName}`,
            patient?.phone,
            patient?._id,
          );
        } else {
          onClick({
            clickedValue: value,
            open: true,
            patientId:
              selectedPatientStatus === "resolved"
                ? FormData?.patient?._id
                : FormData?._id?.patientId,
            queueId:
              selectedPatientStatus === "resolved"
                ? FormData?._id
                : FormData?.items?.map((item) => item?._id)?.join(","),
          });
        }
      }}
    >
      <Tooltip title={title}>
        <Box
          sx={{
            border: "1px solid rgb(0, 0, 0, 0.12)", // Add an 8px border
            borderRadius: "8px", // Optional: Make the border circular if needed
            padding: "4px", // Adjust padding to maintain proper spacing
            display: "inline-flex", // Center the content inside the border
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon color={"black"} size={"20"} />
        </Box>
      </Tooltip>
    </IconButton>
  );
};

export function LastCell({
  id,
  firstName,
  path,
  queueStatus,
  patientId,
  onRefetch,
  patient,
  acuity,
  userFeedback,
  originalMessage,
  chatbotReply,
  reasoning,
  createdAt,
  taskCategory,
  value,
  FormData,
  refetchCount,
  formId,
  isRowHovered,
  selectedPatientStatus,
  refetchQueueAgain,
  isNewDesign,
  setDrawerOpen,
}) {
  const location = useLocation();

  // Create a URLSearchParams object from the location search string
  const queryParams = new URLSearchParams(location.search);
  const [clickedValue, setClickedValue] = useState(null);
  const textColor = { color: "#979797" };
  const [currentReasoning, setCurrentReasoning] = useState("");
  const [currentChatbotReply, setCurrentChatbotReply] = useState("");
  const [messageCount, setMessageCount] = useState(0);
  const [itemStatus, setItemStatus] = useState(queueStatus);
  const [chatData, setChatData] = useState([]);
  const [currentPatientMessage, setCurrentPatientMessage] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = useState(false);
  const { setChatMessages } = useChatValues();
  const navigate = useNavigate();
  const config = {
    headers: { Authorization: `Bearer ${getTokenFromLocalStroage()}` },
  };

  const { data: surveyData } = useGetPatientsSurveyResultByIDQuery(formId);

  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    if (event === undefined) {
      return;
    }
  };
  const [page, setPage] = useState(1);
  const { data, refetch, isLoading } = useGetChatQuery({
    id: patientId,
    sid: "2",
    page,
  });
  const [messageSeen] = useMessageSeenMutation();

  const { user } = useIsAuthenticatedUser();

  const [openChat, setOpenChat] = React.useState(false);
  const [openUnknownCallerChat, setOpenUnknownCallerChat] =
    React.useState(false);

  const redirectToEditPage = (id) => {
    navigate(`/profile/${id}`, { state: { action: "read-only", id } });
  };

  const [openCallUnregisterPatient, setOpenCallUnregisterPatient] =
    useState(false);

  const handleCloseCallUnregister = () => {
    setOpenCallUnregisterPatient(false);
  };

  const openCallUnregisterPatientModal = (name, phone, patientId) => {
    if (phone) {
      setOpenCallUnregisterPatient({
        value: true,
        name: name,
        phone: phone,
        patientId: patientId,
      });
    } else {
      toast.error("Phone number is not valid!");
    }
  };

  useEffect(() => {
    if (data) {
      setChatData(data?.data);

      const trueStatus = data?.data?.filter(
        (obj) => obj.status === true && obj?.sentBy === "user",
      );
      const count = trueStatus?.length;
      setMessageCount(count);
    }
  });

  const updateStatus = async (itemId, currentStatus, allIds) => {
    handleClose();
    const newStatus = currentStatus === "resolved" ? "pending" : "resolved";
    const ids = currentStatus === "resolved" ? [itemId] : allIds;

    try {
      await axios
        .put(
          `${process.env.REACT_APP_BASE_API_URL}/patientQueues/updateStatuses`,
          { status: newStatus, ids },
          config,
        )
        .then((res) => {
          const status = res.data.status;
          toast.success("The issue is " + status);
          setItemStatus(status);
          onRefetch();
          refetchCount();
        });
    } catch (error) {
      toast.error("Error:", error);
    }
  };

  const showReasoningModal = async (itemId) => {
    handleClose();
    try {
      await axios
        .get(
          `${process.env.REACT_APP_BASE_API_URL}/patientQueues/fetchReasoning/${itemId}`,
          config,
        )
        .then((res) => {
          setCurrentReasoning(res.data.reasoning);
          setCurrentPatientMessage(res.data.originalMessage);
          setCurrentChatbotReply(res.data.chatbotReply);
          setOpenModal(true);
        });
    } catch (error) {
      toast.error("Error:" + error);
    }
    refetch();
  };

  const toggleDrawer = (open) => (event) => {
    event.stopPropagation();
    handleClose();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    addInParams("formType", open?.clickedValue);
    addInParams("patientId", open?.patientId);
    addInParams("queueId", open?.queueId);
    setClickedValue(open?.clickedValue);
    setOpenChat(open?.open);
    setChatMessages([]);

    if (open?.open) {
      messageSeen(patientId).then(() => {
        refetch();
      });
      setPage(1);
    }
  };

  const toggleUnknownCallerDrawer = (open) => (event) => {
    handleClose();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    // addInParams('formType', open?.clickedValue )
    // addInParams('patientId', open?.patientId)
    // addInParams('queueId', open?.queueId)
    // setClickedValue(open?.clickedValue)
    setOpenUnknownCallerChat(open);
    // setChatMessages([]);

    // if (open?.open) {
    //   messageSeen(patientId).then(() => {
    //     refetch();
    //   });
    //   setPage(1);
    // }
  };

  const handleCloseModal = (event) => {
    setOpenModal(false);
  };
  const [detail, setDetail] = useState(false);
  const toggleDetailDrawer = (detail) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDetail(detail);
  };

  useEffect(() => {
    if (
      queryParams?.get("formType") &&
      queryParams?.get("patientId") === patientId &&
      (FormData?.items?.some(
        (item) => item._id === queryParams?.get("queueId")?.split(",")?.[0],
      ) ||
        queryParams?.get("queueId") === id)
    ) {
      setOpenChat(true);
    }
  }, []);

  return (
    <>
      {openModal && (
        <Dialog open={openModal} onClose={handleCloseModal}>
          <DialogTitle className="alignTextCenter" variant="h6">
            Reasoning
          </DialogTitle>
          <DialogContent>
            <Typography>
              <strong>Patient Message</strong>
            </Typography>
            <Alert severity="info">{currentPatientMessage}</Alert>
            <br />
            <Typography>
              <strong>Chatbot Reply</strong>
            </Typography>
            <Alert severity="success">{currentChatbotReply}</Alert>
            <br />
            <Typography>
              <strong>Prediction Reasoning</strong>
            </Typography>
            {currentReasoning.map((value) => (
              <>
                <Alert severity="warning">{value}</Alert>
                <br />
              </>
            ))}
            <DialogContentText>
              <IconButton
                onClick={handleCloseModal}
                style={{ position: "absolute", top: "8px", right: "10px" }}
              >
                <CloseIcon />
              </IconButton>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        {value === 2 ? (
          <IconButton
            sx={{
              "&: hover .css-tksnbz-MuiSvgIcon-root ": {
                visibility: "visible",
              },
              padding: "0px",
              marginRight: "5px",
            }}
            onClick={(e) => toggleDetailDrawer(true)(e)}
          >
            <Tooltip title="View Form">
              <VisibilityOutlinedIcon
                sx={{ fontSize: 26 }}
                // sx={{
                //   visibility: "hidden",
                // }}
              />
            </Tooltip>
          </IconButton>
        ) : (
          <IconButton
            sx={{
              padding: "0px",
              marginRight: "5px",
              marginTop: "5px",
            }}
            onClick={(e) => {
              if (isNewDesign) {
                e.stopPropagation();
                setDrawerOpen({
                  value: true,
                  type:
                    selectedPatientStatus === "resolved" || !FormData?.items
                      ? FormData?.type
                      : FormData?._id?.type || "",
                  data: FormData,
                  selectedPatientStatus,
                  isMessageClick: true,
                });
              }
            }}
            // onClick={toggleDrawer({
            //   clickedValue:
            //     selectedPatientStatus === "resolved"
            //       ? FormData?.type
            //       : FormData?._id?.type,
            //   open: true,
            //   patientId:
            //     selectedPatientStatus === "resolved"
            //       ? FormData?.patient?._id
            //       : FormData?._id?.patientId,
            //   queueId:
            //     selectedPatientStatus === "resolved"
            //       ? FormData?._id
            //       : FormData?.items?.map((item) => item?._id)?.join(","),
            // })}
          >
            <Tooltip title="View Queue Details">
              <Badge>
                <Box
                  sx={{
                    border: "1px solid rgb(0, 0, 0, 0.12)", // Add an 8px border
                    borderRadius: "8px", // Optional: Make the border circular if needed
                    padding: "4px", // Adjust padding to maintain proper spacing
                    display: "inline-flex", // Center the content inside the border
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Conversation color={"black"} size={"20"} />
                </Box>
              </Badge>
            </Tooltip>
          </IconButton>
        )}

        {/* ************ Call, Camera and Assistant Icon Component **************** */}
        {isNewDesign
          ? iconsDataForNewDesign?.map((item, index) => (
              <IconButtonCustom
                key={index}
                title={item.title}
                Icon={item.Icon}
                marginRight={item?.marginRight}
                onClick={toggleDrawer}
                value={item.value}
                selectedPatientStatus={selectedPatientStatus}
                FormData={FormData}
                openCallUnregisterPatientModal={openCallUnregisterPatientModal}
                patient={patient}
              />
            ))
          : iconsData?.map((item, index) => (
              <IconButtonCustom
                key={index}
                title={item.title}
                Icon={item.Icon}
                marginRight={item?.marginRight}
                onClick={toggleDrawer}
                value={item.value}
                selectedPatientStatus={selectedPatientStatus}
                FormData={FormData}
                openCallUnregisterPatientModal={openCallUnregisterPatientModal}
                patient={patient}
              />
            ))}

        {/* **************** Resolved / Pending button ******************* */}
        {!isNewDesign && (
          <Box
            sx={{
              "&:hover .hiddenButton": {
                visibility: "visible",
              },
            }}
          >
            <Button
              className="hiddenButton"
              variant="contained"
              size="small"
              sx={{
                visibility: isRowHovered === id ? "visible" : "hidden",
                padding: "0px",
                cursor: "pointer",
              }}
              onClick={() =>
                updateStatus(
                  id,
                  queueStatus,
                  FormData?.items?.map((ids) => ids?._id),
                )
              }
            >
              <Typography
                variant="caption"
                sx={{ color: "white", textWrap: "nowrap", padding: "5px	" }}
              >
                {queueStatus === "resolved"
                  ? "Mark as Pending"
                  : "Mark as Resolved"}
              </Typography>
            </Button>
          </Box>
        )}
      </div>

      {openChat && (
        <QueueDetailDrawerV2
          state={openChat}
          toggleDrawer={toggleDrawer}
          name={`${patient?.firstName} ${patient?.lastName}`}
          user={user}
          path={path}
          patient={patient}
          acuity={patient?.acuity}
          value={value}
          id={patient?._id}
          status={patient?.status}
          consent={patient?.consentResponse}
          isJourney={false}
          // New Props
          queueStatus={queueStatus}
          queueData={FormData}
          refetchCount={refetchCount}
          setOpenChat={setOpenChat}
          refetchQueueAgain={refetchQueueAgain}
          refetchAgain={onRefetch}
        />
      )}

      {/* <MessageDrawer
        toggleDrawer={toggleDrawer}
        state={openChat}
        id={patientId}
        name={firstName}
        user={user}
        queueStatus={queueStatus}
        path={path}
        acuity={
          selectedPatientStatus === "resolved"
            ? acuity
            : FormData?.highestAcuity
        }
        patient={patient}
        userFeedback={userFeedback}
        originalMessage={originalMessage}
        chatbotReply={chatbotReply}
        reasoning={reasoning}
        createdAt={createdAt}
        taskCategory={taskCategory}
        queueId={id}
        tabValue={value}
        refetchAgain={onRefetch}
        consent={patient?.consentResponse}
        setPage={setPage}
        page={page}
        refetchPatientAgain={onRefetch}
        isV2True={true}
        clickedValue={clickedValue}
        setClickedValue={setClickedValue}
        queueData={FormData}
        selectedPatientStatus={selectedPatientStatus}
        refetchCount={refetchCount}
        setOpenChat={setOpenChat}
        refetchQueueAgain={refetchQueueAgain}
      /> */}
      <DetailPageDrawer
        toggleDrawer={toggleDetailDrawer}
        state={detail}
        pendingurl={
          `${process.env.REACT_APP_FRONTEND_URI}/patientSurveys/` +
          surveyData?.data?.formId?._id +
          "/" +
          surveyData?.data?._id
        }
      />

      {openCallUnregisterPatient?.value && (
        <OutgoingCallComponent
          open={openCallUnregisterPatient?.value}
          name={openCallUnregisterPatient?.name}
          phone={openCallUnregisterPatient?.phone}
          patientId={openCallUnregisterPatient?.patientId}
          handleClose={handleCloseCallUnregister}
        />
      )}
    </>
  );
}
