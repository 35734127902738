import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

const IgnoreModal = ({ open, onClose, onConfirm, title, content }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "340px",
          padding: "24px",
          borderRadius: "16px",
          backgroundColor: "#FFF",
        },
      }}
    >
      <DialogContent
        sx={{
          "&.MuiDialogContent-root": {
            padding: "0px 0px",
          },
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "157%", // 25.12px
            letterSpacing: "0.113px",
          }}
        >
          {content}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          paddingTop: "24px",
        }}
      >
        <Button
          onClick={onConfirm}
          sx={{
            padding: "8px 20px",
            borderRadius: "8px",
            backgroundColor: "#1B4DFF",
            color: "#FFF",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "100%", // 14px
            letterSpacing: "0.14px",
            textAlign: "center",
            "&:hover": {
              background: "#1B4DFF",
            },
          }}
        >
          Ignore
        </Button>
        <Button
          onClick={onClose}
          sx={{
            padding: "8px 12px",
            borderRadius: "8px",
            backgroundColor: "#E9E9E9",
            color: "#000",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "100%", // 14px
            letterSpacing: "0.14px",
            textAlign: "center",
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IgnoreModal;
