import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import generate from "../../../assets/generate.svg";

const EmailModal = ({ open, onClose, onSendEmail, onRegenerate }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px 24px",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            lineHeight: "28px",
            color: "#0C111D",
          }}
        >
          AI Suggested Email
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "8px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "24px",
              color: "#0C111D",
            }}
          >
            Email
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "8px",
              border: "1px solid #B3B3B3",
              backgroundColor: "#F7F7F7",
              padding: "8px 20px",
              cursor: "pointer",
            }}
            onClick={onRegenerate}
          >
            <img
              src={generate}
              style={{ width: "20px", height: "20px", marginRight: "8px" }}
            />
            <Typography
              sx={{
                color: "#1D1D1D",
                fontSize: "14px",
                fontWeight: "400",
                letterSpacing: "0.14px",
              }}
            >
              Regenerate
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            borderRadius: "8px",
            border: "1px solid #E4E7EC",
            backgroundColor: "#FFF",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            padding: "16px",
            textAlign: "start",
            whiteSpace: "pre-line",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "24px",
              color: "#0C111D",
              marginBottom: "16px", // Space between paragraphs
            }}
          >
            Dear Pean,
          </Typography>

          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "24px",
              color: "#0C111D",
              marginBottom: "16px",
            }}
          >
            I hope this email finds you well. I’m reaching out regarding your
            recent medication refill request for lisinopril.
          </Typography>

          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "24px",
              color: "#0C111D",
              marginBottom: "16px",
            }}
          >
            Before proceeding with the refill, we need to schedule a routine
            blood pressure check. This is important to ensure your medication is
            working effectively.
          </Typography>

          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "24px",
              color: "#0C111D",
              marginBottom: "16px",
            }}
          >
            Would you like to schedule an appointment this week? We have several
            time slots available.
          </Typography>

          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "24px",
              color: "#0C111D",
              marginBottom: "16px",
            }}
          >
            Best Regards,
            <br />
            RevelAi Care Team
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: "flex-end",
          padding: "16px 24px",
        }}
      >
        <Button
          onClick={onClose}
          sx={{
            padding: "8px 12px",
            borderRadius: "8px",
            backgroundColor: "#E9E9E9",
            color: "#000",
            fontFamily: "Source Sans 3",
            fontSize: "14px",
            fontWeight: "400",
            letterSpacing: "0.14px",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={onSendEmail}
          sx={{
            padding: "8px 20px",
            borderRadius: "8px",
            backgroundColor: "#1B4DFF",
            color: "#FFF",
            fontFamily: "Source Sans 3",
            fontSize: "14px",
            fontWeight: "400",
            letterSpacing: "0.14px",
          }}
        >
          Send Email
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailModal;
