import { Avatar, Box, Modal, Typography } from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import { useEffect, useState } from "react";
import { Device } from "@twilio/voice-sdk";
import { CallEndRounded } from "@mui/icons-material";
import { getCurrentSiteId, getTokenFromLocalStroage } from "../utils";
import { toast } from "react-toastify";

const OutgoingCallComponent = ({ open, handleClose, name, phone }) => {
  const [twilioDevice, setTwilioDevice] = useState(null);
  const [twilioCall, setTwilioCall] = useState(null);
  const [isMuted, setIsMuted] = useState(false);

  // Function to fetch the Twilio token from the server
  const getTwilioToken = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/twilio/token`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getTokenFromLocalStroage()}`,
            hospital: getCurrentSiteId(),
          },
        },
      );

      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  };

  // Start the Twilio call
  const startCall = async () => {
    const data = await getTwilioToken(); // Fetch the token
    const token = data?.token;
    const callerPhoneNumber = data?.hospitalPhoneNumber;
    const device = new Device(token, {
      enableImprovedSignalingErrorPrecision: true,
    });

    device.on("error", (error) => {
      handleCloseModal();
      toast.error(
        "An error occurred while setting up the call. Please try again later.",
      );
    });

    const call = await device.connect({
      params: { To: phone, From: callerPhoneNumber },
    }); // Connect the call

    setTwilioDevice(device);
    setTwilioCall(call);

    call.on("disconnect", async (call) => {
      handleCloseModal();
    });
  };

  // Mute or unmute the call
  const toggleMute = () => {
    if (twilioCall) {
      twilioCall.mute(!isMuted);
      setIsMuted(!isMuted);
    }
  };

  const handleCloseModal = (event) => {
    event.stopPropagation();
    if (twilioDevice) {
      twilioDevice.disconnectAll();
      twilioDevice.destroy();
      setTwilioDevice(null);
      setTwilioCall(null);
    }
    handleClose();
  };

  useEffect(() => {
    if (open) {
      startCall();
    }
    return () => {
      if (twilioDevice) {
        twilioDevice.destroy();
        setTwilioDevice(null);
      }
    };
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={(e) => {
        e.stopPropagation();
        return null;
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
        className="DialogContent"
        onClick={(e) => e.stopPropagation()}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              width: "100%",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Outgoing Call
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mt: 5,
          }}
        >
          <Avatar sx={{ width: "80px", height: "80px" }} alt="Profile Image" />

          <Typography variant="h5" sx={{ mt: 2 }}>
            {name ? name : "N/A"}
          </Typography>

          <Typography variant="subtitle1">{phone ? phone : "N/A"}</Typography>

          <Typography variant="h6" sx={{ color: "blue", mt: 1 }}>
            {twilioCall?.status()?.charAt(0).toUpperCase() +
              twilioCall?.status()?.slice(1) || ". . ."}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: isMuted ? "#f08080" : "#c1c1c1bf", // Light red if muted, default gray otherwise
                borderRadius: "40px",
                width: "60px",
                height: "60px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
                "&:hover": {
                  bgcolor: isMuted ? "#ffcccb" : "#c1c1c1", // Slightly darker shade on hover
                },
              }}
              onClick={toggleMute}
            >
              <MicIcon />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#ff0000bf",
                borderRadius: "40px",
                width: "60px",
                height: "60px",
                cursor: "pointer",
                mt: 2,
                "&:hover": {
                  bgcolor: "red",
                },
              }}
              onClick={handleCloseModal}
            >
              <CallEndRounded />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default OutgoingCallComponent;
