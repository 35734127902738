import { Chip, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/system";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#ffffff", // White background
    color: "#000000", // Black text
    border: "1px solid #ccc", // Optional border
    padding: theme.spacing(1),
    fontSize: "0.875rem",
    boxShadow: theme.shadows[1],
  },
}));

export default function CareProgram({
  program,
  isClickable,
  togglePatientProfileDrawer,
  patient,
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <div
        style={{
          borderRadius: "10px",
          overflow: "hidden",
          marginRight: "5px",
        }}
      >
        <Chip
          label={
            program.length > 0
              ? (() => {
                  const formattedText = program[0]
                    .replace(/_/g, " ")
                    .split(/\s+/)
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ");
                  const maxLength = 15; // Set the maximum length for the text
                  return formattedText.length > maxLength
                    ? `${formattedText.substring(0, maxLength)}...`
                    : formattedText;
                })()
              : "No Care Pathway"
          }
          size="medium"
          sx={{
            marginTop: "-0.2em",
            color: "black",
            backgroundColor:
              program.length > 0 ? "#6235ED14" : "rgba(255, 31, 0, 0.08)",
            borderRadius: "8px",
            position: "relative",
            fontWeight: 500,
            cursor: isClickable && "pointer",
          }}
          onClick={
            isClickable
              ? togglePatientProfileDrawer({
                  value: true,
                  patient,
                  isJourney: true,
                })
              : () => {}
          }
        />
      </div>
      <CustomTooltip
        title={
          program.length > 1 ? (
            <div>
              {program.slice(1).map((word, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "4px",
                  }}
                >
                  <span
                    style={{
                      display: "inline-block",
                      width: "8px",
                      height: "8px",
                      backgroundColor: "#6235ED", // Bullet color
                      borderRadius: "3px", // Rounded corners
                      marginRight: "8px",
                    }}
                  ></span>
                  <Typography
                    component="span"
                    sx={{
                      textDecoration: "underline", // Underline only text
                      textUnderlineOffset: "2px",
                    }}
                  >
                    {word
                      .replace(/_/g, " ")
                      .split(/\s+/)
                      .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
                      .join(" ")}
                  </Typography>
                </div>
              ))}
            </div>
          ) : (
            "No additional data"
          )
        }
      >
        <Chip
          label={"+" + (program.length > 0 ? program.length - 1 : 0)}
          sx={{
            borderRadius: "8px",
            backgroundColor: program.length > 0 && "#6235ED",
            color: program.length > 0 && "white",
            fontWeight: 500,
            cursor: isClickable && "pointer",
          }}
          onClick={
            isClickable
              ? togglePatientProfileDrawer({
                  value: true,
                  patient,
                  isJourney: true,
                })
              : () => {}
          }
        />
      </CustomTooltip>
    </div>
  );
}
