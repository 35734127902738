export function TotalPatientsSvg({ size }) {
  return (
    <svg
      width={size || "46"}
      height={size || "46"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="8" fill="#EEEEEE" />
      <path
        d="M16.6666 14.9998C16.6666 13.1582 18.1583 11.6665 20 11.6665C21.8416 11.6665 23.3333 13.1582 23.3333 14.9998C23.3333 16.8415 21.8416 18.3332 20 18.3332C18.1583 18.3332 16.6666 16.8415 16.6666 14.9998ZM24.1666 28.3332H25C25.9166 28.3332 26.6666 27.5832 26.6666 26.6665V22.6832C26.6666 21.7498 26.1583 20.8915 25.325 20.4665C24.9666 20.2832 24.5833 20.1082 24.1666 19.9498V28.3332ZM20.2833 24.1665L22.5 19.4415C21.725 19.2665 20.8916 19.1665 20 19.1665C17.8916 19.1665 16.075 19.7498 14.675 20.4665C14.2684 20.6759 13.9277 20.9939 13.6909 21.3852C13.4541 21.7765 13.3303 22.2258 13.3333 22.6832V28.3332H15.2833C15.1 27.9582 15 27.5332 15 27.0832C15 25.4748 16.3083 24.1665 17.9166 24.1665H20.2833ZM18.3333 28.3332L19.5083 25.8332H17.9166C17.225 25.8332 16.6666 26.3915 16.6666 27.0832C16.6666 27.7748 17.225 28.3332 17.9166 28.3332H18.3333Z"
        fill="black"
      />
    </svg>
  );
}
