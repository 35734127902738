import { Chip, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useChatValues } from "../../contexts/chatValue";
import { useIsAuthenticatedUser } from "../../contexts/isAuthenticatedUser";
import { useGetChatQuery, useMessageSeenMutation } from "../../store/chatSlice";
import { addInParams } from "../../utils";
import { QueueDetailDrawerV2 } from "../QueueDetailDrawerV2/PatientProfileDrawer";

export function PatientInfo({
  patient,
  id,
  redirectUrl,
  surgeryDate,
  pageName,
  patientId,
  firstName,
  queueStatus,
  path,
  acuity,
  userFeedback,
  originalMessage,
  chatbotReply,
  reasoning,
  createdAt,
  taskCategory,
  queueId,
  value,
  FormData,
  onRefetch,
  selectedPatientStatus,
  refetchQueueAgain,
  refetchCount,
  isV2Profile,
  togglePatientProfileDrawer,
}) {
  const navigate = useNavigate();
  const [openChat, setOpenChat] = useState(false);
  const [clickedValue, setClickedValue] = useState(null);
  const [page, setPage] = useState(1);
  const { setChatMessages } = useChatValues();
  const { user } = useIsAuthenticatedUser();
  const [messageSeen] = useMessageSeenMutation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { data, refetch, isLoading } = useGetChatQuery({
    id: patientId,
    sid: "2",
    page,
  });

  const toggleDrawer = (open) => (event) => {
    handleClose();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    addInParams("formType", open?.clickedValue);
    addInParams("patientId", open?.patientId);
    addInParams("queueId", open?.queueId);
    setClickedValue(open?.clickedValue);
    setOpenChat(open?.open);
    setChatMessages([]);

    if (open?.open) {
      messageSeen(patientId).then(() => {
        refetch();
      });
      setPage(1);
    }
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    if (event === undefined) {
      return;
    }
  };
  const redirectToEditPage = (id, name) => {
    if (name) {
      localStorage.setItem("pageName", JSON.stringify(name));
    }
    navigate(`/${redirectUrl}/${id}`, { state: { action: "read-only", id } });
  };

  const calculateAge = (dob) => {
    if (dob) {
      // Check if the date is in ISO 8601 format
      const birthDate = dob.includes("T")
        ? new Date(dob)
        : new Date(`${dob}T00:00:00.000Z`);
      const today = new Date();
      let age_now = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
      }
      return `${age_now}Y`;
    } else {
      return `N/A`;
    }
  };

  const getCarePhase = (surgeryDate) => {
    if (new Date(surgeryDate) > new Date()) {
      return "Post-op";
    } else if (new Date(surgeryDate) < new Date()) {
      return "Pre-op";
    }
  };
  const handleClick = (event) => {
    if (redirectUrl) {
      redirectToEditPage(id, pageName);
    } else {
      toggleDrawer({
        clickedValue:
          selectedPatientStatus === "resolved"
            ? FormData?.type
            : FormData?._id?.type,
        open: true,
        patientId:
          selectedPatientStatus === "resolved"
            ? FormData?.patient?._id
            : FormData?._id?.patientId,
        queueId:
          selectedPatientStatus === "resolved"
            ? FormData?._id
            : FormData?.items?.map((item) => item?._id)?.join(","),
      })(event);
    }
  };

  return (
    <>
      <Typography
        variant="p"
        sx={{
          fontSize: 14,
          fontWeight: 400,
          textAlign: "left",
        }}
      >
        <div style={{ display: "flex" }}>
          <Typography
            onClick={
              isV2Profile
                ? togglePatientProfileDrawer({ value: true, patient })
                : handleClick
            }
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              paddingRight: "5px",
              fontSize: 14,
              fontWeight: 600,
              color: "#000000",
              "&:hover": {
                color: "#816179",
              },
            }}
          >
            {patient?.firstName} {patient?.lastName}
          </Typography>
          {surgeryDate != null && !isV2Profile && (
            <Chip label={getCarePhase(surgeryDate)} size="small" />
          )}
        </div>
        {calculateAge(patient?.birthday)},{" "}
        {patient?.gender?.charAt(0)?.toUpperCase() || "N/A"}
      </Typography>
      <Typography sx={{ color: "#979797", fontSize: 14 }}>
        <span style={{ color: "#979797", fontWeight: 500 }}>DOB:</span>{" "}
        {patient?.birthday
          ? moment(patient?.birthday).format("MM-DD-YYYY")
          : "N/A"}
      </Typography>
      {/* <MessageDrawer
        toggleDrawer={toggleDrawer}
        state={openChat}
        id={patientId}
        name={firstName}
        user={user}
        queueStatus={queueStatus}
        path={path}
        acuity={acuity}
        patient={patient}
        userFeedback={userFeedback}
        originalMessage={originalMessage}
        chatbotReply={chatbotReply}
        reasoning={reasoning}
        createdAt={createdAt}
        taskCategory={taskCategory}
        queueId={queueId}
        tabValue={value}
        refetchAgain={onRefetch}
        consent={patient?.consentResponse}
        setPage={setPage}
        page={page}
        refetchPatientAgain={onRefetch}
        isV2True={true}
        clickedValue={clickedValue}
        setClickedValue={setClickedValue}
        queueData={FormData}
        selectedPatientStatus={selectedPatientStatus}
        refetchCount={refetchCount}
        setOpenChat={setOpenChat}
        refetchQueueAgain={refetchQueueAgain}
      /> */}

      {openChat && (
        <QueueDetailDrawerV2
          state={openChat}
          toggleDrawer={toggleDrawer}
          name={`${patient?.firstName} ${patient?.lastName}`}
          user={user}
          path={path}
          patient={patient}
          acuity={patient?.acuity}
          value={value}
          id={patient?._id}
          status={patient?.status}
          consent={patient?.consentResponse}
          isJourney={false}
          // New Props
          queueStatus={queueStatus}
          queueData={FormData}
          refetchCount={refetchCount}
          setOpenChat={setOpenChat}
          refetchQueueAgain={refetchQueueAgain}
          refetchAgain={onRefetch}
        />
      )}
    </>
  );
}
