import { Box, Typography, Button } from "@mui/material";
import { HelpCenterOutlined, StopCircleOutlined } from "@mui/icons-material"; // Example icons
import GallerySection from "./GallerySection";
import FormSection from "./FormSection";
import CallSection from "./CallSection";
import TaskSection from "./TaskSection";
import { useStopAgentMutation } from "../../../store/workflowsSlice";
import { toast } from "react-toastify";
import ChatSection from "./ChatSection";
import TimelinePopover from "./TimelinePopover";
import { useEffect, useState } from "react";
import axios from "axios";
import { getCurrentSiteId, getTokenFromLocalStroage } from "../../../utils";
import { Conversation } from "../../../assets/svgs/PatientProfileTabsSvgs/Conversation";
import { Gallery } from "../../../assets/svgs/PatientProfileTabsSvgs/Gallery";
import { Form } from "../../../assets/svgs/PatientProfileTabsSvgs/Form";
import { Call } from "../../../assets/svgs/PatientProfileTabsSvgs/Call";
import { Task } from "../../../assets/svgs/PatientProfileTabsSvgs/Task";

const MedRefillAgent = ({
  type,
  isFullScreen,
  setIsFullScreen,
  data,
  filteredQueueData,
  getPatientQueueData,
}) => {
  const [stopAgent, { isLoading: isStopAgentLoading }] = useStopAgentMutation();
  const [workflowStatus, setWorkflowStatus] = useState(null); // To store the fetched workflow data

  const getWorkflowStatus = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_LLM_URL}/workflow/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${getTokenFromLocalStroage()}`, // You can store the token in environment variables
            hospital: getCurrentSiteId(),
          },
        },
      );
      setWorkflowStatus(response.data); // Return the data from the response
    } catch (error) {
      toast.error("Error fetching workflow details:", error);
      throw error; // Handle the error accordingly
    }
  };

  const getIconByKey = (key) => {
    switch (key) {
      case "message":
        return <Conversation color="#424242" size={20} />;
      case "new_media":
        return <Gallery color="#424242" size={20} />;
      case "form_submission":
        return <Form color="#424242" size={20} />;
      case "care_ally_message":
        return <Conversation color="#424242" size={20} />;
      case "dialogue":
        return <Form color="#424242" size={20} />;
      case "call":
        return <Call color="#424242" size={20} />;
      case "task":
        return <Task color="#424242" size={20} />;
      default:
        return <HelpCenterOutlined />; // Default fallback icon
    }
  };
  useEffect(() => {
    const fetchWorkflowStatus = () => {
      if (data?.items?.[0]?.metadata?.workflowId) {
        getWorkflowStatus(data?.items?.[0]?.metadata?.workflowId);
      }
    };

    // Call immediately on render
    fetchWorkflowStatus();

    // Continue polling every 5 seconds
    const interval = setInterval(fetchWorkflowStatus, 5000);

    // Cleanup the interval when the component unmounts or when `data` changes
    return () => clearInterval(interval);
  }, [data]);

  const handleStopAgent = () => {
    const body = {
      user_id: data?.patient?._id,
      workflow_id: data?.items?.[0]?.metadata?.workflowId,
    };

    stopAgent(body)
      .then((res) => {
        getWorkflowStatus(data?.items?.[0]?.metadata?.workflowId);
        toast.success("Agent has been stopped successfully!");
      })
      .catch((err) => {
        toast.error(err?.message || "Something went wrong!");
      });
  };

  return (
    <Box
      sx={{
        width: "100%",
        margin: "0 auto",
        background: "#fff",
        overflow: "hidden",
        border: "1px solid #e8e8e8",
        boxShadow: "none",
        borderRadius: "8px",
        marginTop: "14px",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "12px 16px",
          borderBottom: "1px solid #e0e0e0",
          height: "45px",
        }}
      >
        {/* First Section */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "157%",
              display: "flex",
              alignItems: "center",
            }}
          >
            {getIconByKey(type)}
            <span style={{ marginLeft: 4 }}>
              {workflowStatus?.intent
                ? workflowStatus.intent
                    .replace("_intent", "_agent") // Replace 'intent' with 'agent'
                    .toLowerCase()
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")
                : type
                  ? type
                      .toLowerCase()
                      .split("_")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1),
                      )
                      .join(" ")
                  : "Loading . ."}
            </span>
          </Typography>
          {/* {data?.items?.[0]?.metadata?.workflowId && (
            <>
              <Checkbox size="small" sx={{ marginLeft: "8px" }} />
              <Typography
                sx={{
                  color: "#212121",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "157%",
                }}
              >
                View Just Actions
              </Typography>
            </>
          )} */}
        </Box>

        {/* Third Section */}
        <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
          {data?.items?.[0]?.metadata?.workflowId && (
            <>
              {/* In Progress */}
              <TimelinePopover data={workflowStatus} />
              {/* Stop Agent Button */}
              {workflowStatus?.status === "in_progress" && (
                <Button
                  variant="outlined"
                  fontSize="small"
                  size="small"
                  startIcon={!isStopAgentLoading && <StopCircleOutlined />}
                  sx={{ fontWeight: 600 }}
                  onClick={() => handleStopAgent()}
                  disabled={isStopAgentLoading}
                >
                  {isStopAgentLoading ? "Loading..." : "Stop Agent"}
                </Button>
              )}
            </>
          )}
          {/* Info Icon */}
          {/* <IconButton
            onClick={() => {
              setIsFullScreen(!isFullScreen);
            }}
          >
            {isFullScreen ? <FullscreenExitRounded /> : <FullscreenRounded />}
          </IconButton> */}
        </Box>
      </Box>

      {/* Chat Section */}
      {type === "form_submission" && (
        <FormSection {...{ data: filteredQueueData, isFullScreen }} />
      )}

      {type === "call" && (
        <CallSection
          {...{ data: filteredQueueData, isFullScreen, getPatientQueueData }}
        />
      )}

      {type === "new_media" && (
        <GallerySection {...{ data: filteredQueueData, isFullScreen }} />
      )}

      {type === "task" && (
        <TaskSection {...{ data: filteredQueueData, isFullScreen }} />
      )}

      {/* Else TAB */}
      {/* {type &&
        !isFullScreen &&
        !["form_submission", "call", "new_media", "task"].includes(type) && (
          <ChatSection />
        )} */}

      {!["form_submission", "call", "new_media", "task"].includes(type) && (
        <Box sx={{ mt: 2 }}>
          <ChatSection
            {...{
              data: filteredQueueData,
              isFullScreen,
              patient: data?.patient,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default MedRefillAgent;
