export const createPatientSidebarData = [
  {
    text: "Demographics",
    path: "demographics",
  },
  // {
  //   text: "Journeys",
  //   path: "journeys",
  // },
];

export const viewPatientSidebarData = [
  {
    text: "Overview",
    path: "overview",
  },
  { text: "Provider Information", path: "providerInfo" },
  {
    text: "Procedures",
    path: "surgery",
  },
  {
    text: "Call",
    path: "Call",
  },
  {
    text: "Forms",
    path: "forms",
  },
  {
    text: "Journeys",
    path: "journeys",
  },
  {
    text: "Tasks",
    path: "tasks",
  },
  {
    text: "Appointment",
    path: "appointment",
  },

  {
    text: "Note",
    path: "note",
  },
  {
    text: "PRO Bundle",
    path: "bundle",
  },
  { text: "Care Ally", path: "careAlly" },
];

export const patientTableSidebarData = [
  {
    text: "All Patients",
    path: "patient",
  },
  { text: "PROs", path: "pros" },
  { text: "Social Needs", path: "social" },
];

export const EthincityData = [
  { label: "Hispanic or Latino", value: "hispanic" },
  { label: "Not Hispanic or Latino", value: "notHispanic" },
];

export const RaceData = [
  { label: "Black or African American", value: "black" },
  { label: "White", value: "white" },
  { label: "Asian", value: "asian" },
  { label: "American Indian or Alaska Native", value: "americanIndian" },
  {
    label: "Native Hawaiian or Other Pacific Islander",
    value: "nativeHawaiian",
  },
];
export const LanguageData = [
  { label: "English", value: "english" },
  { label: "Spanish", value: "spanish" },
];
