import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6235ED",
      selected: "#6235ED14",
    },
    secondary: {
      main: "#1B4DFF1A",
    },
    error: {
      main: "#FF3E3E",
    },
    warning: {
      main: "#EF6C00",
    },
    info: {
      main: "#0288D1",
    },
    success: {
      main: "#2E7D32",
    },
  },
  typography: {
    allVariants: {
      fontFamily: "var(--fontFamily, Roboto)",
      color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
    },

    h1: {
      fontSize: "var(--_fontSize-6rem, 96px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightLight, 300)",
      lineHeight: "116.7%",
      letterSpacing: "-1.5px",
    },
    h2: {
      fontSize: "var(--_fontSize-375rem, 60px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightLight, 300)",
      lineHeight: "120%",
      letterSpacing: "-0.5px",
    },
    h3: {
      fontSize: "var(--_fontSize-3rem, 48px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightRegular, 400)",
      lineHeight: "116.7%",
    },
    h4: {
      fontSize: "var(--font-size-2125-rem, 34px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightRegular, 400)",
      lineHeight: "123.5%",
      letterSpacing: "0.25px",
    },
    h5: {
      fontSize: "var(--font-size-15-rem, 24px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightRegular, 400)",
      lineHeight: "133.4%",
    },
    h6: {
      fontSize: "var(--font-size-125-rem, 20px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightMedium, 500)",
      lineHeight: "160%",
      letterSpacing: "0.15px",
    },
    subtitle1: {
      fontSize: "var(--_fontSize-1rem, 16px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightRegular, 400)",
      lineHeight: "175%;" /* 28px */,
      letterSacing: "0.15px",
    },
    subtitle2: {
      fontSize: "var(--font-size-0875-rem, 14px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightMedium, 500)",
      lineHeight: "157%" /* 21.98px */,
      letterSpacing: "0.1px",
    },
    body1: {
      fontSize: "var(--_fontSize-1rem, 16px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightRegular, 400)",
      lineHeight: "150%" /* 24px */,
      letterSpacing: "0.15px",
    },
    body2: {
      fontSize: "var(--font-size-0875-rem, 14px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightRegular, 400)",
      lineHeight: "143%",
      letterSpacing: "0.17px",
    },
    caption: {
      fontSize: "var(--font-size-075-rem, 12px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightRegular, 400)",
      lineHeight: "166%" /* 19.92px */,
      letterSpacing: "0.4px",
    },
    overline: {
      fontSize: "var(--font-size-075-rem, 12px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightRegular, 400)",
      lineHeight: "266%" /* 31.92px */,
      letterSpacing: "1px",
      textTransform: "uppercase",
    },
    custom: {
      fontSize: "var(--font-size-0875-rem, 14px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightMedium, 500)",
      lineHeight: "24px",
      letterSpacing: "0.17px",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8, // Default border radius for all buttons
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 20,
          height: 20,
          fontSize: "10px",
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: "#f5f5f5",
          padding: "3px",
          borderRadius: "8px",
          "& .MuiToggleButton-root": {
            border: "none",
            borderRadius: "8px !important",
            padding: "6px 16px",
            color: "#666",
            "&.Mui-selected": {
              backgroundColor: "#fff",
              color: "#000",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              "&:hover": {
                backgroundColor: "#fff",
              },
            },
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.05)",
            },
          },
        },
      },
    },
  },
});

export default theme;
