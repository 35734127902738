import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SolidButton } from "../../../Components";
import { useIsMobile } from "../../../contexts/isMobile";
import { useGetAllSurveysQuery } from "../../../store/suerveySlice";
import {
  useAddNewBundleMutation,
  useEditBundleMutation,
} from "../../../store/bundlingsSlice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const procedurePhaseData = {
  "Pre Operative": "pre-op",
  "Post Operative": "post-op",
  None: "none",
};

const SurveyDropdown = ({ surveys, setSurveys, surveyList }) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // Store the _id instead of title
    setSurveys(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <div>
      <FormControl fullWidth sx={{ mt: 2, marginBottom: "0.5em" }}>
        <InputLabel id="demo-multiple-checkbox-label">
          Select Survey *
        </InputLabel>
        <Select
          required={true}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple={true}
          value={surveys}
          onChange={handleChange}
          input={<OutlinedInput label="Select Survey *" />}
          renderValue={(selected) =>
            selected
              .map(
                (id) =>
                  surveyList?.data?.find((src) => src._id === id)?.form?.title,
              )
              .join(", ")
          }
          MenuProps={MenuProps}
        >
          {surveyList?.data?.map((src) => (
            <MenuItem key={src._id} value={src._id}>
              <Checkbox checked={surveys.indexOf(src._id) > -1} />
              <ListItemText primary={src?.form?.title} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

const PreoperativeDropdown = ({ procedurePhase, setProcedurePhase }) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // Store the _id instead of title
    setProcedurePhase(value);
  };

  return (
    <div>
      <FormControl fullWidth sx={{ mt: 2, marginBottom: "0.5em" }}>
        <InputLabel id="procedure-phase-label">
          Select Procedure Phase *
        </InputLabel>
        <Select
          labelId="procedure-phase-label"
          value={procedurePhase}
          onChange={handleChange}
          label="Select Procedure Phase *"
        >
          {Object.keys(procedurePhaseData).map((label) => (
            <MenuItem key={label} value={procedurePhaseData[label]}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export const BundlingModal = ({
  open,
  setOpen,
  title: modalTitle,
  refetchAgain,
  data,
}) => {
  const { user } = useSelector((state) => state.user);
  const [title, setTitle] = useState("");
  const [procedurePhase, setProcedurePhase] = useState("");
  const [checkPoint, setCheckPoint] = useState(1);
  const [videoLink, setVideoLink] = useState("");
  const [description, setDescription] = useState("");
  const [surveys, setSurveys] = useState([]);
  const { data: FormData } = useGetAllSurveysQuery(user.id);

  console.log(FormData);

  const [addNewBundle, { isLoading }] = useAddNewBundleMutation();
  const [editBundle, { isLoading: isEditLoading }] = useEditBundleMutation();
  const isMobile = useIsMobile();

  const handleClose = () => {
    setOpen(false);
    setTitle(""); // Clear the tag input field
    setSurveys([]);
    setDescription("");
    setCheckPoint(1);
    setProcedurePhase("");
    setVideoLink("");
  };

  const onSave = async () => {
    if (title === "" || surveys.length === 0 || description === "") {
      toast.warning("Please fill all fields!");
      return;
    }

    if (data?.id) {
      const body = {
        title,
        description,
        surveys,
        checkPoint,
        videoLink,
        procedurePhase,
      };
      await editBundle({ id: data?.id, body })
        .unwrap()
        .then((res) => {
          toast.success("Successfully edited!");
          refetchAgain();
          handleClose();
        })
        .catch((e) => {
          toast.error(e?.data?.error || e?.message || "some error occured!");
        });
    } else {
      const body = {
        title,
        description,
        surveys,
        checkPoint,
        videoLink,
        procedurePhase,
      };

      await addNewBundle(body)
        .unwrap()
        .then((res) => {
          toast.success("Successfully created!");
          refetchAgain();
          handleClose();
        })
        .catch((e) => {
          toast.error(e?.data?.error || e?.message || "some error occured!");
        });
    }
    handleClose();
  };

  useEffect(() => {
    if (data?.id) {
      setTitle(data?.title ? data?.title : "");
      setDescription(data?.description ? data?.description : "");
      setSurveys(data?.surveys ? data?.surveys : []);
      setCheckPoint(data?.checkPoint ? data?.checkPoint : 1);
      setProcedurePhase(data?.procedurePhase ? data?.procedurePhase : "");
      setVideoLink(data?.videoLink ? data?.videoLink : "");
    }
  }, [data]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 350 : 600,
    bgcolor: "#F5F5F5",
    p: 4,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {modalTitle}
            </Typography>
            <Box sx={{ cursor: "pointer" }} onClick={handleClose}>
              <CloseIcon />
            </Box>
          </Stack>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item md={12} xs={12}>
              <TextField
                required={true}
                id="outlined-required"
                label="Title"
                name="title"
                placeholder="Enter PRO Bundle Title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                sx={{ width: "100%", mt: 2, marginBottom: "0.5em" }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <PreoperativeDropdown
                {...{ procedurePhase, setProcedurePhase }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                required={true}
                id="outlined-required"
                label="Check Point"
                name="checkPoint"
                placeholder="Enter Check Point"
                value={checkPoint}
                onChange={(e) => {
                  setCheckPoint(e.target.value);
                }}
                type="number"
                InputProps={{
                  inputProps: {
                    min: 1,
                    max: 12,
                  },
                }}
                sx={{ width: "100%", mt: 2, marginBottom: "0.5em" }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                required={true}
                id="outlined-required"
                label="Video Link"
                name="videoLink"
                placeholder="Enter video link"
                value={videoLink}
                onChange={(e) => {
                  setVideoLink(e.target.value);
                }}
                sx={{ width: "100%", mt: 2, marginBottom: "0.5em" }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SurveyDropdown
                {...{
                  surveys,
                  setSurveys,
                  surveyList: FormData ? FormData : [],
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                required={true}
                id="outlined-multiline-static"
                multiline
                rows={4}
                label="Description"
                name="description"
                placeholder="Enter Description"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                sx={{ width: "100%", mt: 2, marginBottom: "0.5em" }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                <Box sx={{ ml: "auto" }}>
                  <SolidButton
                    label="Save"
                    onClick={onSave}
                    disabled={isLoading || isEditLoading}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
