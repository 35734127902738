import { useDebouncedValue } from "@mantine/hooks";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { SeachField } from "../../../Components";
import { useIsMobile } from "../../../contexts/isMobile";
import { hasScope } from "../../../utils";
import { BundlingModal } from "./index";
import { BundlingTable } from "./index";
import { useGetBundleTableQuery } from "../../../store/bundlingsSlice";

// const data = [
//   {
//     _id: 1,
//     name: "John Doe",
//     description: "A software developer with a passion for open-source projects.",
//     journey: ["Started with HTML", "Learned JavaScript", "Became proficient in React"]
//   },
//   {
//     _id: 2,
//     name: "Jane Smith",
//     description: "An experienced data scientist specializing in machine learning.",
//     journey: ["Graduated in Computer Science", "Worked with Python", "Built ML models"]
//   },
//   {
//     _id: 3,
//     name: "Alex Johnson",
//     description: "A DevOps engineer focused on cloud infrastructure and automation.",
//     journey: ["Learned Linux systems", "Gained AWS certification", "Implemented CI/CD pipelines"]
//   }
// ];

export function BundlingAccordion({ scopes }) {
  const isMobile = useIsMobile();
  const [expanded, setExpanded] = useState(false);
  const [changeText, setChangeText] = useState(false);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedValue] = useDebouncedValue(searchTerm, 200);
  const searchAbleFields = ["title"];
  const [selectedFields, setSelectedFields] = useState([...searchAbleFields]);

  const handleFieldSelect = (option, checked) => {
    if (checked) {
      setSelectedFields([...selectedFields, option]);
    } else {
      setSelectedFields(selectedFields.filter((o) => o !== option));
    }
  };

  const queries = {};

  for (const field of selectedFields) {
    queries[field] = debouncedValue;
  }

  const { data, isLoading, isError, refetch } = useGetBundleTableQuery({
    page,
    ...queries,
  });

  // States for CreateContentModal
  const [open, setOpen] = React.useState(false);
  const handleOpenCreateModal = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const direction = isMobile ? "column" : "row";

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSearchFieldClick = (event) => {
    event.stopPropagation();
  };

  const refetchAgain = () => {
    refetch();
  };

  return (
    <Grid item={true} xs={12} md={12} lg={12}>
      <Paper elevation={3} sx={{ marginTop: "20px", marginBottom: "20px" }}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            onClick={() => setChangeText((prev) => !prev)}
            // direction="column" justifyContent="space-between"
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: direction,
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 500,
                  margin: "8px 2px",
                  width: isMobile ? "100%" : "34%",
                }}
              >
                <Box
                  sx={{
                    borderBottom: changeText ? "5px solid #816179" : "none",
                    display: "initial",
                  }}
                >
                  PRO Bundles
                </Box>
              </Typography>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="flex-end"
                alignItems={{ xs: "flex-start", sm: "center" }}
                sx={{ flexGrow: 1 }}
              >
                {hasScope(scopes, "settings/contentManagers:create") && (
                  <Box
                    sx={{
                      marginBottom: { xs: 1, sm: 0 },
                      marginRight: { sm: 2 },
                      width: isMobile ? "100%" : "150px",
                    }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      sx={{
                        background: "#816179",
                        padding: "5px",
                        height: isMobile ? "30px" : "35px",
                        width: isMobile ? "100%" : "150px",
                        borderRadius: "0.5rem",
                        fontSize: "14px",
                      }}
                      onClick={handleOpenCreateModal}
                    >
                      Create
                    </Button>
                  </Box>
                )}

                <Box
                  onClick={handleSearchFieldClick}
                  sx={{
                    width: isMobile ? "100%" : "20%",
                    marginRight: { xs: 0, sm: 2 },
                    marginBottom: isMobile && "8px",
                    ".MuiAutocomplete-inputRoot": { padding: "1px" },
                  }}
                >
                  <SeachField
                    {...{
                      searchTerm,
                      setSearchTerm,
                      options: searchAbleFields,
                      selectedOptions: selectedFields,
                      handleOptionSelect: handleFieldSelect,
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    padding: "5px",
                    fontSize: "14px",
                    fontWeight: 500,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: isMobile ? "100%" : "15%",
                    border: "1px solid #d0d0d0",
                    borderRadius: "5px",
                  }}
                >
                  {changeText ? "Reduce" : "Expand"}{" "}
                  {changeText ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Typography>
              </Stack>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <BundlingTable
              {...{
                data,
                isLoading,
                isError,
                page,
                setPage,
                searchTerm,
                scopes,
                refetchAgain,
              }}
            />
          </AccordionDetails>
        </Accordion>

        {/* Modal Button */}
        <BundlingModal
          open={open}
          setOpen={setOpen}
          title={"Create PRO Bundle"}
          refetchAgain={refetchAgain}
        />
      </Paper>
    </Grid>
  );
}
