import {
  DoneAllRounded,
  DoneRounded,
  SmsFailedRounded,
} from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import MessageInterventionAccordion from "../../../Components/MessageLayout/MessageInterventionAccordion";

const ChatSection = ({ data, isFullScreen, patient }) => {
  const formatDate = (createdAt) => {
    const createdAtMoment = moment(createdAt);
    const today = moment().startOf("day");
    const tomorrow = moment().startOf("day").add(1, "day");

    if (createdAtMoment.isSame(today, "day")) {
      return "Today " + createdAtMoment.format("hh:mm A");
    } else if (createdAtMoment.isSame(tomorrow, "day")) {
      return "Tomorrow " + createdAtMoment.format("hh:mm A");
    } else {
      return createdAtMoment.format("MMMM DD, YYYY hh:mm A");
    }
  };

  const messageStatusIcons = (status, failedReason) => {
    switch (status) {
      case "DELIVERED":
        return <DoneAllRounded sx={{ color: "#1B4DFF", ml: "5px" }} />;
      case "ACKNOWLEDGED":
        return <DoneRounded sx={{ color: "#979797", ml: "5px" }} />;
      case "FAILED":
        return (
          <Tooltip title={failedReason || "Reason not available"} arrow>
            <SmsFailedRounded sx={{ color: "red", ml: "5px" }} />
          </Tooltip>
        );
      default:
        return null;
    }
  };

  const MessageStatusIcon = ({ status, failedReason }) => {
    return messageStatusIcons(status, failedReason);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        overflowY: "auto",
        overflowX: "hidden",
        p: 2,
      }}
      className="ScrollDesign"
    >
      {data?.length > 0 ? (
        data.map((item, index) => (
          <>
            {item?.body?.length > 0 &&
              (item.sentBy === "user" ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      mt: !item?.patientQueue && 1,
                      my: item?.patientQueue && 1,
                      // mr:1,
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#979797",
                        fontSize: "12px",
                        margin: "2px",
                        textAlign: "right",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Sent by {item.sentBy}, {formatDate(item.createdAt)}
                    </Typography>
                    <Box
                      // sx={{
                      //   maxWidth: "50%",
                      //   borderRadius: "10px",
                      //   border: "1px ",
                      //   backgroundColor: "rgba(0,151,236,0.12)",
                      //   color: "#000",
                      //   borderTopRightRadius: "0px",
                      //   p: 2,
                      //   mt: 1,
                      //   textAlign: "left",
                      // }}
                      sx={{
                        maxWidth: "50%",
                        borderRadius: "10px",
                        border: "1px ",
                        backgroundColor: "#F2F2F7",
                        color: "#000",
                        borderTopLeftRadius: "0px",
                        p: 2,
                        mt: 1,
                        textAlign: "left",
                        display: "flex", // Add display flex
                        alignItems: "center", // Center items vertically
                        position: "relative", // Required for positioning the pseudo-element
                        "&::before": {
                          // Create the triangle
                          content: '""',
                          position: "absolute",
                          bottom: 10,
                          left: -10,
                          width: 0,
                          height: 0,
                          borderLeft: "10px solid transparent",
                          borderRight: "10px solid #F2F2F7",
                          borderBottom: "10px solid transparent",
                        },
                      }}
                    >
                      {/* {item?.mediaUrl.length !== 0 && (
												<img
													src={item?.mediaUrl}
													style={{
														cursor: 'pointer',
														width: '4em',
														height: '4em',
														padding: '5px',
														borderRadius: '2px',
														background: '#FFFFFF',
														marginTop: '1em',
													}}
													onClick={() => openModal(item?.mediaUrl)}
												/>
											)} */}
                      <Typography
                        sx={{
                          color: "#000",
                          mt: 1,
                          fontSize: "0.875 rem",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm]}
                          components={{
                            a: (props) => {
                              return (
                                <a
                                  href={props.href}
                                  target="blank"
                                  style={{ wordWrap: "break-word" }}
                                >
                                  {props.children}
                                </a> // All other links
                              );
                            },
                          }}
                        >
                          {item?.body
                            ?.replace(/\n\s*\n/g, "\n")
                            ?.replace(/^```|```$/g, "")}
                        </ReactMarkdown>
                      </Typography>
                    </Box>
                  </Box>

                  {item?.patientQueue && (
                    <Box
                      key={index}
                      sx={{
                        width: "100%",
                      }}
                    >
                      <MessageInterventionAccordion
                        item={item}
                        index={index}
                        queueData={item?.patientQueue}
                        patient={patient}
                      />
                    </Box>
                  )}
                </>
              ) : item.sentBy === "journey" && item.chatType === "email" ? (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems:
                      item?.sentBy === "user" ? "flex-start" : "flex-end",
                    my: 1,
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#979797",
                      fontSize: "12px",
                      margin: "2px",
                      textAlign: "left",
                    }}
                  >
                    {formatDate(item.createdAt)}
                    {", "}
                    Automated Journey Email
                  </Typography>
                  <Box
                    sx={{
                      maxWidth: "50%",
                      borderRadius: "10px",
                      border: "1px ",
                      backgroundColor: "#FADBD8",
                      color: "#000",
                      borderTopRightRadius: "0px",
                      p: 2,
                      mt: 1,
                      textAlign: "left",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#000",
                        mt: 1,
                        fontSize: "0.875 rem",
                        whiteSpace: "pre-wrap",
                      }}
                      dangerouslySetInnerHTML={{ __html: item?.body }}
                    ></Typography>
                  </Box>
                </Box>
              ) : item?.senderId === "chatModeSource" ? (
                <Typography
                  sx={{
                    color: "#979797",
                    fontSize: "12px",
                    margin: "2px",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    lineHeight: 5.5,
                  }}
                >
                  {formatDate(item.createdAt)}
                </Typography>
              ) : (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems:
                      item?.sentBy === "user" ? "flex-start" : "flex-end",
                    my: 1,
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#979797",
                      fontSize: "12px",
                      margin: "2px",
                      textAlign: "left",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {formatDate(item.createdAt)}
                    {", "}
                    {item.chatbot === true && item?.sentBy == "journey"
                      ? "Automated Journey Message"
                      : item.chatbot === true
                        ? "AI Chatbot Message"
                        : "Handcrafted Message"}

                    <MessageStatusIcon
                      status={item?.messageStatus}
                      failedReason={item?.failureReason}
                    />
                  </Typography>
                  <Box
                    sx={{
                      maxWidth: "50%",
                      borderRadius: "10px",
                      border: "1px ",
                      backgroundColor:
                        item.chatbot === true && item?.sentBy == "journey"
                          ? "rgba(255,175,64,0.12)"
                          : item.chatbot === true
                            ? "rgba(0,184,148,0.12)"
                            : "rgba(0,151,236,0.12)",
                      color: "#000",
                      borderTopRightRadius: "0px",
                      p: 2,
                      mt: 1,
                      textAlign: "left",
                    }}
                  >
                    {/* {item?.mediaUrl.length !== 0 && (
												<img
													src={item?.mediaUrl}
													style={{
														cursor: 'pointer',
														width: '4em',
														height: '4em',
														padding: '5px',
														borderRadius: '2px',
														background: '#FFFFFF',
														marginTop: '1em',
													}}
													onClick={() => openModal(item?.mediaUrl)}
												/>
											)} */}
                    <Typography
                      sx={{
                        color: "#000",
                        mt: 1,
                        fontSize: "0.875 rem",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={{
                          a: (props) => {
                            return (
                              <a
                                href={props.href}
                                target="blank"
                                style={{ wordWrap: "break-word" }}
                              >
                                {props.children}
                              </a> // All other links
                            );
                          },
                        }}
                      >
                        {item?.body
                          ?.replace(/\n\s*\n/g, "\n")
                          ?.replace(/^```|```$/g, "")}
                      </ReactMarkdown>
                    </Typography>
                  </Box>
                </Box>
              ))}
          </>
        ))
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100px",
          }}
        >
          <Typography
            variant="h6"
            color="textSecondary"
            sx={{ fontSize: "16px" }}
          >
            No Record Found
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ChatSection;
