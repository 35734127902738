import { Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CircularLoader } from "../../../Components/Loader";
import OutlinedButton from "../../../Components/OutlinedButton";
import ProviderInfo from "../PatientTable/DrawerComponents/ProviderInfo";
import { bundleData } from "./ProfileUtils";
import { BundleModal } from "./ProfileModals/BundleModal";
import { useGetBundleForPatientTableQuery } from "../../../store/bundlingsSlice";

export default function BundleTable({ id, status }) {
  const [page, setPage] = useState(1);
  const [editData, setEditData] = useState({});
  const [open, setOpen] = useState(false);
  const { data, refetch, isLoading } = useGetBundleForPatientTableQuery({
    page,
    id,
  });

  useEffect(() => {
    refetch();
  }, [id]);

  const handleModal = () => {
    setOpen(true);
  };

  const fetchAgain = () => {
    refetch();
  };

  if (isLoading) {
    return (
      <div>
        <CircularLoader />
      </div>
    );
  }

  return (
    <div id="bundle">
      <Paper
        elevation={3}
        sx={{ height: "100%", p: "2rem", borderRadius: "10px", mt: "3rem" }}
      >
        <Typography
          variant="p"
          sx={{
            fontSize: "18px",
            fontWeight: "bolder",
          }}
        >
          PRO Bundle
        </Typography>
        <Grid
          container
          justifyContent="space-between"
          sx={{ paddingY: "12px" }}
        >
          <Grid item={true} xs={12} md={12} textAlign="right">
            <OutlinedButton label="Create" handleClick={handleModal} />
          </Grid>
        </Grid>
        <ProviderInfo
          data={data?.data?.patientBundles ? data?.data?.patientBundles : []}
          tableType="PROBundle"
          columns={bundleData}
          count={data?.data?.totalPages}
          {...{
            page,
            setPage,
            setEditData,
            handleModalEdit: handleModal,
            fetchAgain,
          }}
        />
      </Paper>
      <BundleModal
        {...{
          open,
          setOpen,
          pid: id,
          fetchAgain,
          setEditData,
          editData,
          status,
        }}
      />
    </div>
  );
}
