import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { useGetUserForTaskQuery } from "../../../store/userManagmentSlice";
import {
  useRemoveCareTeamInPatientQueueMutation,
  useSetCareTeamInPatientQueueMutation,
} from "../../../store/patientQueuesSlice";
import { toast } from "react-toastify";
import { styled } from "@mui/system";
import {
  Avatar,
  AvatarGroup,
  Box,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip placement="top" {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#ffffff", // White background
    color: "#000000", // Black text
    border: "1px solid #ccc", // Optional border
    padding: theme.spacing(1),
    fontSize: "0.875rem",
    boxShadow: theme.shadows[1],
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CustomMultiSelect({
  queueID,
  queueStatus,
  assignee,
  refetchQueueAgain,
}) {
  const [personName, setPersonName] = React.useState([]); // Initialize with passed assignee
  const [menuOpen, setMenuOpen] = React.useState(false); // State to control menu open/close
  const [unCheckedIds, setUnCheckedIds] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState(""); // State to manage search input
  const [hasUserModified, setHasUserModified] = React.useState(false);
  const { data: userData, isLoading } = useGetUserForTaskQuery();
  const [setCareTeamInPatientQueue, { isLoading: isAssigning }] =
    useSetCareTeamInPatientQueueMutation();

  const [removeCareTeamInPatientQueue] =
    useRemoveCareTeamInPatientQueueMutation();

  const userArr = userData?.data || [];
  const theme = useTheme();

  const handleClose = async () => {
    setMenuOpen(false);

    const formattedQueueIds = Array.isArray(queueID)
      ? queueID.map((item) => item.patientId || item)
      : [queueID.patientId || queueID];

    const unCheckedPayload = {
      patientQueueIds: formattedQueueIds,
      assignees: unCheckedIds,
    };

    if (unCheckedIds?.length !== 0) {
      try {
        await removeCareTeamInPatientQueue({ body: unCheckedPayload }).unwrap();
        setUnCheckedIds([]);
        toast.success("User removed from Patient Queue successfully.");
      } catch (error) {
        toast.error(
          "Failed to unassign Patient Queue to the user. Please try again.",
        );
      }
    }
  };

  const handleChange = async (event) => {
    const {
      target: { value },
    } = event;

    const selectedAssignees =
      typeof value === "string" ? value.split(",") : value;
    setPersonName(selectedAssignees);
    setHasUserModified(true);

    // Determine the unchecked assignees
    const previouslyChecked = personName; // The previously selected assignees
    const newlyChecked = selectedAssignees; // The currently selected assignees

    // Find the difference (unchecked items)
    const newlyUncheckedIds = previouslyChecked.filter(
      (id) => !newlyChecked.includes(id),
    );

    setUnCheckedIds(newlyUncheckedIds);

    const formattedQueueIds = Array.isArray(queueID)
      ? queueID.map((item) => item.patientId || item)
      : [queueID.patientId || queueID];

    const payload = {
      patientQueueIds: formattedQueueIds,
      assignees: selectedAssignees,
    };

    const unCheckedPayload = {
      patientQueueIds: formattedQueueIds,
      assignees: newlyUncheckedIds,
    };

    if (newlyUncheckedIds?.length !== 0) {
      try {
        await removeCareTeamInPatientQueue({ body: unCheckedPayload }).unwrap();
        if (refetchQueueAgain) {
          refetchQueueAgain();
        }
        toast.success("The user has been unassigned from the Patient Queue.");
        setUnCheckedIds([]);
      } catch (error) {
        toast.error(
          "Failed to unassign Patient Queue to the user. Please try again.",
        );
      }
    } else {
      try {
        await setCareTeamInPatientQueue({ body: payload }).unwrap();
        if (refetchQueueAgain) {
          refetchQueueAgain();
        }
        toast.success("Patient Queue assigned to the user successfully.");
      } catch (error) {
        toast.error(
          "Failed to assign Patient Queue to the user. Please try again.",
        );
      }
    }
  };

  const isDisabled =
    queueStatus?.includes("resolved") || queueStatus?.includes("ignored");

  const handleSearchChange = (event) => {
    event.stopPropagation();
    setSearchQuery(event.target.value);
  };

  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  React.useEffect(() => {
    if (!hasUserModified && !menuOpen) {
      if (JSON.stringify(assignee) !== JSON.stringify(personName)) {
        setPersonName(assignee);
      }
    }
  }, [assignee, personName, hasUserModified, menuOpen]);

  const filteredUsers = userArr.filter((user) =>
    user.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const generateColorFromString = (str) => {
    let hash = 0;
    for (let i = 0; i < str?.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `hsl(${hash % 360}, 70%, 60%)`; // Generate an HSL color based on the string
    return color;
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <FormControl
        sx={{
          maxHeight: "35px",
          display: "flex",
          justifyContent: "flex-start",
          borderRadius: "8px",
          padding: "0px",
          marginRight: "5px",
        }}
      >
        <Select
          multiple
          value={personName}
          onChange={handleChange}
          open={menuOpen}
          onOpen={() => setMenuOpen(true)}
          onClose={() => {
            setMenuOpen(false);
            setSearchQuery("");
          }}
          onClick={(e) => e.stopPropagation()}
          disabled={isDisabled}
          displayEmpty
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#D32F2F",
                  }}
                >
                  No Assignee
                </Typography>
              );
            }
            const firstSelectedName = userArr.find(
              (user) => user._id === selected[0],
            )?.name;
            return (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <AvatarGroup total={1}>
                  {selected.map((id) => {
                    const user = userArr.find((user) => user._id === id);
                    const initials = user?.name
                      .split(" ")
                      .map((n) => n[0])
                      .join(""); // Extract initials from name
                    const backgroundColor = generateColorFromString(user?.name); // Get a color based on the name

                    return (
                      <Avatar
                        key={id}
                        sx={{
                          bgcolor: backgroundColor,
                        }}
                      >
                        {initials}
                      </Avatar>
                    );
                  })}
                </AvatarGroup>
                {firstSelectedName}
                {personName.length > 1 && `+ ${personName.length - 1}`}
              </Box>
            );
          }}
          MenuProps={MenuProps}
          sx={{
            cursor: "pointer",
            textAlign: "center",
            maxHeight: "35px",
            fontSize: "12px",
            fontWeight: 500,
            "& .MuiOutlinedInput-notchedOutline": {
              border: "#F5F5F6",
            },
            "&:hover": {
              backgroundColor: "none",
            },
            "& .MuiSelect-icon": {
              display: "none",
            },
            "& .MuiInputBase-input": {
              // Specifically targeting the input element
              paddingX: "8px !important", // Remove any padding here
            },
          }}
        >
          <TextField
            fullWidth
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
            onClickCapture={stopImmediatePropagation}
            onKeyDown={(e) => e.stopPropagation()}
            sx={{
              fontSize: "12px",
              borderRadius: "8px",
              padding: "5px",
              height: "45px", // Adjust the height as needed
              "& .MuiInputBase-root": {
                height: "100%", // Ensures the height is applied to the input element
              },
            }}
          />
          {filteredUsers.map(({ _id, name }) => {
            const initials = name
              .split(" ")
              .map((n) => n[0])
              .join(""); // Extract initials from name
            const backgroundColor = generateColorFromString(name); // Get a color based on the name

            return (
              <MenuItem
                key={_id}
                value={_id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "10px",
                }}
                // disabled={personName.includes(_id)} // Disable already selected options
              >
                <Avatar
                  sx={{
                    width: 20,
                    height: 20,
                    fontSize: "10px",
                    bgcolor: backgroundColor,
                  }}
                >
                  {initials}
                </Avatar>

                <ListItemText
                  primary={name}
                  sx={{
                    fontSize: "12px",
                  }}
                />
                <Checkbox
                  checked={personName.includes(_id)}
                  sx={{ padding: "0px" }}
                />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {/* <CustomTooltip
        title={
          personName?.length > 1 && (
            <div>
              {personName?.slice(1)?.map((id, index) => {
                const userName =
                  userArr.find((user) => user._id === id)?.name || "Unknown";
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "4px",
                    }}
                  >
                    <span
                      style={{
                        width: "8px",
                        height: "8px",
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: "3px",
                        marginRight: "8px",
                      }}
                    ></span>
                    <Typography
                      component="span"
                      sx={{
                        textDecoration: "underline",
                        textUnderlineOffset: "2px",
                      }}
                    >
                      {userName}
                    </Typography>
                  </div>
                );
              })}
            </div>
          )
        }
      >
        <Chip
          label={`+ ${personName.length > 0 ? personName.length - 1 : 0}`}
          sx={{
            borderRadius: "8px",
            backgroundColor:
              personName.length > 1 && theme.palette.primary.main,
            color: personName.length > 1 && "white",
            fontWeight: 500,
          }}
        />
      </CustomTooltip> */}
    </div>
  );
}
