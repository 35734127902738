import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Chrono } from "react-chrono";
import CircleIcon from "@mui/icons-material/Circle";
import moment from "moment";
import { useGetPatientsSurveyQuery } from "../../store/patientsSurveySlice";
import { CircularLoader } from "../Loader";
import AddIcon from "@mui/icons-material/Add";
import { AssignFormsModal } from "../../Pages/Patient/PatientProfile/ProfileModals/FormsModal";

const renderResult = (item) => {
  if (item?.type === "signaturepad" && item?.result) {
    return <img src={item.result} alt="Signature" />;
  } else if (
    item?.type === "multipletext" &&
    typeof item?.result === "object"
  ) {
    return (
      <Box>
        {Object.entries(item.result).map(([key, value], index) => (
          <Typography key={index} sx={{ marginTop: "10px", fontSize: "14px" }}>
            {key}: {value}
          </Typography>
        ))}
      </Box>
    );
  } else {
    return item?.result;
  }
};

const ContentAccordion = ({ data, defaultExpanded }) => {
  return (
    <Accordion
      sx={{ border: "none", boxShadow: "none", width: "100%" }}
      defaultExpanded={defaultExpanded}
    >
      <AccordionSummary aria-controls="panel1-content" id="panel1-header">
        <Box sx={{ width: "100%" }}>
          <Typography variant="body2" sx={{ mb: 1 }} fontSize={12}>
            Survey Form
          </Typography>
          <Typography variant="subtitle2" fontWeight={600} fontSize={16}>
            {data?.title}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: "rgba(2, 136, 209, 0.08)",
          borderRadius: "8px",
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={12}>
            {data?.status !== "pending" &&
              data?.formResult &&
              data?.formResult
                .filter((items) => items?.type != "panel")
                .map((item, index) => (
                  <Grid key={index} item md={12} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          marginRight: "20px",
                          marginBottom: "12px",
                          padding: "6px",
                        }}
                      >
                        <Typography
                          variant="h2"
                          sx={{ fontSize: "16px" }}
                          fontWeight={600}
                        >
                          Q{index + 1}
                        </Typography>
                      </Box>
                      <Box sx={{ marginTop: "20px" }}>
                        <Typography
                          variant="h2"
                          sx={{ fontSize: "16px" }}
                          fontWeight={600}
                        >
                          {item?.title}
                        </Typography>
                        <Typography
                          sx={{ marginTop: "10px", fontSize: "16px" }}
                        >
                          {Array.isArray(item?.result)
                            ? item?.result.map((val, i) =>
                                item.type == "checkbox" ? (
                                  <Grid item md={12} xs={12}>
                                    <Divider />
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          marginRight: "20px",
                                          marginBottom: "12px",
                                          padding: "6px",
                                        }}
                                      ></Box>
                                      <Box sx={{ marginTop: "20px" }}>
                                        <Typography
                                          sx={{
                                            marginTop: "10px",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {i + 1}. {val}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Grid>
                                ) : (
                                  Object.keys(val).map((key, indexes) => (
                                    <Grid item md={12} xs={12}>
                                      <Divider />
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            marginRight: "20px",
                                            marginBottom: "12px",
                                            padding: "6px",
                                          }}
                                        >
                                          <Typography
                                            variant="h2"
                                            sx={{ fontSize: "16px" }}
                                          >
                                            Q{indexes + 1}
                                          </Typography>
                                        </Box>
                                        <Box sx={{ marginTop: "20px" }}>
                                          <Typography
                                            variant="h2"
                                            sx={{ fontSize: "16px" }}
                                          >
                                            {key}
                                          </Typography>
                                          <Typography
                                            sx={{
                                              marginTop: "10px",
                                              fontSize: "16px",
                                            }}
                                          >
                                            {val[key]}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  ))
                                ),
                              )
                            : renderResult(item)}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider sx={{ mt: 1 }} />
                  </Grid>
                ))}

            {data?.status === "pending" && (
              <Typography>
                Survey is currently pending. Results will be available once the
                survey is completed
              </Typography>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const customContent = (dummyCallData) => {
  return dummyCallData.map((data, index) => (
    <Box key={index} sx={{ width: "-webkit-fill-available" }}>
      <ContentAccordion data={data} defaultExpanded={index === 0} />
    </Box>
  ));
};

const CustomTitle = ({ dateTime }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="subtitle" color="textSecondary" fontSize={"14px"}>
        {moment(dateTime).format("DD-MMM-YY")}
      </Typography>
      <Typography variant="body2" color="textSecondary" fontSize={"12px"}>
        {moment(dateTime).format("h:mm A")}
      </Typography>
    </Box>
  );
};

export default function FormTab({
  id,
  user,
  status,
  patientName,
  queueData,
  queueStatus,
  refetchAgain,
  refetchCount,
  refetchQueueAgain,
  setOpenChat,
  name,
  isFullScreen,
}) {
  const [open, setOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [timelineItems, setTimelineItems] = useState([]);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { isLoading, data, refetch } = useGetPatientsSurveyQuery({
    userID: user.id,
    id,
    page: 1,
    limit,
  });

  const handleViewMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    setLimit((prevLimit) => prevLimit + 10);
    refetch();
  };

  const fetchAgain = () => {
    refetch();
  };

  const handleModal = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (data?.data) {
      const timelineDataReturn = data?.data?.map((item) => {
        return { title: <CustomTitle dateTime={item?.createdAt} /> };
      });
      setTimelineItems(timelineDataReturn);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Box>
      {!isFullScreen && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Forms</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => handleModal()}
            >
              Assign
            </Button>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          height: "67vh",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
        className="DialogContent"
      >
        {data?.data && data?.data?.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography variant="h6">No Forms Record Found"</Typography>
          </Box>
        ) : (
          <>
            <Box>
              <Chrono
                key={timelineItems?.length}
                items={timelineItems}
                hideControls
                disableClickOnCircle
                mode="VERTICAL"
                disableAutoScrollOnClick={true}
                scrollable={false}
                cardHeight={100}
                theme={{ primary: "#0049C6", secondary: "#f5f5f5" }}
                cardPosition="top"
              >
                {customContent(data?.data ? data?.data : [])}

                <Box className="chrono-icons">
                  {timelineItems.map((item, index) => (
                    <CircleIcon
                      key={index}
                      sx={{ color: "#0049C6", fontSize: "20px" }}
                    />
                  ))}
                </Box>
              </Chrono>
            </Box>
            {currentPage < data?.totalPages && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flexStart",
                  marginTop: "16px",
                  marginLeft: "20%",
                }}
              >
                <Button variant="contained" onClick={handleViewMore}>
                  View Old Forms
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>

      <AssignFormsModal
        {...{ open, setOpen, pid: id, fetchAgain, status, patientName }}
      />
    </Box>
  );
}
