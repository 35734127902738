import React, { useState } from "react";
import {
  Typography,
  Stack,
  Grid,
  Divider,
  Tooltip,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";
import { useIsAuthenticatedUser } from "../../contexts/isAuthenticatedUser";
import ReplayIcon from "@mui/icons-material/Replay";
import { ConfirmationModal } from "../ConfirmationModal";
import { useResendConsentMutation } from "../../store/chatSlice";
import { toast } from "react-toastify";
import { LaunchRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";

const Header = ({
  name,
  path,
  acuity,
  patient,
  value,
  careAllyData,
  id,
  refetchChat,
  status,
  consent,
  title,
}) => {
  const { user } = useIsAuthenticatedUser();
  // States and Functions for Message Drawer
  const [open, setOpen] = React.useState(false);

  const [resendConsent] = useResendConsentMutation();
  const navigate = useNavigate();

  const getColorStyles = (acuity) => {
    if (acuity === "high") {
      return {
        marginRight: "5px",
        color: "#DC362E",
      };
    } else if (acuity === "medium") {
      return {
        marginRight: "5px",
        color: "#FFB400",
      };
    } else if (acuity === "N/A") {
      return {
        marginRight: "5px",
        color: "black",
      };
    } else if (acuity === "low") {
      return {
        marginRight: "5px",
        color: "#63A02C",
      };
    } else {
      return {
        marginRight: "5px",
        color: "gray",
      };
    }
  };
  const getAge = (birthDate) => {
    if (birthDate) {
      const age = Math.floor(
        (new Date() - new Date(birthDate).getTime()) / 3.15576e10,
      );
      return `${age}Y`;
    } else {
      return "No DOB Available";
    }
  };

  const getConsentColors = (consent) => {
    if (consent === "yes") {
      return {
        marginRight: "5px",
        color: "#63A02C",
      };
    } else if (consent === "no") {
      return {
        marginRight: "5px",
        color: "#DC362E",
      };
    } else {
      return {
        marginRight: "5px",
        color: "#FFB400",
      };
    }
  };
  const [openResend, setOpenResend] = useState(false);
  const handleResend = (event) => {
    event.stopPropagation();
    setOpenResend(true);
  };
  const getSmsConsent = (data) => {
    return data?.consentResponse === "NO_RESPONSE"
      ? "No Response"
      : data?.consentResponse?.charAt(0).toUpperCase() +
          data?.consentResponse?.slice(1);
  };

  const handleDataResend = async () => {
    if (status === "active") {
      const response = await resendConsent(id);
      if (response?.data?.message) {
        toast.success("The SMS consent has been resent.");
        setOpenResend(false);
        refetchChat();
      } else {
        toast.error("Something went wrong!");
      }
    } else {
      toast.error("The patient status is currently inactive.");
    }
  };
  const goToProfile = (id, name) => {
    localStorage.setItem("pageName", JSON.stringify(name));
    navigate(`/profile/${id}`, { state: { action: "read-only", id } });
  };

  return (
    <>
      <Accordion
        sx={{
          bgcolor: "rgba(0, 151 ,236 ,0.09)",
          p: 0,
          borderRadius: "12px",
          "&:before": {
            display: "none", // Removes the default top line
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
            <Grid
              container
              spacing={2}
              style={{
                paddingLeft: "5px",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Grid item md={2} xs={2}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  >
                    {careAllyData && value === 2
                      ? careAllyData?.firstName || careAllyData?.lastName
                        ? `${careAllyData?.firstName} ${careAllyData?.lastName}`
                        : "N/A"
                      : name}
                  </Typography>
                  <Tooltip title="Go to Profile">
                    <IconButton
                      sx={{ ml: "3px" }}
                      size="small"
                      onClick={() => {
                        goToProfile(patient?._id, "patient");
                      }}
                    >
                      <LaunchRounded fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#50607A",
                      pr: 1,
                    }}
                  >
                    {careAllyData && value === 2
                      ? careAllyData?.relationship || "N/A"
                      : `${getAge(patient?.birthday)}, ${patient?.gender?.charAt(0)?.toUpperCase() || "N/A"}`}
                  </Typography>
                </div>
              </Grid>

              <Grid item md={2} xs={2}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#50607A",
                    }}
                  >
                    Acuity
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        ...getColorStyles(acuity),
                        display: "inline-block", // Ensures the dot behaves like an element, not text
                        width: "8px", // Set the diameter of the circle
                        height: "8px", // Keep width and height equal
                        borderRadius: "50%", // Makes the shape circular
                        backgroundColor: "currentColor", // Use the current font color from getColorStyles
                      }}
                    ></span>

                    <Typography fontSize={14} color="black">
                      {acuity
                        ? acuity?.charAt(0).toUpperCase() + acuity?.slice(1)
                        : "No Acuity"}{" "}
                    </Typography>
                  </Box>
                </div>
              </Grid>

              {/* <Divider orientation="vertical" flexItem /> */}
              <Grid item md={3} xs={3}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#50607A",
                    }}
                  >
                    Contact
                  </Typography>

                  <Typography
                    variant="p"
                    sx={{
                      fontSize: 12,
                      fontWeight: 500,
                    }}
                  >
                    {careAllyData && value === 2
                      ? careAllyData?.phoneNumber
                      : patient?.phone}{" "}
                    |{" "}
                    {(value !== 2 || !careAllyData) &&
                      (patient?.email || "No Email Provided")}
                  </Typography>
                </div>
              </Grid>

              <Grid item md={2} xs={2}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#50607A",
                    }}
                  >
                    Location
                  </Typography>
                  <Typography fontSize={14} color="black">
                    {patient?.state ? patient?.state : "N/A"}
                  </Typography>
                </div>
              </Grid>

              {/* <Divider orientation="vertical" flexItem /> */}
              <Grid item md={2} xs={2}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: "#50607A",
                      mb: value !== 2 && consent !== "yes" ? "-8px" : "-6px",
                    }}
                  >
                    SMS Consent
                    {value !== 2 && consent !== "yes" && (
                      <Tooltip title="Resend SMS Consent">
                        <IconButton onClick={handleResend}>
                          <ReplayIcon sx={{ width: "20px", height: "20px" }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <>
                      <span
                        style={{
                          ...(careAllyData && value === 2
                            ? getConsentColors(careAllyData?.consentResponse)
                            : getConsentColors(patient?.consentResponse)),
                          display: "inline-block", // Ensures the dot behaves like an element, not text
                          width: "8px", // Set the diameter of the circle
                          height: "8px", // Keep width and height equal
                          borderRadius: "50%", // Makes the shape circular
                          backgroundColor: "currentColor", // Use the current font color from getColorStyles
                        }}
                      ></span>

                      <Typography fontSize={14} color="black">
                        {careAllyData && value === 2
                          ? getSmsConsent(careAllyData)
                          : getSmsConsent(patient)}
                      </Typography>
                    </>
                  </div>
                </div>
              </Grid>
              {/* <Grid item md={2}>
          <CallOutlinedIcon />
        </Grid> */}
            </Grid>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              borderRadius: "8px",
              backgroundColor: "rgba(0, 151 ,236 ,0.1)",
              padding: "16px",
            }}
          >
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      borderRight: "1px solid gray",
                    }}
                  >
                    Recent Procedure Information :
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                    Surgeon Name
                  </Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                    {patient?.surgeonName
                      ? patient?.surgeonName
                      : patient?.surgery?.surgeon?.name
                        ? patient?.surgery?.surgeon?.name
                        : "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                    Procedure Date
                  </Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                    {patient.surgeryDate
                      ? moment(patient.surgeryDate).format("YYYY-MM-DD")
                      : patient?.surgery?.surgeryDate
                        ? moment(patient?.surgery?.surgeryDate).format(
                            "YYYY-MM-DD",
                          )
                        : "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                    Procedure Type
                  </Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                    {patient.surgeryType
                      ? patient?.surgeryType
                      : patient?.surgery?.surgeryType
                        ? patient?.surgery?.surgeryType
                        : "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ marginY: 2 }}></Divider>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 600,
                      borderRight: "1px solid gray",
                    }}
                  >
                    Other Demographic Information :
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                    DOB
                  </Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                    {patient.birthday
                      ? moment(patient.birthday).format("YYYY-MM-DD")
                      : patient.birthday}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                    Ethnicity
                  </Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                    {patient.ethnicity ? patient.ethnicity : "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                    Race
                  </Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                    {patient.race ? patient.race : "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                    Language
                  </Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                    {patient.language ? patient.language : "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <ConfirmationModal
        open={openResend}
        setOpen={setOpenResend}
        ConfirmationText="Are you sure you want to resend the SMS consent?"
        handle={handleDataResend}
      />
    </>
  );
};

export default Header;
