import CloseIcon from "@mui/icons-material/Close";
import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SolidButton } from "../../Components";
import { useAddNewUserMutation } from "../../store/userManagmentSlice";
import { formatPhone } from "../../utils";
import { userDesignation } from "../../constants";

export function AddUser({ toggleDrawer }) {
  const { user } = useSelector((state) => state.user);
  const [role, setRole] = useState(1111);
  const [isValidNumber, setIsValidNumber] = useState(true);
  const [disbaleInvite, setDisbaleInvite] = useState(false);
  const [designation, setDesignation] = useState(userDesignation.CARE_COORDINATOR)
  const [form, setForm] = useState({
    name: "",
    contact: "",
    email: "",
    password: "",
    confirmPassword: ""
  });

  const [addUser, { isSuccess }] = useAddNewUserMutation();

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const onChangeDesignation = (event) => {
    setDesignation(event.target.value)
  }

  const handleChangeNumber = (newValue) => {
    matchIsValidTel(newValue); // boolean
    setIsValidNumber(newValue ? matchIsValidTel(newValue) : true);
  };

  const onChange = (e) => {
    setForm((form) => ({ ...form, [e.target.name]: e.target.value }));
  };

  const onSave = async () => {
    if (isValidNumber === true) {
      setDisbaleInvite(true);
      const formattedPhone = form?.contact ? formatPhone(form?.contact) : "";
      await addUser({
        ...form,
        contact: formattedPhone,
        role,
        designation: designation.toLowerCase(),
        creator: user.id,
      })
        .unwrap()
        .then(() => toast.success("Successfully created!"))
        .catch((e) => {
          toast.error(e?.data?.error || e?.message || "some error occured!");
        });
      setDisbaleInvite(false);
    } else {
      toast.error("The Number is not valid!");
    }
  };

  if (isSuccess) {
    toggleDrawer(false);
  }

  return (
    <>
      <Box sx={{ padding: "2rem" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: "3rem" }}
        >
          <Typography variant="p" sx={{ fontSize: 30, fontWeight: 400 }}>
            Create Role
          </Typography>
          <Box sx={{ cursor: "pointer" }} onClick={() => toggleDrawer(false)}>
            <CloseIcon />
          </Box>
        </Stack>
        <Box>
          <Grid container={true} spacing={2}>
            <Grid item={true} md={6} xs={12}>
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold" }}
                gutterBottom={true}
              >
                Enter Name
              </Typography>
              <TextField
                required={true}
                id="outlined-required"
                name="name"
                value={form.name}
                onChange={onChange}
                placeholder="Enter Name"
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item={true} md={6} xs={12}>
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold" }}
                gutterBottom={true}
              >
                Enter Email
              </Typography>
              <TextField
                required={true}
                id="outlined-required"
                name="email"
                value={form.email}
                onChange={onChange}
                placeholder="Enter Email"
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item={true} md={6} xs={12}>
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold" }}
                gutterBottom={true}
              >
                Contact Number
              </Typography>
              <MuiTelInput
                required={true}
                id="outlined-required"
                name="contact"
                value={form.contact}
                onChange={(newValue) => {
                  const e = { target: { name: "contact", value: newValue } };
                  onChange(e);
                  handleChangeNumber(newValue);
                }}
                onlyCountries={["US"]}
                error={isValidNumber === false}
                helperText={isValidNumber === false && "Incorrect entry"}
                placeholder="Contact Number"
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item={true} md={6} xs={12}>
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold" }}
                gutterBottom={true}
              >
                User Role
              </Typography>
              <Select
                value={role}
                sx={{ width: "100%" }}
                onChange={handleChange}
                displayEmpty={true}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={2615}>Client Admin</MenuItem>
                <MenuItem value={1122}>Care team members</MenuItem>
                <MenuItem value={1111}>Reporting Users</MenuItem>
              </Select>
            </Grid>
            <Grid item={true} md={6} xs={12}>
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold" }}
                gutterBottom={true}
              >
                Designation
              </Typography>
              <Select
                value={designation}
                sx={{ width: "100%" }}
                onChange={onChangeDesignation}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={userDesignation.CARE_COORDINATOR}>{userDesignation.CARE_COORDINATOR}</MenuItem>
                <MenuItem value={userDesignation.PHYSICIAN}>{userDesignation.PHYSICIAN}</MenuItem>
                <MenuItem value={userDesignation.SURGEON}>{userDesignation.SURGEON}</MenuItem>
                <MenuItem value={userDesignation.NURSE}>{userDesignation.NURSE}</MenuItem>
              </Select>
            </Grid>
            <Grid item={true} xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <SolidButton
                  label={
                    disbaleInvite ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Invite"
                    )
                  }
                  disabled={disbaleInvite}
                  onClick={onSave}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
