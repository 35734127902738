import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Chrono } from "react-chrono";
import CircleIcon from "@mui/icons-material/Circle";

const customContent = (dummyCallData) => {
  return dummyCallData.map((data, index) => (
    <Box key={index} sx={{ width: "-webkit-fill-available" }}>
      <Grid container spacing={2} mt={0}>
        <Grid item xs={12} sm={3}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Title
          </Typography>
          <Typography variant="body2 ">{data?.title}</Typography>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Description
          </Typography>
          <Typography variant="body2">{data?.taskId?.description}</Typography>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Due Date
          </Typography>
          <Typography variant="body2">
            {moment(data?.date)?.format("MMMM DD, YYYY")}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Status
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                display: "inline-block", // Ensures the dot behaves like an element, not text
                width: "10px", // Set the diameter of the circle
                height: "10px", // Keep width and height equal
                borderRadius: "50%", // Makes the shape circular
                backgroundColor:
                  data?.status === "completed" ? "#63A02C" : "#FFB400", // Use the current font color from getColorStyles
              }}
            ></span>

            <Typography fontSize={14} color="black" marginLeft={"6px"}>
              {data?.status?.charAt(0).toUpperCase() + data?.status?.slice(1)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  ));
};

const CustomTitle = ({ dateTime }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="subtitle" color="textSecondary" fontSize={"14px"}>
        {moment(dateTime).format("DD-MMM-YY")}
      </Typography>
      <Typography variant="body2" color="textSecondary" fontSize={"12px"}>
        {moment(dateTime).format("h:mm A")}
      </Typography>
    </Box>
  );
};

const TaskSection = ({ data, isFullScreen }) => {
  const [timelineItems, setTimelineItems] = useState([]);

  useEffect(() => {
    if (data) {
      const timelineDataReturn = data?.map((item) => {
        return { title: <CustomTitle dateTime={item?.createdAt} /> };
      });
      setTimelineItems(timelineDataReturn);
    }
  }, [data]);

  return (
    <Box>
      <Box
        sx={{
          overflowY: "scroll",
          overflowX: "hidden",
          height: isFullScreen ? "100%" : "25vh",
        }}
        className="DialogContent"
      >
        {data?.length !== 0 ? (
          <>
            <Box>
              <Chrono
                key={timelineItems?.length}
                items={timelineItems}
                hideControls
                disableClickOnCircle
                mode="VERTICAL"
                disableAutoScrollOnClick={true}
                scrollable={false}
                cardHeight={100}
                theme={{ primary: "#0049C6", secondary: "#f5f5f5" }}
                cardPosition="top"
              >
                {customContent(data ? data : [])}

                <Box className="chrono-icons">
                  {timelineItems.map((item, index) => (
                    <CircleIcon
                      key={index}
                      sx={{ color: "#0049C6", fontSize: "20px" }}
                    />
                  ))}
                </Box>
              </Chrono>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Typography variant="h6">No Task Found</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TaskSection;
