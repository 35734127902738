import CircleIcon from "@mui/icons-material/Circle";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Chrono } from "react-chrono";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import DialogueAccordion from "../../Pages/patientQueue/DialogueAccordion";

const ContentAccordion = ({ item, defaultExpanded }) => {
  const getColorStyles = (acuity) => {
    if (acuity === "high") {
      return {
        marginRight: "5px",
        color: "#DC362E",
      };
    } else if (acuity === "medium") {
      return {
        marginRight: "5px",
        color: "#FFB400",
      };
    } else if (acuity === "N/A") {
      return {
        marginRight: "5px",
        color: "black",
      };
    } else {
      return {
        marginRight: "5px",
        color: "#63A02C",
      };
    }
  };
  return (
    <Accordion
      sx={{ border: "none", boxShadow: "none", flexGrow: 1 }}
      defaultExpanded={defaultExpanded}
    >
      <AccordionSummary aria-controls="panel1-content" id="panel1-header">
        <Grid container spacing={2} alignItems={"start"}>
          <Grid item xs={12} sm={5}>
            <Typography variant="subtitle2">Dialogue Intent</Typography>
            <Typography variant="body2">
              {item?.dialogueIntent || "No Intent"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">Task Category</Typography>
            <Typography variant="body2">{item?.taskCategory}</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle2">Acuity</Typography>
            <Typography
              variant="body2"
              sx={{ display: "flex", alignItems: "center" }}
            >
              {" "}
              <span
                style={{ ...getColorStyles(item?.acuity), fontSize: "24px" }}
              >
                •
              </span>{" "}
              {item?.acuity?.charAt(0).toUpperCase() + item?.acuity?.slice(1)}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Divider
          sx={{ background: "#ffffff", width: "100%", marginBottom: "20px" }}
        />

        <Grid container spacing={2}>
          {item?.dialogueChat?.length !== 0 && (
            <Grid item xs={12} sm={12}>
              <DialogueAccordion title={"QnA"} chatData={item?.dialogueChat} />
            </Grid>
          )}

          <Grid item xs={12} sm={12}>
            <Typography variant="h6" fontSize={"16px"}>
              Dialogue Summary
            </Typography>
            <Typography>
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  a: (props) => (
                    <a
                      href={props.href}
                      style={{ color: "#000" }}
                      target="blank"
                    >
                      {props.children}
                    </a>
                  ),
                }}
              >
                {item?.dialogueSummary
                  ? item?.dialogueSummary
                      ?.replace(/^```|```$/g, "")
                      ?.replace(/\n/g, "  \n")
                  : "No Summary Available"}
              </ReactMarkdown>
            </Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const customContent = (dummyDialougeData) => {
  return dummyDialougeData?.map((data, index) => (
    <Box key={index} sx={{ display: "flex", width: "100%" }}>
      <ContentAccordion item={data} defaultExpanded={index === 0} />
    </Box>
  ));
};

const CustomTitle = ({ dateTime }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="subtitle" color="textSecondary" fontSize={"14px"}>
        {moment(dateTime).format("DD-MMM-YY")}
      </Typography>
      <Typography variant="body2" color="textSecondary" fontSize={"12px"}>
        {moment(dateTime).format("h:mm A")}
      </Typography>
    </Box>
  );
};
export default function DialogueTab({ queueData }) {
  const [timelineItems, setTimelineItems] = useState([]);
  useEffect(() => {
    if (queueData) {
      const timelineDataReturn = queueData?.map((item) => {
        return { title: <CustomTitle dateTime={item?.createdAt} /> };
      });
      setTimelineItems(timelineDataReturn);
    }
  }, []);
  return (
    <Box
      sx={{
        height: "70vh",
        overflowY: "hidden",
        overflowX: "hidden",
      }}
      className="DialogContent"
    >
      {queueData && queueData?.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Typography variant="h6">No Record Found</Typography>
        </Box>
      ) : (
        <Chrono
          key={timelineItems?.length}
          items={timelineItems}
          hideControls
          disableClickOnCircle
          mode="VERTICAL"
          disableAutoScrollOnClick={true}
          scrollable={false}
          cardHeight={100}
          theme={{ primary: "#0049C6", secondary: "#f5f5f5" }}
          cardPosition="top"
        >
          {customContent(queueData || [])}

          <Box className="chrono-icons">
            {timelineItems.map((item, index) => (
              <CircleIcon
                key={index}
                sx={{ color: "#0049C6", fontSize: "20px" }}
              />
            ))}
          </Box>
        </Chrono>
      )}
    </Box>
  );
}
