import React, { useState } from "react";
import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useIsMobile } from "../../contexts/isMobile";
import { useIsHovered } from "../../contexts/isHovered";
import SelectField from "../Dashboard/FilterUtils/SelectField";
import { useChildNode } from "../../contexts/addChildNode";
import { addInParams } from "../../utils";

export function FilterSidebar({
  data,
  queriesValue,
  setQueriesValue,
  setPage,
  setSortField,
  setSortOrder,
  setSortPatientOrder,
  setSelectedValue,
}) {
  const isMobile = useIsMobile();
  const { hovered } = useIsHovered();
  const { setSearchTerm, setSearchPatientTerm } = useChildNode();
  const [careProgramValue, setCareProgramValue] = useState(
    localStorage?.getItem("program")
      ? JSON.parse(localStorage?.getItem("program"))
      : [],
  );
  const [selectedPatientStatus, setSelectedPatientStatus] = useState(
    localStorage?.getItem("selectedV2PatientStatus")
      ? localStorage?.getItem("selectedV2PatientStatus")
      : "active",
  );
  const [selectedSmsStatus, setSelectedSmsStatus] = useState(
    localStorage?.getItem("selectedSmsStatus")
      ? localStorage?.getItem("selectedSmsStatus")
      : null,
  );
  const [selectedEnrolledStatus, setSelectedEnrolledStatus] = useState(
    localStorage?.getItem("selectedEnrolledStatus")
      ? localStorage?.getItem("selectedEnrolledStatus")
      : null,
  );
  const [surgeryTypesValue, setSurgeryTypesValue] = useState(
    localStorage?.getItem("surgeryTypes")
      ? JSON.parse(localStorage?.getItem("surgeryTypes"))
      : [],
  );
  const [surgeonNamesValue, setSurgeonNamesValue] = useState(
    localStorage?.getItem("surgeonNames")
      ? JSON.parse(localStorage?.getItem("surgeonNames"))
      : [],
  );

  const handleDelete = (label, value) => {
    let updatedState;

    if (label === "program") {
      updatedState = careProgramValue.some((item) => item.value === value.value)
        ? careProgramValue.filter((item) => item.value !== value.value) // Remove if value exists
        : careProgramValue;
      setCareProgramValue(updatedState);
      localStorage.removeItem(label);
      addInParams(label, updatedState.map((task) => task.value).join(","));
    }

    if (label === "surgeonNames") {
      updatedState = surgeonNamesValue.some((item) => item.id === value.id)
        ? surgeonNamesValue.filter((item) => item.id !== value.id) // Remove if value exists
        : surgeonNamesValue;
      setSurgeonNamesValue(updatedState);
      localStorage.removeItem(label);
      addInParams(label, updatedState.map((task) => task.id).join(","));
    }

    if (label === "surgeryTypes") {
      updatedState = surgeryTypesValue.some((item) => item.id === value.id)
        ? surgeryTypesValue.filter((item) => item.id !== value.id) // Remove if value exists
        : surgeryTypesValue;
      setSurgeryTypesValue(updatedState);
      localStorage.removeItem(label);
      addInParams(label, updatedState.map((task) => task.id).join(","));
    }

    const filteredObject = {
      ...queriesValue,
      [label]: updatedState,
    };
    // Set the queries value using the filtered object
    setQueriesValue(filteredObject);

    localStorage.setItem("queriesValue2", JSON.stringify(filteredObject));
  };

  const handleChangPatientStatus = (event, item, key) => {
    const { checked } = event.target;
    const filteredObject = { ...queriesValue };

    filteredObject["status"] = item.value;

    setSelectedPatientStatus(item?.value);
    setQueriesValue(filteredObject);
    addInParams("status", item?.value);

    localStorage.setItem("selectedV2PatientStatus", item?.value);
    localStorage.setItem("queriesValue2", JSON.stringify(filteredObject));
  };

  const handleChangSmsStatus = (event, item, key) => {
    const { checked } = event.target;
    const filteredObject = { ...queriesValue };

    filteredObject["consentApproved"] = item.value;

    setSelectedSmsStatus(item?.value);
    setQueriesValue(filteredObject);
    addInParams("consentApproved", item?.value);

    localStorage.setItem("selectedSmsStatus", item?.value);
    localStorage.setItem("queriesValue2", JSON.stringify(filteredObject));
  };

  const handleChangEnrolledStatus = (event, item, key) => {
    const { checked } = event.target;
    const filteredObject = { ...queriesValue };

    filteredObject["selfEnrolled"] = item.value;

    setSelectedEnrolledStatus(item?.value);
    setQueriesValue(filteredObject);
    addInParams("selfEnrolled", item?.value);

    localStorage.setItem("selectedEnrolledStatus", item?.value);
    localStorage.setItem("queriesValue2", JSON.stringify(filteredObject));
  };

  const handleChange = (event, fieldKey) => {
    const { value } = event.target;
    let updatedState;

    if (fieldKey === "program") {
      updatedState = careProgramValue.some((item) => item.value === value.value)
        ? careProgramValue
        : [...careProgramValue, value];
      setCareProgramValue(updatedState);
      addInParams(fieldKey, updatedState.map((task) => task.value).join(","));
    }

    if (fieldKey === "surgeryTypes") {
      updatedState = surgeryTypesValue.some((item) => item.id === value.id)
        ? surgeryTypesValue
        : [...surgeryTypesValue, value];
      setSurgeryTypesValue(updatedState);
      addInParams(fieldKey, updatedState.map((task) => task.id).join(","));
    }

    if (fieldKey === "surgeonNames") {
      updatedState = surgeonNamesValue.some((item) => item.id === value.id)
        ? surgeonNamesValue
        : [...surgeonNamesValue, value];
      setSurgeonNamesValue(updatedState);
      addInParams(fieldKey, updatedState.map((task) => task.id).join(","));
    }

    const filteredObject = {
      ...queriesValue,
      [fieldKey]: updatedState,
    };
    // Set the queries value using the filtered object
    setQueriesValue(filteredObject);
    localStorage.setItem("queriesValue2", JSON.stringify(filteredObject));
    localStorage.setItem(fieldKey, JSON.stringify(updatedState));
    localStorage.setItem("page3", 1);
    addInParams("page", 1);
    setPage(1);
  };

  const handleClear = () => {
    // Reset states to their default values
    setSearchTerm("");
    setSearchPatientTerm("");
    setCareProgramValue([]);
    setSelectedPatientStatus("active");
    setSelectedSmsStatus(null);
    setSelectedEnrolledStatus(null);
    setQueriesValue({});
    setPage(1);
    setSortField("createdAt");
    setSortOrder("desc");
    setSortPatientOrder({ field: "patientName", sort: "asc" });
    setSurgeonNamesValue([]);
    setSurgeryTypesValue([]);
    setSelectedValue("asc");

    localStorage.removeItem("program");
    localStorage.removeItem("surgeonNames");
    localStorage.removeItem("surgeryTypes");
    localStorage.removeItem("selectedV2PatientStatus");
    localStorage.removeItem("selectedSmsStatus");
    localStorage.removeItem("selectedEnrolledStatus");
    localStorage.removeItem("queriesValue");
    localStorage.setItem("page3", 1);
    localStorage.removeItem("queriesValue2");
    localStorage.removeItem("sortField3");
    localStorage.removeItem("sortOrder3");
    localStorage.removeItem("sortPatientOrder3");
    localStorage.removeItem("selectedValue");

    const url = new URL(window.location);

    // Clear all existing query parameters
    url.search = ""; // This removes all query parameters

    // Set specific query parameters
    url.searchParams.set("page", "1");
    url.searchParams.set("status", "active");

    // Update the URL without reloading the page
    window.history.replaceState({}, "", url);
  };

  React.useEffect(() => {
    if (queriesValue?.program && queriesValue?.program?.length !== 0) {
      setCareProgramValue(queriesValue?.program);
      localStorage.setItem("program", JSON.stringify(queriesValue?.program));
    }

    if (
      queriesValue?.surgeonNames &&
      queriesValue?.surgeonNames?.length !== 0
    ) {
      setSurgeonNamesValue(queriesValue?.surgeonNames);
      localStorage.setItem(
        "surgeonNames",
        JSON.stringify(queriesValue?.surgeonNames),
      );
    }
  }, [queriesValue]);

  return (
    <>
      <Stack
        direction="column"
        justifyContent="left"
        sx={{
          height: "100%",
          alignItems: isMobile ? "baseline" : "left",
          position: "fixed",
          bgcolor: "white",
          paddingLeft: !hovered && "12px",
          mt: "1.5rem",
          pl: "1.2rem",
          width: "15%",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            display: "none", // Hide scrollbar for WebKit browsers
          },
          scrollbarWidth: "none",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              textTransform: "uppercase",
              fontWeight: "600",
              mt: "20px",
            }}
          >
            Filters
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="outlined"
              sx={{ mt: "20px", mr: "10px" }}
              size="small"
              onClick={() => handleClear()}
            >
              Clear
            </Button>
            {/* <Button variant="contained" sx={{ mt: "20px" }} size="small">
              Save
            </Button> */}
          </Box>
        </Box>

        {/* Saved Filters */}
        {/* <Box sx={{ padding: "10px", mt: "10px" }}>
          <SavedFilterAccordion />
        </Box> */}

        {/*  Care Program */}
        <Box sx={{ mt: "10px" }}>
          <Typography>Care Pathways</Typography>

          <SelectField
            label="Select Type"
            showValue={careProgramValue}
            setShowValue={setCareProgramValue}
            data={data["program"]?.value}
            onClick={handleChange}
            fieldKey={"program"}
          />

          {careProgramValue?.map((item) => (
            <Chip
              label={item?.title}
              variant="outlined"
              onDelete={() => handleDelete("program", item)}
              sx={{ mr: "3px" }}
            />
          ))}
        </Box>

        {/* Status */}
        <Box sx={{ mt: "10px" }}>
          <Typography>Patient Status</Typography>
          {data["patientStatus"]?.value?.map((item, index) => (
            <RadioGroup
              aria-label="status"
              name="status"
              value={selectedPatientStatus}
              onChange={(event) =>
                handleChangPatientStatus(event, item, "status")
              }
              sx={{ px: 2 }}
            >
              <FormControlLabel
                key={index}
                value={item.value}
                control={<Radio />}
                label={item?.title}
              />
            </RadioGroup>
          ))}
        </Box>

        {/* Status */}
        <Box sx={{ mt: "10px" }}>
          <Typography>SMS Status</Typography>
          {data["consentApproved"]?.value?.map((item, index) => (
            <RadioGroup
              aria-label="consentApproved"
              name="consentApproved"
              value={selectedSmsStatus}
              onChange={(event) =>
                handleChangSmsStatus(event, item, "consentApproved")
              }
              sx={{ px: 2 }}
            >
              <FormControlLabel
                key={index}
                value={item.value}
                control={<Radio />}
                label={item?.title}
              />
            </RadioGroup>
          ))}
        </Box>

        {/* Status */}
        <Box sx={{ mt: "10px" }}>
          <Typography>Self Enrolled</Typography>
          {data["selfEnrolled"]?.value?.map((item, index) => (
            <RadioGroup
              aria-label="selfEnrolled"
              name="selfEnrolled"
              value={selectedEnrolledStatus}
              onChange={(event) =>
                handleChangEnrolledStatus(event, item, "selfEnrolled")
              }
              sx={{ px: 2 }}
            >
              <FormControlLabel
                key={index}
                value={item.value}
                control={<Radio />}
                label={item?.title}
              />
            </RadioGroup>
          ))}
        </Box>
        {/*  Procedure */}
        <Box sx={{ mt: "10px" }}>
          <Typography>Procedure Type</Typography>

          <SelectField
            label="Select Type"
            showValue={surgeryTypesValue}
            setShowValue={setSurgeryTypesValue}
            data={data["surgeryType"]?.value}
            onClick={handleChange}
            fieldKey={"surgeryTypes"}
          />

          {surgeryTypesValue?.map((item) => (
            <Chip
              label={item?.title}
              variant="outlined"
              onDelete={() => handleDelete("surgeryTypes", item)}
              sx={{ mr: "3px" }}
            />
          ))}
        </Box>

        {/*  Physician Name */}
        <Box sx={{ mt: "10px", mb: "4rem" }}>
          <Typography>Surgeon Name</Typography>

          <SelectField
            label="Select Type"
            showValue={surgeonNamesValue}
            setShowValue={setSurgeonNamesValue}
            data={data["surgeonName"]?.value}
            onClick={handleChange}
            fieldKey={"surgeonNames"}
          />

          {surgeonNamesValue?.map((item) => (
            <Chip
              label={item?.title}
              variant="outlined"
              onDelete={() => handleDelete("surgeonNames", item)}
              sx={{ mr: "3px" }}
            />
          ))}
        </Box>
      </Stack>
    </>
  );
}
