import {
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetPatientBundleByIDQuery } from "../store/bundlingsSlice";
import { CircularLoader } from "./Loader";
import { toast } from "react-toastify";

const ReportedOutcomesCard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { id } = useParams();
  const { data, error, isLoading } = useGetPatientBundleByIDQuery(id);

  const GeneratePendingLink = (formId, itemId) => {
    try {
      const pendingUrl = `${process.env.REACT_APP_FRONTEND_URI}/showPatientSurvey/${formId}/${itemId}`;
      window.open(pendingUrl, "_blank");
      toast.success("Link opened in a new tab!");
    } catch (error) {
      toast.error("Failed to open link. Please try again.");
    }
  };

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        maxWidth: 700,
        flexGrow: 1,
        p: 2,
        maxHeight: "100%",
        overflow: "hidden",
      }}
      elevation={0}
    >
      {data?.data?.bundleId?.videoLink && (
        <Box
          sx={{
            width: "100%",
            height: "40%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          <video
            controls
            width="100%"
            height="100%"
            style={{
              objectFit: "contain",
              borderRadius: "16px",
              border: "1px solid rgb(0, 0, 0, 0.12)",
            }}
          >
            <source src={data?.data?.bundleId?.videoLink} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
      )}
      <CardContent
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          paddingY: 2,
          paddingX: 0,
        }}
      >
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          textAlign="center"
          color="text.primary"
        >
          {data?.data?.bundleId?.title}
        </Typography>

        <Typography
          variant="subtitle1"
          gutterBottom
          textAlign="center"
          color="text.secondary"
        >
          Reported Outcomes to be filled out:
        </Typography>

        <List
          sx={{
            flexGrow: 1,
            overflow: "auto",
          }}
          className="DialogContent"
        >
          {data?.data?.assignedSurveys?.map((outcome, index) => (
            <ListItem
              key={index}
              sx={{
                borderRadius: "16px",
                border: "1px solid rgb(0, 0, 0, 0.12)",
                p: 1,
                my: 2,
              }}
            >
              <ListItemText primary={`${index + 1}. ${outcome.title}`} />
              <ListItemSecondaryAction>
                <Button
                  variant="contained"
                  color={outcome.status === "pending" ? "primary" : "success"}
                  size="small"
                  disabled={outcome.status !== "pending"}
                  sx={{ borderRadius: "4px" }}
                  onClick={() =>
                    GeneratePendingLink(outcome.formId, outcome._id)
                  }
                >
                  {outcome.status === "pending" ? "Start" : "Completed"}
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default ReportedOutcomesCard;
