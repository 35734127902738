import { ErrorBoundary } from "react-error-boundary";
import { createBrowserRouter } from "react-router-dom";
import { Thankyou } from "../Components";
import ErrorPage from "../Pages/ErrorPage";
import OnBoardingSurvey from "../Pages/OnBoardingSurvey";
import CreatePatientIndex from "../Pages/Patient/CreatePatient";
import PatientProfileIndex from "../Pages/Patient/PatientProfile";
import UploadImageIndex from "../Pages/UploadImage";
import QueueIndex from "../Pages/patientQueue";
import { QueueProfile } from "../Pages/patientQueue/Profile";
import ViewSolveSurvey from "../Pages/viewSolveSurvey";
import { IsAuthenticatedUserProvider } from "../contexts/isAuthenticatedUser";
import { Layout } from "./../Components/layout";
import {
  Analytics,
  CreatorPage,
  DashboardV2,
  EditorPage,
  FlowV2,
  FormsTable,
  Home,
  JourneysTableV2,
  PatientOnboarding,
  PatientTable,
  ProsUI,
  RolePage,
  Settings,
  ShowSurvey,
  SocialCareQueueUI,
  SocialNeedsUI,
  TasksTable,
  Unauthorized,
} from "./../Pages";
import { PATHS, ROLES } from "./../constants/routes";
import { Protected } from "./ProtectedRouter";
import ReportedOutcomesCard from "../Components/PROLandingPage";

export const router = createBrowserRouter([
  {
    path: "/unauthorized",
    element: (
      <ErrorBoundary fallback={<ErrorPage />}>
        <Unauthorized />
      </ErrorBoundary>
    ),
  },
  {
    index: true,
    path: "/",
    element: (
      <ErrorBoundary fallback={<ErrorPage />}>
        <Home />
      </ErrorBoundary>
    ),
  },
  {
    path: PATHS.index,
    element: (
      <ErrorBoundary fallback={<ErrorPage />}>
        <IsAuthenticatedUserProvider>
          <Layout />
        </IsAuthenticatedUserProvider>
      </ErrorBoundary>
    ),
    children: [
      {
        path: PATHS.dashboardIndexPage,
        element: <DashboardV2 />,
      },
      {
        path: PATHS.dashboardIndexPageV2,
        element: <DashboardV2 />,
      },
      {
        path: PATHS.patientsProfilePage,
        element: <PatientProfileIndex />,
        canActivate: [
          <IsAuthenticatedUserProvider>
            <Protected
              allowedRoles={[
                ROLES.Admin,
                ROLES.ClientAdmin,
                ROLES.CareTeamMember,
                // ROLES.ReportingUser,
              ]}
            />
          </IsAuthenticatedUserProvider>,
        ],
      },

      {
        path: PATHS.queueProfilePage,
        element: <QueueProfile />,
        canActivate: [
          <IsAuthenticatedUserProvider>
            <Protected
              allowedRoles={[
                ROLES.Admin,
                ROLES.ClientAdmin,
                ROLES.CareTeamMember,
                // ROLES.ReportingUser,
              ]}
            />
          </IsAuthenticatedUserProvider>,
        ],
      },
      {
        path: PATHS.patientsCareProgramPage,
        element: <PatientTable />,
        canActivate: [
          <IsAuthenticatedUserProvider>
            <Protected
              allowedRoles={[
                ROLES.Admin,
                ROLES.ClientAdmin,
                ROLES.CareTeamMember,
                // ROLES.ReportingUser,
              ]}
            />
          </IsAuthenticatedUserProvider>,
        ],
      },
      {
        path: PATHS.patientsIndexPage,
        element: <PatientTable />,
        canActivate: [
          <IsAuthenticatedUserProvider>
            <Protected
              allowedRoles={[
                ROLES.Admin,
                ROLES.ClientAdmin,
                ROLES.CareTeamMember,
                // ROLES.ReportingUser,
              ]}
            />
          </IsAuthenticatedUserProvider>,
        ],
      },
      {
        path: PATHS.prosIndexPage,
        element: <ProsUI />,
        canActivate: [
          <IsAuthenticatedUserProvider>
            <Protected
              allowedRoles={[
                ROLES.Admin,
                ROLES.ClientAdmin,
                ROLES.CareTeamMember,
                // ROLES.ReportingUser,
              ]}
            />
          </IsAuthenticatedUserProvider>,
        ],
      },
      {
        path: PATHS.socialNeedsIndexPage,
        element: <SocialNeedsUI />,
        canActivate: [
          <IsAuthenticatedUserProvider>
            <Protected
              allowedRoles={[
                ROLES.Admin,
                ROLES.ClientAdmin,
                ROLES.CareTeamMember,
                // ROLES.ReportingUser,
              ]}
            />
          </IsAuthenticatedUserProvider>,
        ],
      },
      {
        path: PATHS.socialCareQueue,
        element: <SocialCareQueueUI />,
        canActivate: [
          <IsAuthenticatedUserProvider>
            <Protected
              allowedRoles={[
                ROLES.Admin,
                ROLES.ClientAdmin,
                ROLES.CareTeamMember,
                // ROLES.ReportingUser,
              ]}
            />
          </IsAuthenticatedUserProvider>,
        ],
      },
      {
        path: PATHS.formsIndexPage,
        element: <FormsTable />,
        onActivate: [
          <IsAuthenticatedUserProvider>
            <Protected
              allowedRoles={[
                ROLES.Admin,
                ROLES.ClientAdmin,
                // ROLES.CareTeamMember,
                // ROLES.ReportingUser,
              ]}
            />
          </IsAuthenticatedUserProvider>,
        ],
      },
      {
        path: PATHS.formsCreatorPage,
        element: <CreatorPage />,
        onActivate: [
          <IsAuthenticatedUserProvider>
            <Protected
              allowedRoles={[
                ROLES.Admin,
                ROLES.ClientAdmin,
                // ROLES.CareTeamMember,
                // ROLES.ReportingUser,
              ]}
            />
          </IsAuthenticatedUserProvider>,
        ],
      },
      {
        path: PATHS.formsEditorPage,
        element: <EditorPage />,
        onActivate: [
          <IsAuthenticatedUserProvider>
            <Protected
              allowedRoles={[
                ROLES.Admin,
                ROLES.ClientAdmin,
                // ROLES.CareTeamMember,
                // ROLES.ReportingUser,
              ]}
            />
          </IsAuthenticatedUserProvider>,
        ],
      },
      {
        path: PATHS.solve,
        element: <ViewSolveSurvey />,
        onActivate: [
          <IsAuthenticatedUserProvider>
            <Protected
              allowedRoles={[
                ROLES.Admin,
                ROLES.ClientAdmin,
                // ROLES.CareTeamMember,
                // ROLES.ReportingUser,
              ]}
            />
          </IsAuthenticatedUserProvider>,
        ],
      },
      {
        path: PATHS.createPatient,
        element: <CreatePatientIndex />,
        onActivate: [
          <IsAuthenticatedUserProvider>
            <Protected
              allowedRoles={[
                ROLES.Admin,
                ROLES.ClientAdmin,
                // ROLES.CareTeamMember,
                // ROLES.ReportingUser,
              ]}
            />
          </IsAuthenticatedUserProvider>,
        ],
      },
      {
        path: PATHS.editPatient,
        element: <CreatePatientIndex />,
        onActivate: [
          <IsAuthenticatedUserProvider>
            <Protected
              allowedRoles={[
                ROLES.Admin,
                ROLES.ClientAdmin,
                // ROLES.CareTeamMember,
                // ROLES.ReportingUser,
              ]}
            />
          </IsAuthenticatedUserProvider>,
        ],
      },
      {
        path: PATHS.formsAnalyticsPage,
        element: <Analytics />,
        onActivate: [
          <IsAuthenticatedUserProvider>
            <Protected
              allowedRoles={[
                ROLES.Admin,
                ROLES.ClientAdmin,
                ROLES.CareTeamMember,
                ROLES.ReportingUser,
              ]}
            />
          </IsAuthenticatedUserProvider>,
        ],
      },

      // {
      //   path: PATHS.journeysIndexPage,
      //   element: <JourneysTable />,
      //   onActivate: [
      //     <IsAuthenticatedUserProvider>
      //       <Protected
      //         allowedRoles={[
      //           ROLES.Admin,
      //           ROLES.ClientAdmin,
      //           // ROLES.CareTeamMember,
      //           // ROLES.ReportingUser,
      //         ]}
      //       />
      //     </IsAuthenticatedUserProvider>,
      //   ],
      // },
      {
        path: PATHS.journeysIndexPage,
        element: <JourneysTableV2 />,
        onActivate: [
          <IsAuthenticatedUserProvider>
            <Protected
              allowedRoles={[
                ROLES.Admin,
                ROLES.ClientAdmin,
                // ROLES.CareTeamMember,
                // ROLES.ReportingUser,
              ]}
            />
          </IsAuthenticatedUserProvider>,
        ],
      },
      {
        path: PATHS.patientQueue,
        element: <QueueIndex />,
        onActivate: [
          <IsAuthenticatedUserProvider>
            <Protected
              allowedRoles={[
                ROLES.Admin,
                ROLES.ClientAdmin,
                // ROLES.CareTeamMember,
                // ROLES.ReportingUser,
              ]}
            />
          </IsAuthenticatedUserProvider>,
        ],
      },
      {
        path: PATHS.settigsIndexPage,
        element: <Settings />,
        onActivate: [
          <IsAuthenticatedUserProvider>
            <Protected
              allowedRoles={[
                ROLES.Admin,
                ROLES.ClientAdmin,
                ROLES.CareTeamMember,
                // ROLES.ReportingUser,
              ]}
            />
          </IsAuthenticatedUserProvider>,
        ],
      },
      {
        path: PATHS.patientSurveys,
        element: <ShowSurvey />,
      },
      // {
      //   path: "/surveys",
      //   element: <ViewAllSurveys />,
      // },
      // {
      //   path: "/surveys/:id",
      //   element: <ShowSurvey />,
      // },
      {
        path: "/roles",
        element: <RolePage />,
        onActivate: [
          <IsAuthenticatedUserProvider>
            <Protected
              allowedRoles={[
                ROLES.Admin,
                ROLES.ClientAdmin,
                ROLES.CareTeamMember,
                // ROLES.ReportingUser,
              ]}
            />
          </IsAuthenticatedUserProvider>,
        ],
      },
      {
        path: PATHS.tasks,
        element: <TasksTable />,
        canActivate: [
          <IsAuthenticatedUserProvider>
            <Protected
              allowedRoles={[
                ROLES.Admin,
                ROLES.ClientAdmin,
                ROLES.CareTeamMember,
                // ROLES.ReportingUser,
              ]}
            />
          </IsAuthenticatedUserProvider>,
        ],
      },
    ],
  },
  {
    path: PATHS.uploadImage,
    element: (
      <ErrorBoundary fallback={<ErrorPage />}>
        <UploadImageIndex />
      </ErrorBoundary>
    ),
  },
  {
    path: PATHS.patientOnboarding,
    element: (
      <ErrorBoundary fallback={<ErrorPage />}>
        <PatientOnboarding />
      </ErrorBoundary>
    ),
  },
  {
    path: PATHS.patientReportedOutcomes,
    element: (
      <ErrorBoundary fallback={<ErrorPage />}>
        <ReportedOutcomesCard />
      </ErrorBoundary>
    ),
  },
  {
    path: PATHS.ShowPatientSurvey,
    element: (
      <ErrorBoundary fallback={<ErrorPage />}>
        <ShowSurvey />
      </ErrorBoundary>
    ),
  },
  // {
  //   path: PATHS.journeysflow,
  //   element: (
  //     <ErrorBoundary fallback={<ErrorPage />}>
  //       <Flow />
  //     </ErrorBoundary>
  //   ),
  //   onActivate: [
  //     <IsAuthenticatedUserProvider>
  //       <Protected
  //         allowedRoles={[
  //           ROLES.Admin,
  //           ROLES.ClientAdmin,
  //           // ROLES.CareTeamMember,
  //           // ROLES.ReportingUser,
  //         ]}
  //       />
  //     </IsAuthenticatedUserProvider>,
  //   ],
  // },
  {
    path: PATHS.journeysflow,
    element: (
      <ErrorBoundary fallback={<ErrorPage />}>
        <FlowV2 />
      </ErrorBoundary>
    ),
    onActivate: [
      <IsAuthenticatedUserProvider>
        <Protected
          allowedRoles={[
            ROLES.Admin,
            ROLES.ClientAdmin,
            // ROLES.CareTeamMember,
            // ROLES.ReportingUser,
          ]}
        />
      </IsAuthenticatedUserProvider>,
    ],
  },
  // {
  //   path: PATHS.editjourneysflow,
  //   element: (
  //     <ErrorBoundary fallback={<ErrorPage />}>
  //       <IsAuthenticatedUserProvider>
  //       <Flow />
  //       </IsAuthenticatedUserProvider>
  //     </ErrorBoundary>
  //   ),
  //   onActivate: [
  //     <IsAuthenticatedUserProvider>
  //       <Protected
  //         allowedRoles={[
  //           ROLES.Admin,
  //           ROLES.ClientAdmin,
  //           // ROLES.CareTeamMember,
  //           // ROLES.ReportingUser,
  //         ]}
  //       />
  //     </IsAuthenticatedUserProvider>,
  //   ],
  // },
  {
    path: PATHS.editjourneysflow,
    element: (
      <ErrorBoundary fallback={<ErrorPage />}>
        <IsAuthenticatedUserProvider>
          <FlowV2 />
        </IsAuthenticatedUserProvider>
      </ErrorBoundary>
    ),
    onActivate: [
      <IsAuthenticatedUserProvider>
        <Protected
          allowedRoles={[
            ROLES.Admin,
            ROLES.ClientAdmin,
            // ROLES.CareTeamMember,
            // ROLES.ReportingUser,
          ]}
        />
      </IsAuthenticatedUserProvider>,
    ],
  },
  // {
  //   path: PATHS.viewjourneysflow,
  //   element: (
  //     <ErrorBoundary fallback={<ErrorPage />}>
  //       <IsAuthenticatedUserProvider>
  //       <Flow />
  //       </IsAuthenticatedUserProvider>
  //     </ErrorBoundary>
  //   ),
  //   onActivate: [
  //     <IsAuthenticatedUserProvider>
  //       <Protected
  //         allowedRoles={[
  //           ROLES.Admin,
  //           ROLES.ClientAdmin,
  //           ROLES.CareTeamMember,
  //           // ROLES.ReportingUser,
  //         ]}
  //       />
  //     </IsAuthenticatedUserProvider>,
  //   ],
  // },
  {
    path: PATHS.viewjourneysflow,
    element: (
      <ErrorBoundary fallback={<ErrorPage />}>
        <IsAuthenticatedUserProvider>
          <FlowV2 />
        </IsAuthenticatedUserProvider>
      </ErrorBoundary>
    ),
    onActivate: [
      <IsAuthenticatedUserProvider>
        <Protected
          allowedRoles={[
            ROLES.Admin,
            ROLES.ClientAdmin,
            ROLES.CareTeamMember,
            // ROLES.ReportingUser,
          ]}
        />
      </IsAuthenticatedUserProvider>,
    ],
  },
  {
    path: PATHS.thankyou,
    element: (
      <ErrorBoundary fallback={<ErrorPage />}>
        <Thankyou />
      </ErrorBoundary>
    ),
  },
  {
    path: PATHS.onBoarding,
    element: (
      <ErrorBoundary fallback={<ErrorPage />}>
        <OnBoardingSurvey />
      </ErrorBoundary>
    ),
  },
]);
