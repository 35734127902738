import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";

const PatientToggleButton = ({
  handlePatientToggle,
  patientToggleOption,
  setPatientToggleOption,
}) => {
  return (
    <ToggleButtonGroup
      value={patientToggleOption}
      exclusive
      onChange={handlePatientToggle}
      aria-label="text alignment"
      sx={{ mb: 2 }}
    >
      <ToggleButton value="patient" aria-label="left aligned">
        <Typography variant="subtitle2" fontWeight="600">
          Patient
        </Typography>
      </ToggleButton>
      <ToggleButton value="careAlly" aria-label="centered">
        <Typography variant="subtitle2" fontWeight="600">
          Care Ally
        </Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default PatientToggleButton;
