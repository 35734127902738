import {
  Box,
  Button,
  Drawer,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Call } from "../../assets/svgs/PatientProfileTabsSvgs/Call";
import { Conversation } from "../../assets/svgs/PatientProfileTabsSvgs/Conversation";
import { useIsMobile } from "../../contexts/isMobile";
import UnknownCallTab from "./UnknownCallTab";
import UnknownConversationTab from "./UnknownConversationTab";
import { UnknownDrawerHeader } from "../../Pages/DashboardV2/PatientQueue/UnknownDrawerHeader";
import { toast } from "react-toastify";
import OutgoingCallComponent from "../OutgoingCall";

export function UnknownCallerMessageDrawer({
  state,
  toggleDrawer,
  data,
  setOpenUnknownCallerChat,
  refetchQueueAgain,
}) {
  const isMobile = useIsMobile();
  const [unknownPatientToggleOption, setUnknownPatientToggleOption] =
    useState("calls");
  const [openCallUnregisterPatient, setOpenCallUnregisterPatient] =
    useState(false);
  const handleUnknownPatientToggle = (event, newValue) => {
    if (newValue !== null) {
      setUnknownPatientToggleOption(newValue);
    }
  };

  const handleCloseCallUnregister = () => {
    setOpenCallUnregisterPatient(false);
  };

  useEffect(() => {
    if (state?.value) {
      // Reset the toggle option when the drawer opens
      setUnknownPatientToggleOption("calls");
    }
  }, [state?.value]);

  const openCallUnregisterPatientModal = (name, phone, patientId) => {
    if (phone) {
      setOpenCallUnregisterPatient({
        value: true,
        name: name,
        phone: phone,
        patientId: patientId,
      });
    } else {
      toast.error("Phone number is not valid!");
    }
  };

  return (
    <React.Fragment key="anchor">
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflow: "hidden",
            width: isMobile ? "90%" : "60%",
          },
        }}
        anchor="right"
        open={state?.value}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            zIndex: 990,
          }}
        >
          <UnknownDrawerHeader
            data={state}
            setOpenUnknownCallerChat={setOpenUnknownCallerChat}
            refetchQueueAgain={refetchQueueAgain}
          />
        </Box>

        <Box sx={{ padding: "10px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ToggleButtonGroup
              value={unknownPatientToggleOption}
              exclusive
              onChange={handleUnknownPatientToggle}
              aria-label="text alignment"
              sx={{ mb: 2 }}
            >
              <ToggleButton value="messages" aria-label="left aligned">
                <Typography
                  variant="subtitle2"
                  fontWeight="600"
                  display="flex"
                  alignItems="center"
                >
                  <Conversation color="black" />
                  <span style={{ marginLeft: 5 }}>Messages</span>
                </Typography>
              </ToggleButton>
              <ToggleButton value="calls" aria-label="centered">
                <Typography
                  variant="subtitle2"
                  fontWeight="600"
                  display="flex"
                  alignItems="center"
                >
                  <Call color="black" />
                  <span style={{ marginLeft: 5 }}>Calls</span>
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>
            <Button
              variant="outlined"
              startIcon={<Call color="#6235ED" />}
              sx={{ fontWeight: 600, mr: 2, height: 40 }}
              size="small"
              onClick={() =>
                openCallUnregisterPatientModal(
                  state?.calldata?.metadata?.patientData?.name,
                  state?.calldata?.metadata?.patientData?.phone_number,
                  state?.calldata?._id,
                )
              }
            >
              Call Patient
            </Button>
          </Box>
          {unknownPatientToggleOption === "messages" && (
            <UnknownConversationTab
              {...{
                phone: state?.results?.phone_number,
                consentResponse: state?.results?.consentResponse,
              }}
            />
          )}
          {unknownPatientToggleOption === "calls" && (
            <UnknownCallTab
              {...{
                queueId: state.data,
                taskCategory: state.taskCategory,
                data: state,
              }}
            />
          )}
        </Box>
      </Drawer>
      {openCallUnregisterPatient?.value && (
        <OutgoingCallComponent
          open={openCallUnregisterPatient?.value}
          name={openCallUnregisterPatient?.name}
          phone={openCallUnregisterPatient?.phone}
          patientId={openCallUnregisterPatient?.patientId}
          handleClose={handleCloseCallUnregister}
        />
      )}
    </React.Fragment>
  );
}
