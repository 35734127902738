import { baseApiSlice } from "./baseApiSlice";

export const extendedApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBundleTable: builder.query({
      query: (query = {}) => {
        const { page = 1, limit = 10, ...rest } = query;
        const finalQuery = { page, limit, ...rest };

        const params = new URLSearchParams();
        if (page) {
          for (const [key, value] of Object.entries(finalQuery)) {
            params.append(key, value);
          }
        }

        return `/bundles?${params.toString()}`;
      },
    }),
    getBundleByID: builder.query({
      query: (id) => `/bundles/${id}`,
    }),
    getPatientBundleByID: builder.query({
      query: (id) => `/patientBundles/${id}`,
    }),
    getBundlesOptions: builder.query({
      query: () => "/bundles/options",
      providesJourneys: ({ data }) => {
        return [
          { type: "Bundles", id: "LIST" },
          ...data.map((p) => ({ type: "Bundles", id: p._id })),
        ];
      },
    }),
    addNewBundle: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/bundles",
          method: "POST",
          body: initialPost,
        };
      },
      invalidatesTags: [{ type: "Care Pathway", id: "LIST" }],
    }),
    editBundle: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/bundles/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
    deleteBundle: builder.mutation({
      query: (id) => {
        return {
          url: `/bundles/${id}`,
          method: "DELETE",
        };
      },
    }),
    assignBundleToPatient: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/patientBundles",
          method: "POST",
          body: initialPost,
        };
      },
    }),
    getBundleForPatientTable: builder.query({
      query: (query = {}) => {
        const { page = 1, limit = 10, id, ...rest } = query;
        const finalQuery = { page, limit, ...rest };

        const params = new URLSearchParams();
        if (page) {
          for (const [key, value] of Object.entries(finalQuery)) {
            if (key !== "id") {
              // Exclude 'id' from query params
              params.append(key, value);
            }
          }
        }

        return `/patientBundles/patient/${id}?${params.toString()}`;
      },
    }),
  }),
});

export const {
  useGetBundleByIDQuery,
  useGetBundleForPatientTableQuery,
  useGetBundlesOptionsQuery,
  useGetPatientBundleByIDQuery,
  useAddNewBundleMutation,
  useAssignBundleToPatientMutation,
  useDeleteBundleMutation,
  useEditBundleMutation,
  useGetBundleTableQuery,
} = extendedApiSlice;
