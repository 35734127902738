import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the datalabels plugin
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { IsMobileProvider } from "./contexts/isMobile";
import { persistor, store } from "./store";

// Define your custom plugin
const noDataPlugin = {
  id: "noDataPlugin",
  beforeDraw: (chart) => {
    if (chart.data.datasets[0].data.every((item) => item === 0)) {
      const ctx = chart.ctx;
      const { width, height } = chart;

      chart.clear();
      ctx.save();
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.font = "16px Arial"; // Customize the font style
      ctx.fillStyle = "#000"; // Customize text color

      // Retrieve the chart title from options
      const heading = chart.options.plugins.title.text || "No Data Available";
      ctx.fillText(`No data to display`, width / 2, height / 2);

      ctx.restore();
    }
  },
};

Chart.register(...registerables);
// Register the plugin to all charts:
Chart.register(ChartDataLabels, noDataPlugin);

const theme = createTheme({
  customeScondary: "#979797",
  palette: {
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#f50057",
    },
    error: {
      main: "#FF3E3E",
    },
    warning: {
      main: "#EF6C00",
    },
    info: {
      main: "#0288D1",
    },
    success: {
      main: "#2E7D32",
    },
    text: {
      main: "#979797",
      secondary: "#979797",
    },
    custom: {
      main: "#48f95e",
    },
  },
  typography: {
    allVariants: {
      fontFamily: "var(--fontFamily, Roboto), sans-serif",
      color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
      fontFeatureSettings: "'liga' off, 'clig' off",
    },

    h1: {
      fontSize: "var(--_fontSize-6rem, 96px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightLight, 300)",
      lineHeight: "116.7%",
      letterSpacing: "-1.5px",
    },
    h2: {
      fontSize: "var(--_fontSize-375rem, 60px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightLight, 300)",
      lineHeight: "120%",
      letterSpacing: "-0.5px",
    },
    h3: {
      fontSize: "var(--_fontSize-3rem, 48px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightRegular, 400)",
      lineHeight: "116.7%",
    },
    h4: {
      fontSize: "var(--font-size-2125-rem, 34px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightRegular, 400)",
      lineHeight: "123.5%",
      letterSpacing: "0.25px",
    },
    h5: {
      fontSize: "var(--font-size-15-rem, 24px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightRegular, 400)",
      lineHeight: "133.4%",
    },
    h6: {
      fontSize: "var(--font-size-125-rem, 20px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightMedium, 500)",
      lineHeight: "160%",
      letterSpacing: "0.15px",
    },
    subtitle1: {
      fontSize: "var(--_fontSize-1rem, 16px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightRegular, 400)",
      lineHeight: "175%;" /* 28px */,
      letterSacing: "0.15px",
    },
    subtitle2: {
      fontSize: "var(--font-size-0875-rem, 14px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightMedium, 500)",
      lineHeight: "157%" /* 21.98px */,
      letterSpacing: "0.1px",
    },
    body1: {
      fontSize: "var(--_fontSize-1rem, 16px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightRegular, 400)",
      lineHeight: "150%" /* 24px */,
      letterSpacing: "0.15px",
    },
    body2: {
      fontSize: "var(--font-size-0875-rem, 14px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightRegular, 400)",
      lineHeight: "143%",
      letterSpacing: "0.17px",
    },
    caption: {
      fontSize: "var(--font-size-075-rem, 12px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightRegular, 400)",
      lineHeight: "166%" /* 19.92px */,
      letterSpacing: "0.4px",
    },
    overline: {
      fontSize: "var(--font-size-075-rem, 12px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightRegular, 400)",
      lineHeight: "266%" /* 31.92px */,
      letterSpacing: "1px",
      textTransform: "uppercase",
    },
    custom: {
      fontSize: "var(--font-size-0875-rem, 14px)",
      fontStyle: "normal",
      fontWeight: "var(--fontWeightMedium, 500)",
      lineHeight: "24px",
      letterSpacing: "0.17px",
    },
  },
});
const container = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      scope="read:azraauth"
      responseType="code"
      audience={process.env.REACT_APP_BASE_API_URL}
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      useRefreshTokens={true}
      authorizationParams={{
        redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
      }}
    >
      <IsMobileProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </IsMobileProvider>
    </Auth0Provider>
  </React.StrictMode>,
  container,
);
