import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";

const TaskToggleButton = ({ data, setQueries, queries }) => {
  const { user } = useSelector((state) => state.user);
  const [alignment, setAlignment] = React.useState("allTask");

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
    if (newAlignment === "myTask") {
      setQueries({
        ...queries,
        assignee: [data?.assignee?.value?.find((item) => item?.id === user.id)],
      });
    } else {
      setQueries({ ...queries, assignee: [] });
    }
  };

  return (
    <ToggleButtonGroup
      value={alignment}
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment"
      sx={{ mb: 2 }}
    >
      <ToggleButton value="allTask" aria-label="left aligned">
        <Typography variant="subtitle2" fontWeight="600">
          All Tasks
        </Typography>
      </ToggleButton>
      <ToggleButton value="myTask" aria-label="centered">
        <Typography variant="subtitle2" fontWeight="600">
          My Tasks
        </Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default TaskToggleButton;
