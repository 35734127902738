import { llmApiSlice } from "./llmApiSlice";

export const extendedApiSlice = llmApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWorkflowStatus: builder.query({
      query: (id) => `/workflow/${id}`,
    }),
    stopAgent: builder.mutation({
      query: (initialPost) => {
        return {
          url: "/workflow/interrupt",
          method: "POST",
          body: initialPost,
        };
      },
      invalidatesTags: [{ type: "Surveys", id: "LIST" }],
    }),
    queueSummary: builder.mutation({
      queryFn: async ({ body }, _queryApi, _extraOptions, fetchWithBQ) => {
        try {
          const response = await fetchWithBQ({
            url: "/summarize/queue",
            method: "POST",
            body,
          });

          if (!response.error && response.raw?.body) {
            const reader = response.raw.body.getReader();
            const decoder = new TextDecoder("utf-8");
            let result = "";

            while (true) {
              const { done, value } = await reader.read();
              if (done) break;

              result += decoder.decode(value, { stream: true });
              _queryApi.dispatch(
                extendedApiSlice.util.updateQueryData(
                  "queueSummary",
                  undefined,
                  () => result,
                ),
              );
            }

            return { data: result };
          } else {
            throw new Error(response.error?.data || "Streaming failed.");
          }
        } catch (error) {
          return { error: { status: 500, data: error.message } };
        }
      },
    }),
  }),
});

export const {
  useGetWorkflowStatusQuery,
  useStopAgentMutation,
  useQueueSummaryMutation,
} = extendedApiSlice;
