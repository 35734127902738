import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearLocalStroage } from "../utils";

function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginWithRedirect, isAuthenticated, getIdTokenClaims } = useAuth0();

  // isAuthenticated && navigate('/dashboard')
  return (
    <>
      <Stack direction="row" justifyContent="center" sx={{ height: "100vh" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <img
            src={require("../../src/assets/RevelAi.png")}
            style={{
              width: "260px",
              height: "100px",
              objectFit: "cover",
              background: "transparent",
            }}
            alt="RevelAi Logo"
          />
          <Typography variant="h3" sx={{ marginBottom: "5px", marginTop: 8 }}>
            Welcome to the RevelAi Health Dashboard
          </Typography>
          <Typography variant="body1" fontSize="22px" color="text.secondary">
            Bringing order to the post-operative chaos - Log in here
          </Typography>

          {isAuthenticated ? (
            <Box>You are logged in</Box>
          ) : (
            <Button
              sx={{ marginTop: 6, width: "20%", borderRadius: "4px" }}
              variant="contained"
              color="primary"
              onClick={() => {
                clearLocalStroage();
                loginWithRedirect();
              }}
            >
              Login
            </Button>
          )}
        </Box>
      </Stack>
    </>
  );
}

export default Home;
