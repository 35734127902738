import {
  Paper,
  Chip,
  IconButton,
  Typography,
  Box,
  Badge,
  Grid,
  Tooltip,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useChatValues } from "../../../contexts/chatValue";
import { useIsMobile } from "../../../contexts/isMobile";
import { useIsHovered } from "../../../contexts/isHovered";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebouncedValue } from "@mantine/hooks";
import { useSelector } from "react-redux";
import {
  addInParams,
  getTokenFromLocalStroage,
  toSnakeCase,
} from "../../../utils";
import filterByDate from "../../patientQueue/DateFilterFunction";
import { useGetProceduresOptionsQuery } from "../../../store/proceduresSlice";
import { patientQueueFilterData } from "../../../FilterUtils";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CallIcon from "@mui/icons-material/Call";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
import PaymentsIcon from "@mui/icons-material/Payments";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import ShieldIcon from "@mui/icons-material/Shield";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import BlockIcon from "@mui/icons-material/Block";
import { PaginationBar } from "../../../Components";
import moment from "moment";
import CustomDrawer from "../PatientQueue/Drawer";
import CustomMultiSelect from "../PatientQueue/CustomMultiSelect";
import { LastCell } from "../../patientQueue/LastCell";
import { UnknownCallerMessageDrawer } from "../../../Components/MessageLayout/UnknownCallerMessageDrawer";
import { Conversation } from "../../../assets/svgs/PatientProfileTabsSvgs/Conversation.js";
import axios from "axios";
import { toast } from "react-toastify";
import { PatientsSvg } from "../../../assets/svgs/PatientsSvg.js";
import LinkQueueModal from "../../../Components/LinkQueueModal.js";
import { Call } from "../../../assets/svgs/PatientProfileTabsSvgs/Call.js";
import { Gallery } from "../../../assets/svgs/PatientProfileTabsSvgs/Gallery.js";
import { Form } from "../../../assets/svgs/PatientProfileTabsSvgs/Form.js";
import { Task } from "../../../assets/svgs/PatientProfileTabsSvgs/Task.js";
import { HelpCenterOutlined } from "@mui/icons-material";
import { styled } from "@mui/system";
import OutgoingCallComponent from "../../../Components/OutgoingCall.js";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#ffffff", // White background
    color: "#000000", // Black text
    border: "1px solid #ccc", // Optional border
    padding: theme.spacing(1),
    fontSize: "0.875rem",
    boxShadow: theme.shadows[1],
  },
}));

const typeData = [
  { label: "Messages", key: "message", icon: "ChatBubbleOutlineIcon" },
  { label: "Photo Uploads", key: "new_media", icon: "CollectionsOutlinedIcon" },
  { label: "Tasks", key: "task", icon: "AssignmentIcon" },
  { label: "Forms", key: "form_submission", icon: "FeedOutlinedIcon" },
  {
    label: "Care Ally Messages",
    key: "care_ally_message",
    icon: "HandshakeOutlinedIcon",
  },
  { label: "Call", key: "call", icon: "CallIcon" },
  { label: "Dialogue Assessments", key: "dialogue", icon: "ForumOutlinedIcon" },
];

const iconMapping = {
  ChatBubbleOutlineIcon: ChatBubbleOutlineIcon,
  CollectionsOutlinedIcon: CollectionsOutlinedIcon,
  AssignmentIcon: AssignmentIcon,
  FeedOutlinedIcon: FeedOutlinedIcon,
  HandshakeOutlinedIcon: HandshakeOutlinedIcon,
  ForumOutlinedIcon: ForumOutlinedIcon,
  CallIcon: CallIcon,
};

export function QueueCardV2({
  value,
  refetchCount,
  acuityCount,
  queueData,
  selectedPatientIds,
  setSelectedPatientIds,
  page,
  setPage,
  handleSorting,
  sortPatientOrder,
  sortAcuityOrder,
  refetchQueueAgain,
  sortTaskCategoryOrder,
  sortPhysicianNameOrder,
  sortProcedureTypeOrder,
  sortQueueTypeOrder,
  sortSocialNeedsOrder,
  sortContactTimeOrder,
  isV2Profile,
}) {
  const { setChatMessages } = useChatValues();
  const isMobile = useIsMobile();
  const { hovered } = useIsHovered();
  const prevDataRef = useRef();
  const unknownCallerDrawerRef = useRef(null);
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const queryParams = new URLSearchParams(location.search);
  const searchAbleFields = ["name", "task category", "acuity"];
  const selectedPatientStatus =
    location.pathname.split("/")[1] === "queue"
      ? localStorage.getItem("selectedPatientStatus1")
      : localStorage.getItem("selectedPatientStatus");
  const [selectedFields, setSelectedFields] = useState([...searchAbleFields]);
  const { user } = useSelector((state) => state.user);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedValue] = useDebouncedValue(searchTerm, 200);
  const [status, setStatus] = useState("pending");
  const [openRows, setOpenRows] = useState([]);
  const [linkQueueModalOpen, setLinkQueueModalOpen] = useState({
    value: false,
    data: null,
    results: null,
    taskCategory: null,
    callerIdentification: null,
  });
  const [filterData, setFilterData] = useState({});
  const [rows, setRows] = useState([]);
  const [searchProgram, setSearchProgram] = useState([]);
  const navigate = useNavigate();
  const queries = {};
  const [queriesValue, setQueriesValue] = useState(
    localStorage.getItem("queriesValue")
      ? JSON.parse(localStorage.getItem("queriesValue"))
      : {},
  );
  const [open, setOpen] = useState(false);
  const [chatbotResponseCritique, setChatbotResponseCritique] = useState("");
  const [selectedAcuity, setSelectedAcuity] = useState("");
  const [proposedChatbotResponse, setProposedChatbotResponse] = useState("");
  const [selectedTaskLabel, setSelectedTaskLabel] = useState("");
  const [currentItemId, setCurrentItemId] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [patientQueues, setPatientQueues] = useState([]);
  const [selectOriginalMessage, setSelectOriginalMessage] = useState(null);
  const [openDislikeModal, setOpenDislikeModal] = useState(false);
  const [isTaskCategorySelected, setIsTaskCategorySelected] = useState(true);
  const [isChatbotResponse, setIsChatbotResponse] = useState(true);
  const [chatbotResponse, setChatbotResponse] = useState(null);
  const [isAcuitySelected, setIsAcuitySelected] = useState(true);
  const [selectedValue, setSelectedValue] = useState(
    localStorage.getItem("selectedValue")
      ? localStorage.getItem("selectedValue")
      : null,
  );
  const [sortCreatedAtOrder, setSortCreatedAtOrder] = useState(
    localStorage.getItem("sortCreatedAtOrder")
      ? JSON.parse(localStorage.getItem("sortCreatedAtOrder"))
      : { field: "createdAt", sort: "desc" },
  );
  const [sortField, setSortField] = useState(
    localStorage.getItem("sortField") ? localStorage.getItem("sortField") : "",
  );
  const [sortSurgeonName, setSortSurgeonName] = useState(
    localStorage.getItem("sortSurgeonName")
      ? JSON.parse(localStorage.getItem("sortSurgeonName"))
      : { field: "surgeonName", sort: "asc" },
  );
  const [sortOrder, setSortOrder] = useState(
    localStorage.getItem("sortOrder") ? localStorage.getItem("sortOrder") : "",
  );
  const [createdAfter, setCreatedAfter] = useState(
    localStorage.getItem("createdAfter")
      ? localStorage.getItem("createdAfter")
      : "",
  );
  const [patientQueuesData, setPatientQueuesData] = useState([]);
  const [isRowHovered, setIsRowHovered] = useState(null);
  const [hasRunOnce, setHasRunOnce] = useState(false);
  const [openPatientProfileDrawer, setOpenPatientProfileDrawer] =
    useState(false);
  const [openUnknownCallerChat, setOpenUnknownCallerChat] =
    React.useState(false);
  const tabsValue = [
    "message",
    "new_media",
    "form_submission",
    "care_ally_message",
    "dialogue",
  ];

  const { data: procedureTypeData } = useGetProceduresOptionsQuery();

  const iconMap = {
    food: <RestaurantIcon />,
    transportation: <DirectionsCarIcon />,
    housing: <HouseOutlinedIcon />,
    safety: <ShieldIcon />,
    utility: <TipsAndUpdatesIcon />,
    financial: <PaymentsIcon />,
    urgent: <DirectionsRunIcon />,
  };
  const getIconByKey = (key) => {
    switch (key) {
      case "message":
        return <Conversation color="#212121" size={20} />;
      case "new_media":
        return <Gallery color="#212121" size={20} />;
      case "form_submission":
        return <Form color="#212121" size={20} />;
      case "care_ally_message":
        return <Conversation color="#212121" size={20} />;
      case "dialogue":
        return <Form color="#212121" size={20} />;
      case "call":
        return <Call color="#212121" size={20} />;
      case "task":
        return <Task color="#212121" size={20} />;
      default:
        return <HelpCenterOutlined />; // Default fallback icon
    }
  };

  const [openCallUnregisterPatient, setOpenCallUnregisterPatient] =
    useState(false);

  const handleCloseCallUnregister = () => {
    setOpenCallUnregisterPatient(false);
  };

  const openCallUnregisterPatientModal = (name, phone, patientId) => {
    const usPhoneRegex = /^\+1\d{10}$/;
    if (phone && usPhoneRegex.test(phone)) {
      setOpenCallUnregisterPatient({
        value: true,
        name: name,
        phone: phone,
        patientId: patientId,
      });
    } else {
      toast.error("Phone number is not valid!");
    }
  };
  const handleChangePage = (event, newPage) => {
    if (isV2Profile) {
      localStorage.setItem("page3", newPage);
      setPage(newPage);
      addInParams("page", newPage);
    } else {
      setSelectedPatientIds([]);
      localStorage.setItem("page", newPage);
      setPage(newPage);
      addInParams("page", newPage);
    }
  };

  const calculateAge = (dob) => {
    if (dob) {
      // Check if the date is in ISO 8601 format
      const birthDate = dob.includes("T")
        ? new Date(dob)
        : new Date(`${dob}T00:00:00.000Z`);
      const today = new Date();
      let age_now = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
      }
      return `${age_now}Y`;
    } else {
      return `N/A`;
    }
  };

  const getColorStyles = (acuity) => {
    if (acuity === "high") {
      return {
        backgroundColor: "#EF4444",
      };
    } else if (acuity === "medium") {
      return {
        backgroundColor: "#FFB546",
      };
    } else if (acuity === "N/A") {
      return {
        backgroundColor: "black",
      };
    } else if (acuity === "low") {
      return {
        backgroundColor: "#04B556",
      };
    } else {
      return {
        backgroundColor: "gray",
      };
    }
  };

  const toggleUnknownCallerDrawer = (open) => () => {
    // addInParams('formType', open?.clickedValue )
    // addInParams('patientId', open?.patientId)
    // addInParams('queueId', open?.queueId)
    // setClickedValue(open?.clickedValue)
    setOpenUnknownCallerChat(open);
    // setChatMessages([]);

    // if (open?.open) {
    //   messageSeen(patientId).then(() => {
    //     refetch();
    //   });
    //   setPage(1);
    // }
  };

  const config = {
    headers: { Authorization: `Bearer ${getTokenFromLocalStroage()}` },
  };

  const updateStatus = async (itemId, currentStatus) => {
    // handleClose();
    const newStatus = currentStatus === "resolved" ? "pending" : "resolved";
    const ids = [itemId];

    try {
      await axios
        .put(
          `${process.env.REACT_APP_BASE_API_URL}/patientQueues/updateStatuses`,
          { status: newStatus, ids },
          config,
        )
        .then((res) => {
          const status = res.data.status;
          toast.success("The issue is " + status);
          refetchQueueAgain();
          refetchCount();
        });
    } catch (error) {
      toast.error("Error:", error);
    }
  };

  const showMarkAsResolved = (index) => {
    const hoveredPatient = patientQueues[index];
    setIsRowHovered(hoveredPatient?._id);
  };

  useEffect(() => {
    if (queueData && isV2Profile) {
      setTotalPages(queueData?.totalPages);
      setPatientQueues(queueData?.data);
    } else if (queueData) {
      if (queueData?.patientQueues !== prevDataRef?.current?.length) {
        prevDataRef.current = queueData?.patientQueues;

        setTotalPages(queueData?.totalPages);
        setPatientQueues(queueData?.patientQueues);
      }
    }
  }, [queueData]);

  useEffect(() => {
    if (selectedValue) {
      setPage(1);
      const filteredDate = filterByDate(selectedValue);
      localStorage.setItem("createdAfter", filteredDate);
      setCreatedAfter(filteredDate);
    }
  }, [selectedValue]);

  useEffect(() => {
    if (page > queueData?.totalPages) {
      localStorage.setItem("page", 1);
      setPage(1);
    }
  }, [queueData]);

  useEffect(() => {
    if (procedureTypeData?.data) {
      const surgeryType = {
        value: procedureTypeData?.data?.map((item) => ({
          id: item._id,
          title: item.name,
          value: toSnakeCase(item.name),
        })),
      };

      const newPatientQueueFilterData = {
        ...patientQueueFilterData,
        surgeryType,
      };

      setFilterData(newPatientQueueFilterData);
    } else {
      setFilterData(patientQueueFilterData);
    }
  }, [procedureTypeData]);

  useEffect(() => {
    if (setSelectedPatientIds) {
      setSelectedPatientIds([]);
    }
  }, [selectedPatientStatus, location]);

  useEffect(() => {
    if (
      queryParams.get("tab") &&
      queryParams.get("patientId") &&
      !hasRunOnce &&
      patientQueues?.find((item) => item?._id == queryParams.get("patientId"))
    ) {
      setChatMessages([]);
      setOpenPatientProfileDrawer({
        value: true,
        patient: patientQueues?.find(
          (item) => item?._id == queryParams.get("patientId"),
        ),
      });
      setHasRunOnce(true);
    }
  }, [patientQueues]);

  return (
    <>
      {patientQueues &&
        patientQueues?.map((item, index) => (
          <Paper
            elevation={0}
            sx={{
              px: 3,
              py: 2,
              my: 3,
              position: "relative", // Ensure the Paper is a positioning context
              borderRadius: "8px",
              border: "1px solid #DEDEDE",
              transition: "all 0.3s ease-in-out", // Smooth transition
              "&:hover": {
                backgroundColor: "#f5f5f5", // Change background color on hover
                cursor: "pointer", // Change cursor to pointer
              },
            }}
            key={index}
            onClick={
              item?.patient
                ? () => {
                    setDrawerOpen({
                      value: true,
                      type:
                        selectedPatientStatus === "resolved" ||
                        !item?.items ||
                        selectedPatientStatus === "ignored"
                          ? item?.type
                          : item?._id?.type || "",
                      data: item,
                      selectedPatientStatus,
                    });
                  }
                : toggleUnknownCallerDrawer({
                    value: true,
                    data: item?._id,
                    results: item?.metadata?.patientData,
                    taskCategory: item?.taskCategory,
                    callerIdentification: item?.metadata?.callerIdentification,
                    calldata: item,
                  })
            }
            onMouseEnter={() => showMarkAsResolved(index)}
            onMouseLeave={() => setIsRowHovered(null)}
          >
            <>
              {/* <IconButton aria-label="expand row" size="small" onClick={() => handleRowToggle(index)}>
                            {rows[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton> */}
              {console.log(item?._id, selectedPatientIds?.includes(item?._id))}
              {selectedPatientStatus === "resolved" ||
              selectedPatientStatus === "ignored" ||
              !item?.items ? (
                <Checkbox
                  size="small"
                  sx={{
                    visibility: selectedPatientIds?.includes(item?._id)
                      ? "visible" // Always visible if checked
                      : isRowHovered === item?._id
                        ? "visible" // Visible when hovered
                        : "hidden", // Otherwise hidden
                    position: "absolute",
                    top: -3,
                    left: -3,
                  }}
                  checked={selectedPatientIds?.includes(item?._id)}
                  onClick={(e) => e.stopPropagation()}
                  onChange={() => {
                    setSelectedPatientIds((prevState) =>
                      prevState.includes(item._id)
                        ? prevState.filter((id) => id !== item._id)
                        : [...prevState, item._id],
                    );
                  }}
                />
              ) : (
                <Checkbox
                  size="small"
                  checked={item?.items
                    ?.map((ids) => ids?._id)
                    ?.every((id) => selectedPatientIds?.includes(id))}
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    visibility: item?.items
                      ?.map((ids) => ids?._id)
                      ?.every((id) => selectedPatientIds?.includes(id))
                      ? "visible" // Always visible if checked
                      : isRowHovered === item?._id
                        ? "visible" // Visible when hovered
                        : "hidden", // Otherwise hidden
                    position: "absolute",
                    top: -3,
                    left: -3,
                  }}
                  onChange={() => {
                    setSelectedPatientIds((prevState) => {
                      const newIds = item.items?.map((ids) => ids?._id) || [];

                      const allIdsIncluded = newIds.every((id) =>
                        prevState.includes(id),
                      );

                      return allIdsIncluded
                        ? prevState.filter((id) => !newIds.includes(id))
                        : [...prevState, ...newIds];
                    });
                  }}
                />
              )}
            </>
            {/* Main Grid of Card */}
            <Grid container>
              <Grid item sm={12} padding={0.5}>
                {/* First Row of Card */}
                <Grid container spacing={2}>
                  {/* Grid containing Patient Info */}
                  <Grid item sm={2.5}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Box>
                        {item?.patient ? (
                          <Box>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: "500", fontSize: 16 }}
                            >
                              {item?.patient?.firstName},{" "}
                              {item?.patient?.lastName}
                            </Typography>
                            <Typography
                              variant="caption"
                              color="text.secondary"
                              sx={{ fontWeight: "normal", fontSize: 14 }}
                            >
                              {`${calculateAge(item?.patient?.birthday)}, ${
                                item?.patient?.gender
                                  ? item.patient.gender
                                      .charAt(0)
                                      .toUpperCase() +
                                    item.patient.gender.slice(1)
                                  : ""
                              } | DOB: ${item?.patient?.birthday ? moment(item?.patient?.birthday).format("MM-DD-YYYY") : "--/--/----"}`}
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Chip
                              label={item?.metadata?.patientData?.name || "N/A"}
                              size="small"
                              variant="outlined"
                              color="warning"
                              sx={{
                                fontWeight: 500,
                                borderRadius: "4px",
                              }}
                            />
                            <IconButton
                              sx={{
                                border: "1px solid rgb(0, 0, 0, 0.12)", // Add an 8px border
                                borderRadius: "8px", // Optional: Make the border circular if needed
                                padding: "4px", // Adjust padding to maintain proper spacing
                                display: "inline-flex", // Center the content inside the border
                                alignItems: "center",
                                justifyContent: "center",
                                marginLeft: "5px",
                              }}
                              onClick={(event) => {
                                event.stopPropagation();
                                setLinkQueueModalOpen({
                                  value: true,
                                  data: item?._id,
                                  results: item?.metadata?.patientData,
                                  taskCategory: item?.taskCategory,
                                  callerIdentification:
                                    item?.metadata?.callerIdentification,
                                });
                              }}
                            >
                              <PatientsSvg fill="black" size="18px" />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  {/* Grid containing Task Category Info */}
                  <Grid item sm={2.5}>
                    <Box>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontWeight: "normal", fontSize: 14 }}
                      >
                        Task Category
                      </Typography>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "500", fontSize: 16 }}
                        >
                          {item?._id?.taskCategory
                            ? item?._id?.taskCategory?.[0]
                            : item?.taskCategory
                              ? item?.taskCategory?.[0]
                              : "N/A"}
                        </Typography>
                        {((selectedPatientStatus === "pending"
                          ? item?._id?.taskCategory
                          : item?.taskCategory
                        )?.length || 0) > 1 && (
                          <CustomTooltip
                            title={
                              <div>
                                {(selectedPatientStatus === "pending"
                                  ? item?._id?.taskCategory
                                  : item?.taskCategory
                                )
                                  ?.slice(1)
                                  .map((item, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: "4px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: "8px",
                                          height: "8px",
                                          backgroundColor: "#6235ED",
                                          borderRadius: "3px",
                                          marginRight: "8px",
                                        }}
                                      />
                                      <Typography component="span">
                                        {item
                                          .replace(/_/g, " ")
                                          .split(/\s+/)
                                          .map(
                                            (word) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1),
                                          )
                                          .join(" ")}
                                      </Typography>
                                    </div>
                                  ))}
                              </div>
                            }
                          >
                            <Chip
                              label={`+
                                                ${
                                                  (
                                                    selectedPatientStatus ===
                                                    "pending"
                                                      ? item?._id?.taskCategory
                                                      : item?.taskCategory
                                                  )?.length - 1
                                                }`}
                              size="small"
                              sx={{
                                backgroundColor: "#EEE",
                                borderRadius: "700px",
                                cursor: "pointer",
                              }}
                            />
                          </CustomTooltip>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  {/* Grid containing Contact Time Info */}
                  <Grid item sm={2}>
                    {/* Date & Time */}
                    <Box>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontWeight: "normal", fontSize: 14 }}
                      >
                        Contact Time
                      </Typography>
                      {selectedPatientStatus === "resolved" ||
                      !item?.items ||
                      selectedPatientStatus === "ignored" ? (
                        <>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "500", fontSize: 16 }}
                          >
                            {`${moment(item?.createdAt).format("MM-DD-YYYY")} |
                          ${moment(item?.createdAt).format("hh:mm A")}`}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "500", fontSize: 16 }}
                          >
                            {`${moment(item?.items?.[0]?.createdAt).format(
                              "MM-DD-YYYY",
                            )} |
                          ${moment(item?.items?.[0]?.createdAt).format("hh:mm A")}`}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Grid>
                  {/* Grid containing Physician Info */}
                  <Grid item sm={2.5}>
                    <Box>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontWeight: "normal", fontSize: 14 }}
                      >
                        Physician
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "500", fontSize: 16 }}
                      >
                        {item?.surgeonName ? item?.surgeonName : "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                  {/* Grid containing Procedure Info */}
                  <Grid item sm={2.5}>
                    <Box>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontWeight: "normal", fontSize: 14 }}
                      >
                        Procedure Type / Date
                      </Typography>
                      {selectedPatientStatus === "resolved" ||
                      !item?.items ||
                      selectedPatientStatus === "ignored" ? (
                        <>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "500", fontSize: 16 }}
                          >
                            {`${item?.surgeryType || "N/A"} | ${
                              item?.surgeryDate
                                ? moment(item?.surgeryDate).format("MM-DD-YYYY")
                                : "--/--/----"
                            }`}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "500", fontSize: 16 }}
                          >
                            {`${item?.items?.[0]?.surgeryType || "N/A"} | ${
                              item?.items?.[0]?.surgeryDate
                                ? moment(item?.items?.[0]?.surgeryDate).format(
                                    "MM-DD-YYYY",
                                  )
                                : "--/--/----"
                            }`}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                {/* Second Row of Card */}
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  {/* Grid containing Acuity Info */}
                  <Grid item sm={2.5}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontWeight: "normal", fontSize: 14 }}
                      >
                        Acuity
                      </Typography>

                      {item?.highestAcuity ? (
                        <Chip
                          label={
                            item?.highestAcuity?.charAt(0).toUpperCase() +
                            item?.highestAcuity?.slice(1)
                          }
                          size="small"
                          sx={{
                            ...getColorStyles(item?.highestAcuity),
                            height: "20px",
                            fontSize: "0.75rem",
                            color: "white",
                            borderRadius: "4px",
                            fontWeight: "500",
                            maxWidth: "fit-content",
                          }}
                        />
                      ) : (
                        <Chip
                          label={
                            item?.acuity?.charAt(0).toUpperCase() +
                            item?.acuity?.slice(1)
                          }
                          size="small"
                          sx={{
                            ...getColorStyles(item?.acuity),
                            height: "20px",
                            fontSize: "0.75rem",
                            color: "white",
                            borderRadius: "4px",
                            fontWeight: "500",
                            maxWidth: "fit-content",
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                  {/* Grid containing Queue Type Info */}
                  <Grid item sm={2.5}>
                    <Box>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontWeight: "normal", fontSize: 14 }}
                      >
                        Queue Source
                      </Typography>
                      <Box>
                        {selectedPatientStatus === "resolved" ||
                        !item?.items ||
                        selectedPatientStatus === "ignored" ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                            }}
                          >
                            {typeData
                              .filter((type) => type.key === item?.type)
                              .map((matchedType) => {
                                const IconComponent =
                                  iconMapping[matchedType.icon];
                                return (
                                  <React.Fragment key={matchedType.key}>
                                    {IconComponent && (
                                      <IconComponent
                                        sx={{ marginRight: "5px" }}
                                        fontSize="small"
                                      />
                                    )}
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: 16,
                                        whiteSpace: "normal", // Allow breaking
                                        wordBreak: "break-word", // Break long words,
                                      }}
                                    >
                                      {/* Dynamically break text based on a space or any condition */}
                                      {matchedType.label || item?.type}
                                    </Typography>
                                  </React.Fragment>
                                );
                              })}
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            {typeData
                              .filter((type) => type.key === item?._id?.type)
                              .map((matchedType) => {
                                return (
                                  <React.Fragment key={matchedType.key}>
                                    {getIconByKey(item?._id?.type)}
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: 16,
                                        ml: "4px",
                                        textAlign: "center",
                                        whiteSpace: "normal", // Allows line breaking within words
                                        wordBreak: "break-word", // Allows breaking long words
                                      }}
                                    >
                                      {matchedType.label || item?._id?.type}
                                    </Typography>
                                    <Badge
                                      badgeContent={item.totalItems}
                                      color="primary"
                                      sx={{ ml: item.totalItems > 99 ? 3 : 2 }}
                                    />
                                  </React.Fragment>
                                );
                              })}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  {/* Grid containing Social Needs Info */}
                  <Grid item sm={2}>
                    <Box>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontWeight: "normal", fontSize: 14 }}
                      >
                        Social Needs
                      </Typography>

                      <div
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          justifyContent: "center",
                          gap: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(6, 1fr)",
                            gap: "4px",
                            justifyContent: "start",
                            alignContent: "center",
                            width: "70%",
                          }}
                        >
                          {(item?.patient?.socialNeeds ||
                            item?.patient?.socialRisks) &&
                          (item?.patient?.socialNeeds?.length > 0 ||
                            item?.patient?.socialRisks?.length > 0) ? (
                            [
                              ...(item?.patient?.socialRisks || []),
                              ...(item?.patient?.socialNeeds || []),
                            ].map((icon, index) => {
                              const isSocialNeed =
                                item?.socialNeeds?.includes(icon);
                              const color = isSocialNeed
                                ? "#FFB546"
                                : "#EF4444";
                              const IconElement = iconMap[icon] || (
                                <BlockIcon />
                              );
                              return (
                                <Tooltip
                                  key={index}
                                  title={
                                    icon.charAt(0).toUpperCase() + icon.slice(1)
                                  }
                                >
                                  <div
                                    style={{
                                      display: "flex", // Flexbox layout
                                      alignItems: "center", // Vertical centering
                                      justifyContent: "center", // Horizontal centering
                                      cursor: "pointer",
                                    }}
                                  >
                                    {React.cloneElement(IconElement, {
                                      sx: { color, fontSize: 16 },
                                    })}
                                  </div>
                                </Tooltip>
                              );
                            })
                          ) : (
                            <div
                              style={{
                                gridColumn: "1 / span 3", // Span across all columns
                              }}
                            >
                              N/A
                            </div>
                          )}
                        </div>
                      </div>
                    </Box>
                  </Grid>
                  {/* Grid containing Assignee Info */}
                  <Grid item sm={2.5}>
                    <Box>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontWeight: "normal", fontSize: 14 }}
                      >
                        Task Assignee
                      </Typography>
                      <CustomMultiSelect
                        queueID={
                          item?.patient
                            ? item?.items?.map((ids) => ids?._id)
                            : item?._id
                        }
                        queueStatus={item?.status}
                        assignee={
                          selectedPatientStatus === "resolved" ||
                          selectedPatientStatus === "ignored"
                            ? item?.patient
                              ? item?.assignees || []
                              : item?.assignees || []
                            : item?.patient
                              ? item?.uniqueAssignees || []
                              : item?.assignees || []
                        }
                        refetchQueueAgain={refetchQueueAgain}
                      />
                    </Box>
                  </Grid>
                  {/* Grid containing Actions Info */}
                  <Grid item sm={2.5}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        height: "100%",
                        alignItems: "flex-end",
                      }}
                    >
                      {item?.patient ? (
                        <LastCell
                          id={item._id}
                          firstName={item?.patient?.firstName}
                          patientId={item?.patient?._id}
                          path={item?.patient?.path}
                          queueStatus={item?.status}
                          onRefetch={() => {}}
                          patient={item?.patient}
                          FormData={item}
                          acuity={item?.acuity}
                          userFeedback={item?.userFeedback}
                          originalMessage={item?.originalMessage}
                          chatbotReply={item?.chatbotReply}
                          reasoning={item?.reasoning}
                          createdAt={item?.createdAt}
                          value={value}
                          taskCategory={item?.taskCategory}
                          refetchCount={refetchCount}
                          formId={item?.metadata?.assignFormId}
                          isRowHovered={isRowHovered}
                          selectedPatientStatus={
                            item?.items ? selectedPatientStatus : item?.status
                          }
                          refetchQueueAgain={refetchQueueAgain}
                          isNewDesign={true}
                          setDrawerOpen={setDrawerOpen}
                        />
                      ) : (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                            sx={{
                              border: "1px solid rgb(0, 0, 0, 0.12)", // Add an 8px border
                              borderRadius: "8px", // Optional: Make the border circular if needed
                              padding: "4px", // Adjust padding to maintain proper spacing
                              display: "inline-flex", // Center the content inside the border
                              alignItems: "center",
                              justifyContent: "center",
                              marginRight: "5px",
                              marginTop: "5px",
                            }}
                            // onClick={() =>
                            //   setLinkQueueModalOpen({
                            //     value: true,
                            //     data: item?._id,
                            //     results: item?.metadata?.patientData,
                            //     taskCategory: item?.taskCategory,
                            //     callerIdentification: item?.metadata?.callerIdentification
                            //   })
                            // }
                            onClick={toggleUnknownCallerDrawer({
                              value: true,
                              data: item?._id,
                              results: item?.metadata?.patientData,
                              taskCategory: item?.taskCategory,
                              callerIdentification:
                                item?.metadata?.callerIdentification,
                              calldata: item,
                            })}
                          >
                            <Tooltip title="Message">
                              <Badge>
                                <Conversation color={"black"} size={20} />
                              </Badge>
                            </Tooltip>
                          </IconButton>
                          <IconButton
                            sx={{
                              border: "1px solid rgb(0, 0, 0, 0.12)", // Add an 8px border
                              borderRadius: "8px", // Optional: Make the border circular if needed
                              padding: "4px", // Adjust padding to maintain proper spacing
                              display: "inline-flex", // Center the content inside the border
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "5px",
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              openCallUnregisterPatientModal(
                                item?.metadata?.patientData?.name,
                                item?.metadata?.patientData?.phone_number,
                                item?._id,
                              );
                            }}
                          >
                            <Tooltip title="Call">
                              <Badge>
                                <Call color={"black"} size={20} />
                              </Badge>
                            </Tooltip>
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        ))}

      <PaginationBar
        {...{ page, count: totalPages, handleChange: handleChangePage }}
      />
      <CustomDrawer
        open={drawerOpen?.value}
        onClose={() => setDrawerOpen(false)}
        type={drawerOpen?.type ? drawerOpen?.type : null}
        data={drawerOpen?.data ? drawerOpen?.data : null}
        selectedPatientStatus={
          drawerOpen?.selectedPatientStatus
            ? drawerOpen?.selectedPatientStatus
            : null
        }
        isMessageClick={drawerOpen?.isMessageClick}
        refetchQueueAgain={refetchQueueAgain}
      />

      <UnknownCallerMessageDrawer
        toggleDrawer={toggleUnknownCallerDrawer}
        state={openUnknownCallerChat}
        data={toggleUnknownCallerDrawer?.calldata}
        setOpenUnknownCallerChat={setOpenUnknownCallerChat}
        refetchQueueAgain={refetchQueueAgain}
      />

      {openCallUnregisterPatient?.value && (
        <OutgoingCallComponent
          open={openCallUnregisterPatient?.value}
          name={openCallUnregisterPatient?.name}
          phone={openCallUnregisterPatient?.phone}
          patientId={openCallUnregisterPatient?.patientId}
          handleClose={handleCloseCallUnregister}
        />
      )}

      <LinkQueueModal
        open={linkQueueModalOpen?.value}
        queueId={linkQueueModalOpen?.data}
        metadata={linkQueueModalOpen?.results}
        callresults={linkQueueModalOpen?.callresults}
        taskCategory={linkQueueModalOpen?.taskCategory}
        callerIdentification={linkQueueModalOpen?.callerIdentification}
        setOpenUnknownCallerChat={setOpenUnknownCallerChat}
        onClose={() =>
          setLinkQueueModalOpen({
            value: false,
            data: null,
            results: null,
            taskCategory: null,
            callerIdentification: null,
          })
        }
        refetchQueueAgain={refetchQueueAgain}
      />
    </>
  );
}
